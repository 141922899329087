import { axiosInstance } from '../../../common/axiosInstance';
import ROUTES from '../../../common/routes';

const {
	policy,
	policyDocument,
	listInsurer,
	addPolicy,
	viewPolicy,
	premiumReceipts,
	getInsurerPolicies
} = ROUTES;
const axios = axiosInstance;

const fetchPolicies = (data: any) => axios.request({ ...policy, data });

const fetchPolicyDocument = (data: any) =>
	axios.request({ ...policyDocument, data });

const fetchPremiumReceipts = (data: any) =>
	axios.request({ ...premiumReceipts, data });

const fetchAllInsurers = () => axios.request({ ...listInsurer });

const AddPolicies = (data: any) => axios.request({ ...addPolicy, data });

const ViewPolicies = (data: any) => axios.request({ ...viewPolicy, data });

const GetInsurerPolicies = (data: any) => axios.request({ ...getInsurerPolicies, data });

export {
	fetchPolicies,
	fetchPolicyDocument,
	fetchPremiumReceipts,
	fetchAllInsurers,
	AddPolicies,
	ViewPolicies,
	GetInsurerPolicies
};
