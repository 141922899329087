import React, { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
import CustomButton from '../../../common/ui/CustomButton';
import GeneratePolicyIcon from '../../../common/ui/GeneratePolicyIcon';
import { POLICY_TYPE_CODES, POLICY_TYPE_LABEL } from '../../../common/Enums';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as OrangeRuppee } from '../../../common/icons/profile/orange_ruppee_icon.svg';
import { ReactComponent as checked } from '../../../common/icons/profile/checked.svg';
import { ReactComponent as alert } from '../../../common/icons/Nominee-orange-alert-icon.svg';
import { convertAmount } from '../../../common/utils';
import Currency from './Currency';

const PolicyCard = ({
	policyDetails = {},
	className,
	changeButtonText = 'CHANGE_ADDRESS',
	onChangeClick = () => false,
	disabled = true,
}: any) => {
	const theme = useTheme();
	const isAssetPolicy: boolean = useMemo(
		() =>
			String(policyDetails?.alternateCode).toUpperCase() ===
			POLICY_TYPE_CODES.ASSET,
		[policyDetails?.alternateCode]
	);

	const { t } = useTranslation();

	return (
		<Grid item className={className}>
			<Grid container spacing={1.5} alignItems="center" className="mb-5">
				<Grid item>
					<GeneratePolicyIcon policyTypeCode={policyDetails?.alternateCode} />
				</Grid>
				<Grid item>
					<Typography color={theme.palette.primary.main} fontSize={14}>
						{POLICY_TYPE_LABEL[policyDetails?.alternateCode]}
					</Typography>
				</Grid>
			</Grid>
			<Grid item className="mb-10">
				<Separator color={theme.palette.primary.main} borderWidth={1} />
			</Grid>
			<Grid item>
				<Grid
					container
					flexWrap="nowrap"
					justifyContent="space-between"
					className="mb-15"
				>
					<Grid item>
						{!isAssetPolicy ? (
							<>
								<Grid item>
									<Typography
										className="f-16 fw-600"
										color={theme.palette.common.black}
									>
										{policyDetails.planName}
									</Typography>
								</Grid>
							</>
						) : (
							''
						)}

						<Grid item>
							<Typography className="f-14" color={theme.palette.common.black}>
								{isAssetPolicy ? '' : t('LIFE_ASSURED')}
							</Typography>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.common.black}
							>
								{
									policyDetails[
									isAssetPolicy ? t('VEHICLE_NUMBER') : t('CUSTOMER_NAME')
									]
								}
							</Typography>
						</Grid>
						{isAssetPolicy ? (
							<Grid item>
								<Typography className="f-14" color={theme.palette.common.black}>
									{t('IDV')}
								</Typography>
								<Typography
									className="f-16 fw-600"
									color={theme.palette.common.black}
								>
									{convertAmount(policyDetails?.idv)}
								</Typography>
							</Grid>
						) : (
							<></>
						)}
					</Grid>
					<Grid item className="mt-10">
						<img
							src={policyDetails?.companyLogo}
							alt="Logo"
							width={60}
							height={60}
							style={{ objectFit: 'contain', border: '1px solid #d7d7d7', backgroundColor: 'white' }}
						/>
					</Grid>
				</Grid>
				<Grid container className="mt-n15" justifyContent="space-between">
					{isAssetPolicy ? (
						<>
							<Grid item sm={6}>
								<Grid item>
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('COVER')}@
									</Typography>
									<Grid container spacing={0.5} alignItems="center">
										<Grid item display="flex">
											<Typography
												className="f-14 fw-600 mr-5"
												color={theme.palette.common.black}
											>
												40%
											</Typography>
											<Typography
												className="f-14"
												color={theme.palette.common.black}
											>
												{t('DEPRECIATION')}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					) : (
						<>
							<Grid item sm={6}>
								<Grid item>
									<Typography
										className="f-14"
										color={theme.palette.common.black}
									>
										{t('SUM_ASSURED')}
									</Typography>
									<Grid container spacing={0.5} alignItems="center">
										<Currency policyDetails={policyDetails} amount={policyDetails?.sumAssured} className="mb-2" />
									</Grid>
								</Grid>
							</Grid>
						</>
					)}

					<Grid item sm={6}>
						<Grid item className="mr-50">
							<Typography className="f-14" color={theme.palette.common.black}>
								{t('PREMIUM')}
							</Typography>
							<Grid container alignItems="center">
								<Currency policyDetails={policyDetails} amount={policyDetails?.premiumAmount} className="mb-2 mr-5" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{policyDetails?.companyCode === "10" && changeButtonText === "CHANGE_BANK" ? (policyDetails.endoBankUpdatedStatus === 'Y' ||
				policyDetails.endoBankUpdatedStatus === '' ? (
				<Grid item>
					<Grid container alignItems="center">
						<Grid item>
							<SvgConverter Icon={checked} />
						</Grid>
						<Grid item>
							<Typography
								className="f-14 ml-2"
								color={theme.palette.success.main}
							>
								{t('UPDATED')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid item>
					<Grid container>
						<Grid item>
							<SvgConverter
								Icon={alert}
								className="mt-5 mr-5"
							/>
						</Grid>
						<Grid item>
							<Typography
								className="f-14"
								color={theme.palette.secondary.main}
							>
								{t('UNDER_PROCESS')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)) : (<></>)}

			<Grid item className="mt-7">
				<Separator color={theme.palette.grey[300]} borderWidth={1} />
			</Grid>
			<Grid item className="mt-5">
				<CustomButton
					text={t(changeButtonText)}
					variant="text"
					fontSize={14}
					fontWeight={400}
					showIcon={false}
					onClick={onChangeClick}
					color="primary"
					disabled={disabled}
				/>
			</Grid>
		</Grid>
	);
};

export default PolicyCard;
