import React from 'react';
import { Grid } from '@mui/material';
// import { ReactComponent as CancelledCheck } from "../../../common/icons/update-mobile/cancelled_cheque_icon.svg";
import {
	assetPolicies,
	combinePoliciesAccount,
	getBankInfoByAcNo,
	healthPolicies,
	lifePolicies,
} from './Bank.slice';
import BankDetailsHeader from './common/BankDetailsHeader';
import PolicyCard from '../Common/PolicyCard';
import './Bank.scss';
import { BankPolicyLock } from '../../../common/utils';

const BankPolicies = ({
	policiesObj,
	totalPolicies,
	unAssignedBanks,
	handleChangeBank,
	handleRemoveBank,
	handleAssignPolicies,
	handleVerifyNow
}: any) => (
	<Grid container className="Manage-bank" spacing={6}>
		{Object.keys(policiesObj).map((acNo: any, index) => {
			const bankInfo = getBankInfoByAcNo(acNo)(
				combinePoliciesAccount(totalPolicies, unAssignedBanks)
			);

			return (
				<Grid item key={acNo} className="bank-style-policy">
					<Grid item className="Manage-bank">
						<BankDetailsHeader
							data={bankInfo}
							index={index}
							onRemoveClick={() => handleRemoveBank(acNo)}
							showAssignButton
							handleAssignPolicy={() => handleAssignPolicies(bankInfo)}
							isUnVerified={(combinePoliciesAccount(totalPolicies, unAssignedBanks)).some((item: any) => item.bankAcNo === bankInfo.bankAcNo && item.bankVerified === 'Y') ? false : true}
							onVerifyNow={() => handleVerifyNow(bankInfo)}
						/>
						<Grid
							item
							className="mobile-Policy-screen policy-list-container p-20"
						>
							{lifePolicies(policiesObj[acNo]).map((policy: any) => (
								<PolicyCard
									key={policy?.policyNo}
									policyDetails={policy}
									changeButtonText="CHANGE_BANK"
									onChangeClick={handleChangeBank(acNo, policy)}
									disabled={BankPolicyLock(policy)}
								/>
							))}
							{healthPolicies(policiesObj[acNo]).map((policy: any) => (
								<PolicyCard
									key={policy?.policyNo}
									policyDetails={policy}
									changeButtonText="CHANGE_BANK"
									onChangeClick={handleChangeBank(acNo, policy)}
									disabled={BankPolicyLock(policy)}
								/>
							))}
							{assetPolicies(policiesObj[acNo]).map((policy: any) => (
								<PolicyCard
									key={policy?.policyNo}
									policyDetails={policy}
									changeButtonText="CHANGE_BANK"
									onChangeClick={handleChangeBank(acNo, policy)}
									disabled={BankPolicyLock(policy)}
								/>
							))}
						</Grid>
					</Grid>
				</Grid>
			);
		})}
	</Grid>
);

export default BankPolicies;
