import { Box, Grid, Typography, useTheme } from "@mui/material";
import Select from 'react-select';
import { fetchInsurers, selectInsurerOptions, selectIsInsurerLoading } from "../../PolicyScreen/Policies.slice";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormInput from "../../../../common/ui/Forms/FormInput/FormInput";
import Spinner from "../../../../common/ui/Spinner";
import CustomButton from "../../../../common/ui/CustomButton";
import CustomSelectBox from "../../../../common/ui/Selectbox/CustomSelect";
import CommonModal from "../../../../common/ui/Modal/CommonModal";



const AddPolicyViaNumberModal = ({ open, setOpen, submitPolicies }: any) => {

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedInsurence, setSelectedInsurence] = useState('');
    const insurerOptions = useSelector(selectInsurerOptions);
    const isInsurersLoading = useSelector(selectIsInsurerLoading);

    const { t } = useTranslation();
    const theme = useTheme();

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const insurerOptionsList =
        insurerOptions &&
        insurerOptions.map((item: any) => ({
            Description: item.label,
            Logo: item.insurerLogo,
            Code: item.value,
        }));
    const insurerenceOptionsList = [
        { Description: 'Health', Category: 'H', Code: 'H' },
        { Description: 'Assets-Motors', Category: 'G', Code: 'G' },
    ];
    const selectedInsurer = insurerOptions.find(
        (option: any) => option.value === selectedOption
    );

    const alternateCode = selectedInsurer ? selectedInsurer.alternateCode : '';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchInsurers());
    }, []);
    useEffect(() => {
        setSelectedInsurence('');
    }, [selectedOption]);

    useEffect(() => {
        setSelectedOption('');
        reset({ policyNo: '', insurerCode: '', insuranceCode: '' });
        setSelectedInsurence('');
    }, [open])

    const handlePolicyNoInput = (event: any) => {
        const currentValue = event.target.value;

        if (currentValue.length === 1 && !/^[a-zA-Z0-9-_/\\]$/.test(currentValue)) {
            event.target.value = '';
        } else {
            const newValue = currentValue.replace(/[^a-zA-Z0-9-_/\\]/g, '');
            event.target.value = newValue;
        }
    };

    const onSubmit = (data: any) => {
        const insurerLogo = insurerOptions.find(
            (insurer: any) => insurer.value === data?.insurerCode
        );
        if (insurerLogo) {
            data.companyLogo = insurerLogo.insurerLogo;
            data.alternateCode = insurerLogo.alternateCode === 'G' ? selectedInsurence : insurerLogo.alternateCode;
            data.companyName = insurerLogo?.label;
            data.requestFrom = 'POLICYNO';
        }

        if (data.alternateCode === 'L') {
            data.insuranceCode = '';
        }

        submitPolicies(data);

        setSelectedOption('');
        reset({ policyNo: '', insurerCode: '', insuranceCode: '' });
        setSelectedInsurence('');

        setOpen(false);
    }

    return (

        <CommonModal

            open={open}
            modalClassName="px-10 py-20"
            boxProps={{ width: 335 }}>

            <Grid container justifyContent={'center'}>
                <Box className="add-policy">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} className="p-5">
                                <Typography
                                    className="f-16 fw-600 mb-10"
                                    color={theme.palette.primary.main}
                                >
                                    {t('ENTER_POLICY_NUMBER')}
                                </Typography>
                                <FormInput
                                    type="text"
                                    placeholder="ABCDE0100X"
                                    name="policyNo"
                                    hideCountButton
                                    control={control}
                                    errors={errors}
                                    inputProps={{
                                        className: '',
                                        onInput: handlePolicyNoInput,
                                        maxLength: 40,
                                        ...register('policyNo', {
                                            required: {
                                                value: true,
                                                message: t('MANDATORY_FIELD'),
                                            },
                                        }),
                                    }}
                                />
                            </Grid>
                            {isInsurersLoading ? (
                                <Grid className="ml-50 mt-20">
                                    <Spinner />
                                </Grid>
                            ) : (
                                <Grid item xs={12} className="p-5 ">
                                    <Typography
                                        className="f-16 fw-600 mb-10"
                                        color={theme.palette.primary.main}
                                    >
                                        {t('SELECT_INSURER')}
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name="insurerCode"
                                        render={({ field }) => (
                                            <Select
                                                options={insurerOptionsList}
                                                isSearchable
                                                required

                                                theme={theme => ({
                                                    ...theme, colors: {
                                                        ...theme.colors,
                                                        neutral50: "#0051ba"
                                                    },
                                                })}
                                                placeholder={t('TYPE_TO_SEARCH')}
                                                value={
                                                    selectedOption
                                                        ? insurerOptionsList.find(
                                                            (option) => option.Code === selectedOption
                                                        )
                                                        : null
                                                }
                                                getOptionLabel={(option: any) => option.Description}
                                                getOptionValue={(option: any) => option.Code}
                                                onChange={(selectedOption) => {
                                                    setSelectedOption(selectedOption?.Code || '');
                                                    field.onChange(selectedOption?.Code || '');
                                                }}
                                                onBlur={() => field.onBlur()}
                                                styles={{
                                                    control: (base) => ({
                                                        ...base,
                                                        height: '50px',
                                                        borderColor: '#0051ba',
                                                        '&:hover': {
                                                            borderColor: ' #f99117',
                                                        },
                                                    }),
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.insurerCode && (
                                        <Typography
                                            color={theme.palette.error.main}
                                            fontSize={12}
                                            className="mt-5"
                                        >
                                            {t('MANDATORY_FIELD')}
                                        </Typography>
                                    )}
                                </Grid>
                            )}
                            {alternateCode === 'G' || alternateCode === 'H' ? (
                                <Grid item xs={12} className="p-5 ">
                                    <Typography
                                        className="f-16 fw-600 pb-10"
                                        color={theme.palette.primary.main}
                                    >
                                        {t('Select Insurance Type')}
                                    </Typography>
                                    <CustomSelectBox
                                        options={insurerenceOptionsList}
                                        defaultValue={selectedInsurence}
                                        setSelectedOption={setSelectedInsurence}
                                        ClassName="w-100 selectbox-border"
                                        inputProps={register('insuranceCode', {
                                            required: true,
                                        })}
                                    />
                                    {errors.insuranceCode && (
                                        <Typography color={theme.palette.error.main} fontSize={12}>
                                            {t('MANDATORY_FIELD')}
                                        </Typography>
                                    )}
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={12}
                            className="pt-30 pb-20"
                            container

                            justifyContent={'space-between'}
                        >
                            <Grid item xs={6}>
                                <CustomButton
                                    text={t('CANCEL')}
                                    variant="text"
                                    color="inherit"
                                    showIcon={false}
                                    className="f-16 fw-400"
                                    onClick={() => setOpen(false)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomButton
                                    text={t('ADD_POLICY')}
                                    variant="text"
                                    type="submit"
                                    color="primary"
                                    className="f-16 fw-600"
                                    textAlign={"right"}
                                // onClick={() => setSelectedOption('')}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>

            </Grid>
        </CommonModal >)
}

export default AddPolicyViaNumberModal;