import { Grid } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Download } from '../../../../../common/icons/policy/download.svg';
import { ReactComponent as Arrow } from '../../../../../common/icons/right_arrow-icon.svg';
import '../../Policy.scss';
import theme from '../../../../../common/theme';
import SvgConverter from '../../../../../common/SvgConverter';
import Separator from '../../../../../common/ui/Separator';
import CustomButton from '../../../../../common/ui/CustomButton';
import CommonModal from '../../../../../common/ui/Modal/CommonModal';
import PolicyServices from '../../PolicyServices/PolicyServices';
import { APP_ROUTES } from '../../../../../common/constants/Routes';
import {
	fetchPolicyDocumentDownload,
	fetchpremiumRecieptsDownloads,
} from '../../Policies.slice';
import DownloadStatement from '../../DownloadStatement/DownloadStatement';
import PremiumReceipt from '../../DownloadStatement/PremiumReceipt';
import BonusStatement from '../../DownloadStatement/BonusStatement';
import FundStatement from '../../DownloadStatement/FundSatement';
import { PolicyLock } from '../../../../../common/utils';

const generateLinkTo = (policyType: any) => {
	let route;
	switch (policyType) {
		case 'life':
			route = APP_ROUTES.POLICY_DETAILS_LIFE;
			break;
		case 'health':
			route = APP_ROUTES.POLICY_DETAILS_HEALTH;
			break;
		default:
			route = APP_ROUTES.POLICY_DETAILS_ASSET;
			break;
	}
	return route;
};
interface UserProps {
	footerType?: string;
	items?: any;
	policyType?: any;
}
export const PolicyFooter: React.FC<UserProps> = ({
	footerType,
	items,
	policyType,
}) => {
	const [open, setOpen] = React.useState(false);
	const [viewModal, setViewModal] = React.useState(false);
	const [premiumModal, setPremiumModal] = React.useState(false);
	const [bonusModal, setBonusModal] = React.useState(false);
	const [FundModal, setFundModal] = React.useState(false);
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const handleClose = () => setOpen(false);
	const handleViewClose = () => setViewModal(false);
	const handlePremiumClose = () => setPremiumModal(false);
	const handlePremiumBack = () => {
		setPremiumModal(false);
		setViewModal(true);
	};
	const handleBonusBack = () => {
		setBonusModal(false);
		setViewModal(true);
	};
	const handleFundBack = () => {
		setFundModal(false);
		setViewModal(true);
	};
	const downloadPolicyDocument = () => {
		dispatch(
			fetchPolicyDocumentDownload({
				policyDocument: 'Y',
				policyNumber: items?.policyNo,
				alternateCode: items?.alternateCode,
				insurerCode: items?.companyCode,
				documentFlag: 'POLICY_DOCUMENT',
				t,
			} as any)
		);
	};
	const downloadPremiumReceipts = () => {
		dispatch(
			fetchpremiumRecieptsDownloads({
				policyNumber: items?.policyNo,
				alternateCode: items?.alternateCode,
				insurerCode: items?.companyCode,
				t,
			} as any)
		);
	};
	const toRoute = generateLinkTo(policyType);
	return (
		<Grid container>
			<Grid item className="" xs={10}>
				<Link to={toRoute} state={{ items, footerType }}>
					<Typography
						className="f-14 fw-6 py-15  px-7"
						color={theme.palette.primary.main}
					>
						{t('VIEW_POLICY_DETAILS')}
					</Typography>
				</Link>
			</Grid>
			<Grid item xs={1} className="py-12">
				<Link to={toRoute} state={{ items, footerType }}>
					<SvgConverter Icon={Arrow} className="right-arrow ml-13" />
				</Link>
			</Grid>
			<Separator color={theme.palette.primary.main} />
			{/* <Grid container className="py-7 pr-18 pl-5">
				<CustomButton
					showIcon
					text={t('DOWNLOAD_POLICY_DOCUMENT')}
					className="f-14 fw-400"
					variant="text"
					textAlign={'start'}
					onClick={downloadPolicyDocument}
					iconClassName="ml-10 policy-button-end-icon"
					endIcon={Download}
				/>
				<Grid item xs={1} className="py-12 ml-15">
					<SvgConverter Icon={Download}  />
				</Grid>
			</Grid> */}
			<Grid item className="" xs={10}>
				<Typography
					className="f-14 fw-6 py-15  px-7 cursor-pointer"
					color={theme.palette.primary.main}
					onClick={downloadPolicyDocument}
				>
					{t('DOWNLOAD_POLICY_DOCUMENT')}
				</Typography>
			</Grid>
			<Grid item xs={1} className="py-12" onClick={downloadPolicyDocument}>
				<SvgConverter Icon={Download} className="ml-13 cursor-pointer" />
			</Grid>
			<Separator color={theme.palette.primary.main} />
			{/* <Grid container className="py-7 ">
				<CustomButton
					showIcon
					text={t('VIEW/DOWNLOAD_STATEMENTS')}
					className="f-14 fw-400"
					variant="text"
					onClick={() => setViewModal(true)}
					iconClassName="ml-10 policy-button-end-icon"
					endIcon={Download}
				/>
				<Grid item xs={1} className="py-12 ml-15">
					<SvgConverter Icon={Download} />
				</Grid>
			</Grid> */}
			<Grid item className="" xs={10}>
				<Typography
					className="f-14 fw-6 py-15  px-7 cursor-pointer"
					color={theme.palette.primary.main}
					onClick={() => setViewModal(true)}
				>
					{t('VIEW/DOWNLOAD_STATEMENTS')}
				</Typography>
			</Grid>
			<Grid item xs={1} className="py-12" onClick={() => setViewModal(true)}>
				<SvgConverter Icon={Download} className="ml-13 cursor-pointer" />
			</Grid>
			{footerType === 'active' ? (
				<>
					<Separator color={theme.palette.primary.main} />
					<Grid container>
						<Grid item xs={9}>
							<CustomButton
								text={t('POLICY_SERVICES')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={() => setOpen(true)}
								className="py-8 f-14 fw-6 "
								disabled={PolicyLock(items)}
							/>
							{/* <Typography
								className="f-14 fw-6 py-10 px-8"
								color={theme.palette.primary.main}
							>
								POLICY SERVICES
							</Typography> */}
						</Grid>
						<Grid item xs={3}>
							<CustomButton
								text={<SvgConverter Icon={Arrow} className="right-arrow" />}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={() => setOpen(true)}
								className="py-8 ml-15"
								disabled={PolicyLock(items)}
							/>
						</Grid>
					</Grid>

					{policyType !== 'life' && (
						<>
							<Separator color={theme.palette.primary.main} />

							{/* <Grid>
								<CustomButton
									showIcon
									text={t('DOWNLOAD_CASHLESS_E-CARDS')}
									className="f-14 fw-400 py-10"
									variant="text"
									iconClassName="ml-10 policy-button-end-icon"
									endIcon={Download}
								/>
							</Grid> */}
							<Grid item className="" xs={10}>
								<Typography
									className="f-14 fw-6 py-15  px-7 cursor-pointer"
									color={theme.palette.primary.main}
								>
									{t('DOWNLOAD_CASHLESS_E-CARDS')}
								</Typography>
							</Grid>
							<Grid item xs={1} className="py-12">
								<SvgConverter Icon={Download} className="ml-13 cursor-pointer" />
							</Grid>
						</>
					)}

					<CommonModal
						onClose={handleClose}
						boxProps={{ width: 320 }}
						open={open}
						modalClassName="modal-border"
					>
						<PolicyServices item={items} />
					</CommonModal>
				</>
			) : (
				<></>
			)}
			<CommonModal
				onClose={handleViewClose}
				boxProps={{ width: 320 }}
				open={viewModal}
				modalClassName="modal-border"
			>
				<DownloadStatement
					PremiumClick={downloadPremiumReceipts}
					BonusClick={() => setBonusModal(true)}
					FundClick={() => setFundModal(true)}
				/>
			</CommonModal>
			<CommonModal
				onClose={handlePremiumClose}
				boxProps={{ width: 320 }}
				open={premiumModal}
				modalClassName="modal-border"
			>
				<PremiumReceipt PremiumBack={handlePremiumBack} />
			</CommonModal>
			<CommonModal
				onClose={handlePremiumClose}
				boxProps={{ width: 320 }}
				open={bonusModal}
				modalClassName="modal-border"
			>
				<BonusStatement BonusBack={handleBonusBack} />
			</CommonModal>
			<CommonModal
				onClose={handlePremiumClose}
				boxProps={{ width: 320 }}
				open={FundModal}
				modalClassName="modal-border"
			>
				<FundStatement FundBack={handleFundBack} />
			</CommonModal>
		</Grid>
	);
};
export default PolicyFooter;
