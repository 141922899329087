import { Box, Grid, Typography, useTheme } from "@mui/material"
import CommonModal from "../../../../common/ui/Modal/CommonModal"
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../common/ui/CustomButton";

export const TermsCondtionsModal = ({ open, setOpen, setIsChecked }: any) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (<CommonModal
        onClose={() => { }}
        open={open}
        modalClassName="px-10 py-20"
        boxProps={{ width: 500 }}
    >
        <Grid container justifyContent={'center'} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <Box
                marginBottom={5}
                display="flex"
                width={500}
                justifyContent="center"
                flexDirection="column"

            >

                <Grid container justifyContent={'center'} className="p-10" >

                    <Typography
                        className="f-16 fw-600 mb-5 "
                        color={theme.palette.primary.main}
                    >
                        {t('TERMS_&_CONDITIONS')}
                    </Typography>


                    <Typography
                        className="f-14 fw-600 mb-5 "
                        color={theme.palette.common.black}
                    >
                        {`The content on the CAMSRepository.com is provided for information purpose only. CAMSRep maintains this website only to enhance access to the information about eInsurance account and about CAMSRepository.com.

                        The content displayed as part of this website or webpage comes from authenticated sources believed to be accurate, but may contain inaccuracies or typographical errors. CAMSRepository.com makes no representations about the results to be obtained from using the CAMSRepository.com Site or the content.

                        While we try to keep the information as current and accurate as possible, we make no warranty of any kind, implied or express, as to its accuracy, completeness or appropriateness for any purpose. We will make an effort to correct the errors brought to our attention from time to time. In the event of conflict, if any, between the information contained in this website and those contained in the policy document or the premium certificate issued by the insurer, the information by the insurer shall prevail.

                        This Website may contain links to other websites, web pages and services and use of each such website, web page and services on any other website or web service is subject to the terms and conditions, if any, of the respective website or web service only.

                        Information submitted or provided as part of CAMSRepository.com will be transmitted over local Exchange, Interexchange and Internet Backbone Carrier Lines and through routers switches and other devices owned, maintained and serviced by third party service providers, long distant carriers, utilities, internet service providers and others, all of which are beyond the control and jurisdiction of CAMSRepository.com.

Accordingly, CAMSRepository.com assumes no liability for or relating to the delay, failure, interruption or corruption of any data or other information transmitted in connection therewith.`}
                    </Typography>




                </Grid >

                <Grid container justifyContent={'end'}>
                    <CustomButton
                        text={t('AGREE&CONTINUE')}
                        variant="text"
                        color="primary"
                        className="fw-600 f-16 pr-15 pt-10"
                        showIcon
                        textAlign="end"
                        onClick={() => { setOpen(false); setIsChecked(true) }}
                    />
                </Grid>

            </Box >
        </Grid >
    </CommonModal >)
}