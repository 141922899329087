/* eslint-disable no-unused-vars */
import { APP_ROUTES } from './constants/Routes';

export enum DATE_FORMAT_ENUMS {
	LABEL = 'DD/MM/YYYY',
}

export enum FAMILY_TYPES {
	SPOUSE = 'SPOUSE',
	SON = 'SON',
	DAUGHTER = 'DAUGHTER',
	FATHER = 'FATHER',
	MOTHER = 'MOTHER',
}

export const FAMILY_TYPES_LABEL = {
	[FAMILY_TYPES.SPOUSE]: 'Spouse',
	[FAMILY_TYPES.SON]: 'Son',
	[FAMILY_TYPES.DAUGHTER]: 'Daughter',
	[FAMILY_TYPES.FATHER]: 'Father',
	[FAMILY_TYPES.MOTHER]: 'Mother',
};

export const FAMILY_RELATION_CODE = {
	[FAMILY_TYPES.SPOUSE]: '01',
	[FAMILY_TYPES.SON]: '02',
	[FAMILY_TYPES.DAUGHTER]: '03',
	[FAMILY_TYPES.FATHER]: '04',
	[FAMILY_TYPES.MOTHER]: '05',
};

export const FAMILY_TYPES_LIST = [
	{ label: FAMILY_TYPES_LABEL[FAMILY_TYPES.SPOUSE], type: FAMILY_TYPES.SPOUSE },
	{ label: FAMILY_TYPES_LABEL[FAMILY_TYPES.SON], type: FAMILY_TYPES.SON },
	{
		label: FAMILY_TYPES_LABEL[FAMILY_TYPES.DAUGHTER],
		type: FAMILY_TYPES.DAUGHTER,
	},
	{ label: FAMILY_TYPES_LABEL[FAMILY_TYPES.FATHER], type: FAMILY_TYPES.FATHER },
	{ label: FAMILY_TYPES_LABEL[FAMILY_TYPES.MOTHER], type: FAMILY_TYPES.MOTHER },
];

export enum PAYMENT_TYPES {
	POLICY = 'POLICY',
}

export enum PAYMENT_GATEWAY_TYPES {
	CAMSPAY = 'CAMSPAY',
	RAZORPAY = 'RAZORPAY',
}

export enum PAYMENT_STATUS {
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export enum MOBILE_EMAIL_CHANGE_ACTIONS {
	CHANGE = 'CHANGE',
	REMOVE = 'REMOVE',
	ADD = 'ADD',
}

export enum SELECT_POLICY_TYPES_FOR_MODULES {
	MOBILE = 'MOBILE',
	EMAIL = 'EMAIL',
	ADDRESS = 'ADDRESS',
	BANK = 'BANK',
}

export enum SIGNUP_EIA_STATUS {
	EIA_NA = 'EIA_NA',
	EIA_CAMS = 'EIA_CAMS',
	EIA_OTHERS = 'EIA_OTHERS',
	EIA_MOBILE_EXCEED = 'MOBILE_EXCEED',
	EIA_EMAIL_EXCEED = 'EMAIL_EXCEED',
	EIA_INPROGRESS = "VERIFICATION_INPROGRESS"
}

export enum CAMSPAY_FEEDBACKS_URL {
	SUCCESS = `${window.location.origin}${APP_ROUTES.PAYMENT_SUCCESS}` as any,
	FAILED = `${window.location.origin}${APP_ROUTES.PAYMENT_FAILED}` as any,
}

export enum KYC_TYPES {
	SIGNUP = 'SIGNUP',
	ADDRESS_CHANGE = 'ADDRESS_VERIFICATION',
}

export enum KYC_DOCUMENT_TYPES {
	ADH = 'Aadhar',
	PAN = 'PAN',
	DL = 'Driving License',
}

export enum BANK_ADDRESS_EMAIL_MOBILE_REMOVE_TYPES {
	UNASSIGNED = 'UNASSIGNED',
	UNVERIFIED = 'UNVERIFIED',
}

export enum QUICK_LOGIN_TYPES {
	MPIN = 'MPIN',
	FINGER_PRINT = 'FINGER_PRINT',
}

export enum OTP_PURPOSE {
	SIGNIN = 'SIGNIN',
	SIGNUP = 'SIGNUP',
}

export enum POLICY_TYPE_CODES {
	LIFE = 'L',
	HEALTH = 'H',
	ASSET = 'G',
}

export const POLICY_TYPE_LABEL: any = {
	[POLICY_TYPE_CODES.LIFE]: 'Life',
	[POLICY_TYPE_CODES.HEALTH]: 'Health',
	[POLICY_TYPE_CODES.ASSET]: 'Asset',
};

export const MODULE_TYPE_LABEL: any = {
	[SELECT_POLICY_TYPES_FOR_MODULES.MOBILE]: 'Number',
	[SELECT_POLICY_TYPES_FOR_MODULES.EMAIL]: 'Email',
	[SELECT_POLICY_TYPES_FOR_MODULES.BANK]: 'Bank',
	[SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS]: 'Address',
};

export const MODULE_TYPE_VALUE_FOR_ASSIGN_SCREEN: any = {
	[SELECT_POLICY_TYPES_FOR_MODULES.MOBILE]: 'mobileNo',
	[SELECT_POLICY_TYPES_FOR_MODULES.EMAIL]: 'email',
	[SELECT_POLICY_TYPES_FOR_MODULES.BANK]: 'bankAcNo',
	[SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS]: 'fullAddress',
};

export enum SEND_COMMUNICATION_PURPOSE {
	PREMIUM_PAYMENT = 'PREMIUM_PAYMENT',
	AR_INTIMATION = 'AR_INTIMATION',
}

export enum SEND_COMMUNICATION_FLAG {
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
}

export const GenderList = [
	{ Description: 'Male', Code: 'M' },
	{ Description: 'Female', Code: 'F' },
	{ Description: 'Others', Code: 'O' },
];

export enum EKYC_PROCESS_FLOW_TYPE {
	AUTO = 'AUTO',
	MANUAL = 'MANUAL',
	TAP_TO_SEE = 'TAP_TO_SEE',
}

export enum CHEQUE_VERIFICATION_TYPES {
	CHEQUE_VERIFIED = 'CHEQUE_VERIFIED',
	CHEQUE_MANUAL_VERIFICATION = 'CHEQUE_MANUAL_VERIFICATION',
}

export const POLICY_LOCK_FLAG = ["A", "S"];