import {
	Box,
	Card,
	CardContent,
	Grid,
	IconButton,
	useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';

import './Dashboard.scss';
import {
	DashboardList,
	dashboardListRequest,
	fetchMissingInfo,
	selectAnnualStatusViewedFlag,
	selectIsDashboardLoading,
	selectIsMissingInfoLoading,
	selectIsPolicyRejection,
	selectMissingInformation,
	selectNomineeUpdateNeeded,
	selectPolicyRejection,
} from './Dashboard.slice';
import LifeScreen from './LifeScreen';
import HealthScreen from './HealthScreen';
import AssetsScreen from './AssetsScreen';
import PoliciesByCalendar from './PoliciesByCalendar';
import HelpComponent from './HelpComponent';
import LoanTrackerScreen from './Tracker/LoanTracker/LoanTrackerScreen';
import SessionTrackerScreen from './Tracker/SessionTracker.tsx/SessionTrackerScreen';
import DashboradAddPolicyButton from './DashboradAddPolicyButton';
import NeedSupport from '../../Auth/common/NeedSupport';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { convertAmount, getFormatDate } from '../../../common/utils';
import { ReactComponent as RupeeIcon } from '../../../common/icons/dashboard/rupee-icon.svg';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as RightArrowIcon } from '../../../common/icons/right_arrow-icon.svg';
import { APP_ROUTES } from '../../../common/constants/Routes';
import MissingInfoCard from './common/MissingInfoCard';
import Spinner from '../../../common/ui/Spinner';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const DashboardScreen = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const dashboardListDatas = useSelector(DashboardList);

	const dashboardLoading = useSelector(selectIsDashboardLoading);
	const calendarList =
		dashboardListDatas && dashboardListDatas?.PolicyRenewalCalenderDetails;

	const annualStatusViewedFlag = useSelector(selectAnnualStatusViewedFlag);
	selectPolicyRejection
	const missingInformation = useSelector(selectMissingInformation);
	const nomineeUpdateNeeded = useSelector(selectNomineeUpdateNeeded);
	const policyRejectionLoading = useSelector(selectIsPolicyRejection);
	const missingInfoLoading = useSelector(selectIsMissingInfoLoading);
	const policyRejction = useSelector(selectPolicyRejection);

	const healthCountCheck = dashboardListDatas?.policyCount?.healthPolicy?.dollar === '0' && dashboardListDatas?.policyCount?.healthPolicy?.euro === '0' && dashboardListDatas?.policyCount?.healthPolicy?.inr === '0';
	const lifeCountCheck = dashboardListDatas?.policyCount?.lifePolicy?.dollar === '0' && dashboardListDatas?.policyCount?.lifePolicy?.euro === '0' && dashboardListDatas?.policyCount?.lifePolicy?.inr === '0';
	const assetCountCheck = dashboardListDatas?.policyCount?.assetPolicy?.dollar === '0' && dashboardListDatas?.policyCount?.assetPolicy?.euro === '0' && dashboardListDatas?.policyCount?.assetPolicy?.inr === '0';

	useEffect(() => {
		// if (missingInformation?.length === 0) {
		dispatch(fetchMissingInfo({}));
		// }
		if (dashboardListDatas?.length === 0) {
			dispatch(dashboardListRequest({}));
		}
	}, [dispatch]);
	const { t } = useTranslation();

	const arr = [1, 2, 3];

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'DashBoard Screen',
		});
	}, []);

	return (
		<Box className="ml-18 mt-5">
			<Grid container item xs={12} gap={2} justifyContent={'stretch'}>
				{missingInfoLoading || policyRejectionLoading ? (
					<Grid item display="flex" justifyContent="center" className="py-10">
						<Spinner />
					</Grid>
				) : (
					<>
						{
							annualStatusViewedFlag && (
								//<Grid item xs={12} sm={6} md={4} lg={3}>
								<MissingInfoCard flag="A" />
							)
							//</Grid>
						}

						{missingInformation &&
							missingInformation.map((mapdata: any, index: any) => (
								// <Grid item xs={12} sm={6} md={4} lg={3}>
								<MissingInfoCard flag="M" insIntegFlag={mapdata.insIntegFlag} />
								// </Grid>
							))}

						{nomineeUpdateNeeded &&
							nomineeUpdateNeeded.map((mapdata: any, index: any) => (
								// <Grid item xs={12} sm={6} md={4} lg={3}>
								<MissingInfoCard flag="N" />
								// </Grid>
							))}

						{policyRejction &&
							policyRejction.map((mapdata: any, index: any) => (
								// <Grid item xs={12} sm={6} md={4} lg={3}>
								<MissingInfoCard flag="R" data={mapdata} />
								// </Grid>
							))}
					</>
				)}
				{(missingInfoLoading ||
					annualStatusViewedFlag ||
					missingInformation?.length !== 0 ||
					nomineeUpdateNeeded?.length !== 0) && (
						<Separator color={theme.palette.info.main} className="mt-20" />
					)}
			</Grid>

			<Grid container justifyContent="left" spacing={12}>
				<Grid item lg={8} xs={12}>
					<Grid className="dashboard-box mt-30">
						{dashboardListDatas?.SurrenderDetails &&
							dashboardListDatas?.SurrenderDetails.map(
								(data: any, index: any) => (
									<Grid className="surrender-container">
										<Grid
											container
											justifyContent="space-between"
											spacing={1}
											flexWrap="nowrap"
											alignItems="baseline"
											className="dashboard-policy-card-header"
											px={2}
											pt={2}
										>
											<Grid
												item
												container
												spacing={2}
												flexWrap="nowrap"
												alignItems="flex-end"
												className="h-100"
											>
												<Grid item>
													<Typography
														fontSize={12}
														color={theme.palette.common.black}
													>
														{t('SURRENDER')}
													</Typography>

													<Typography
														className="pb-1"
														fontSize={16}
														color={theme.palette.primary.main}
														fontWeight={600}
													>
														{t('SESSION_TRACKER')}
													</Typography>
													<Typography
														fontSize={12}
														color={theme.palette.grey[400]}
													>
														{`${t('SURRENDER_DATE')} ${getFormatDate(
															data.surrenderInitatedDate,
															'DD-MM-YYYY'
														)}`}
													</Typography>

													<>
														<Grid item xs={12} mt={1}>
															<LabelWithIcon
																svgClassName=""
																justifyContent="flex-start"
																Icon={RupeeIcon}
																label={convertAmount(
																	data.currentSurrenderValue
																)}
																labelProps={{
																	color: theme.palette.common.black,
																	fontSize: 16,
																	fontWeight: 800,
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															{/* <Typography
																fontSize={14}
																color={theme.palette.common.black}
																className="ml-4"
															>
																{t('BY')} {getFormatDate(' ', 'YYYY-MM-DD')}
															</Typography> */}
														</Grid>
													</>
												</Grid>
											</Grid>

											<Grid item className="pb-5">
												<Link to={APP_ROUTES.POLICY_DETAILS_LIFE} state={data}>
													<IconButton className="p-0 ">
														<SvgConverter
															Icon={RightArrowIcon}
															className="d-flex align-items-end next-arrow"
														/>
													</IconButton>
												</Link>
											</Grid>
										</Grid>

										<Grid
											container
											justifyContent="space-between"
											// spacing={1}
											flexWrap="nowrap"
											alignItems="baseline"
											className="dashboard-policy-card-header custom-tracker-card"
											px={2}
											pt={2}
										>
											<Grid
												item
												container
												spacing={2}
												flexWrap="nowrap"
												alignItems="flex-end"
												className="h-100"
											>
												<Grid item>
													<Grid pb={1}>
														<Typography
															fontSize={14}
															fontWeight={600}
															color={theme.palette.common.black}
														>
															Surrender Value of
														</Typography>
														<Typography
															className="pb-1"
															fontSize={14}
															fontWeight={400}
														>
															<LabelWithIcon
																svgClassName=""
																justifyContent="flex-start"
																Icon={RupeeIcon}
																label={
																	convertAmount(data.currentSurrenderValue) +
																	' intiated'
																}
																labelProps={{
																	color: theme.palette.common.black,
																	fontSize: 14,
																	fontWeight: 400,
																}}
															/>
														</Typography>
														<Typography>
															<Separator color={theme.palette.grey[300]} />
														</Typography>
														<Typography
															fontSize={12}
															fontWeight={500}
															color={theme.palette.common.black}
															pt={1}
														>
															Estimated processing time: 6 hrs
														</Typography>
														<Typography
															fontSize={12}
															fontWeight={500}
															color={theme.palette.common.black}
														>
															Time elapsed: 3 hrs
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											{/* <Grid item className="pb-5">
												
												<Link to={APP_ROUTES.POLICY_DETAILS_LIFE} state={data}>
													<IconButton className="p-0 ">
														<SvgConverter
															Icon={RightArrowIcon}
															className="d-flex align-items-end next-arrow"
														/>
													</IconButton>
												</Link>
											</Grid> */}
										</Grid>
									</Grid>
								)
							)}

						{dashboardListDatas?.Tracker?.SessionTracker?.sessionTrackerDetailsList.map(
							(data: any, index: any) => (
								<SessionTrackerScreen
									sessionDetails={data}
									dashboardLoading={dashboardLoading}
								/>
							)
						)}

						{dashboardListDatas?.Tracker?.LoanTracker?.LoanTracker && (
							<LoanTrackerScreen
								dashboardLoading={dashboardLoading}
								LoanDetails={dashboardListDatas?.Tracker?.LoanTracker}
							/>
						)}
					</Grid>

					{lifeCountCheck ? (
						<DashboradAddPolicyButton type="life" />
					) : (
						<LifeScreen
							lifeDetails={dashboardListDatas?.life}
							dashboardLoading={dashboardLoading}
						/>
					)}

					<div className="mb-20 health-res">
						<Separator color={theme.palette.primary.main} />
					</div>
					{healthCountCheck ? (
						<DashboradAddPolicyButton type="health" />
					) : (
						<HealthScreen healthDetails={dashboardListDatas?.health} />
					)}

					<div className="mb-20">
						<Separator color={theme.palette.primary.main} />
					</div>

					{assetCountCheck ? (
						<Grid mb={5}>
							<DashboradAddPolicyButton type="assets" />
						</Grid>
					) : (
						<AssetsScreen assetsDetails={dashboardListDatas?.assets} />
					)}

					<div className="my-20 asset-bdr">
						<Separator color={theme.palette.primary.main} />
					</div>
				</Grid>
				<Grid
					item
					xl={4}
					lg={4}
					md={5}
					sm={6}
					xs={12}
					className="cal-align mt-20"
				>
					{lifeCountCheck &&
						healthCountCheck &&
						assetCountCheck ? (
						<>
							<Typography
								fontSize={16}
								color="primary"
								fontWeight={600}
								className="mb-30 calender_text"
							>
								{t('YEAR_2023_CALENDAR')}
							</Typography>
							<Grid
								item
								xl={12}
								lg={12}
								md={10}
								sm={12}
								xs={10}
								className="dashBoard-calender"
							>
								<DashboradAddPolicyButton type="calender" />
							</Grid>
						</>
					) : (
						<>
							<PoliciesByCalendar
								calendarList={calendarList}
								calendarLoading={dashboardLoading}
							/>
							<HelpComponent />
							<Grid mt={10} mb={10} width={300}>
								<NeedSupport type="black" page="dashboardMain" />
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default DashboardScreen;
