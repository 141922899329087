import { call, put, select, takeEvery } from 'typed-redux-saga';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import {
	ProfileUpdateArDetails,
	SaveFamilyVerification,
	addAddressDetails,
	addFamilyMemberRequest,
	fetchFamilyRelationShipRequest,
	fetchSelfDeclarationDetails,
	fetchUserFamilyList,
	fetchUserProfileData,
	otpGenerateForMobileEmail,
	otpVerifyForMobileEmail,
	pinCodeDetails,
} from './Profile.service';
import {
	SelfDeclarationDetailsError,
	SelfDeclarationDetailsRequest,
	SelfDeclarationDetailsSuccess,
	addAddressError,
	addAddressRequest,
	addAddressSuccess,
	addFamilyMemberError,
	addFamilyMemberRequest as addFamilyMemberRequestAction,
	addFamilyMemberSuccess,
	errorFamilyRelationShipList,
	errorUserFamilyList,
	errorUserProfile,
	// familyUploadError,
	// familyUploadRequest,
	// familyUploadSuccess,
	fetchFamilyRelationShipList,
	fetchUserFamilyListAction,
	fetchUserProfile,
	loadFamilyRelationShipList,
	loadUserFamilyList,
	loadUserProfile,
	pinCodeError,
	pinCodeRequest,
	pinCodeSuccess,
	saveFamilyEmailVerificationDetails,
	saveFamilyEmailVerificationDetailsError,
	saveFamilyEmailVerificationDetailsSuccess,
	saveFamilyVerificationDetails,
	saveFamilyVerificationDetailsError,
	saveFamilyVerificationDetailsSuccess,
	updateArError,
	updateArRequest,
	updateArSuccess,
	verifyGenerateOtpForEmailError,
	verifyGenerateOtpForEmailRequest,
	verifyGenerateOtpForEmailSuccess,
	verifyGenerateOtpForMobileError,
	verifyGenerateOtpForMobileRequest,
	verifyGenerateOtpForMobileSuccess,
	verifyOtpEmailError,
	verifyOtpEmailRequest,
	verifyOtpEmailSuccess,
	verifyOtpMobileNoError,
	verifyOtpMobileNoRequest,
	verifyOtpMobileNoSuccess,
} from './Profile.slice';
import { setAlertInfo } from '../Common/Common.slice';
// import { handleImageUpload } from '../ImageUpload/Upload.saga';
import { APP_ROUTES } from '../../../common/constants/Routes';

function* handleFetchUserProfileInformation() {
	// alert();
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				// eiaNo: 'eiaNo',
			},
		};
		const { data }: any = yield* call(fetchUserProfileData, requestPayload);
		yield* put(loadUserProfile(data));
	} catch (e: any) {
		yield* put(errorUserProfile(e.message));
	}
}

function* handleFetchUserFamilyListInformation() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchUserFamilyList, requestPayload);
		const familyList = data.FamilyMembers ? data.FamilyMembers : [];
		yield* put(loadUserFamilyList(familyList));
	} catch (e: any) {
		yield* put(errorUserFamilyList(e.message));
	}
}

function* handleAddFamilyMember({ payload: { handleSuccess, ...payload } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			...payload,
		};
		const { data }: any = yield* call(addFamilyMemberRequest, requestPayload);
		yield* put(addFamilyMemberSuccess(data));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		if (handleSuccess) {
			handleSuccess();
		}
		yield* put(fetchUserFamilyListAction());
		yield call(handleFetchUserProfileInformation);
	} catch (e: any) {
		yield* put(addFamilyMemberError(e.message));
	}
}

function* handleFetchFamilyRelationShip() {
	try {
		const { data }: any = yield* call(fetchFamilyRelationShipRequest);
		yield* put(loadFamilyRelationShipList(data));
	} catch (e: any) {
		yield* put(errorFamilyRelationShipList(e.message));
	}
}

function* handleOtpGenerateForMobile({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				value: payload?.mobileNo,
				flag: 'MOBILE',

				otpPurpose: payload?.Type === 'FAMILY' ? 'ADD_MOBILE' : 'UPDATE_MOBILE',
				eiaNo: user?.eiaNo,
			},
		};
		const { data }: any = yield* call(
			otpGenerateForMobileEmail,
			requestPayload
		);
		yield* put(verifyGenerateOtpForMobileSuccess(data));
		if (handleSuccess) {
			handleSuccess(data);
		}
	} catch (e: any) {
		yield* put(verifyGenerateOtpForMobileError(e.message));
	}
}

function* handleOtpGenerateForEmail({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				value: payload?.email,
				flag: 'EMAIL',
				otpPurpose: payload?.Type === 'FAMILY' ? 'ADD_EMAIL' : 'UPDATE_EMAIL',
				eiaNo: user?.eiaNo,
			},
		};
		const { data }: any = yield* call(
			otpGenerateForMobileEmail,
			requestPayload
		);
		yield* put(verifyGenerateOtpForEmailSuccess(data));
		if (handleSuccess) {
			handleSuccess(data);
		}
	} catch (e: any) {
		yield* put(verifyGenerateOtpForEmailError(e.message));
	}
}
function* handleOtpVerifyForMobile({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				value: payload.mobileNo,
				otp: payload.otpCode,
				flag: 'MOBILE',
				otpPurpose: !payload?.familyFlag ? 'UPDATE_MOBILE' : 'ADD_MOBILE',
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(otpVerifyForMobileEmail, requestPayload);
		yield* put(verifyOtpMobileNoSuccess(data));
		if (handleSuccess) {
			handleSuccess(data);
		}
	} catch (e: any) {
		yield* put(verifyOtpMobileNoError(e.message));
	}
}
function* handleOtpVerifyForEmail({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				value: payload.email,
				otp: payload.otpCode,
				flag: 'EMAIL',
				otpPurpose: !payload?.familyFlag ? 'UPDATE_EMAIL' : 'ADD_EMAIL',
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(otpVerifyForMobileEmail, requestPayload);
		yield* put(verifyOtpEmailSuccess(data));
		if (handleSuccess) {
			handleSuccess(data);
		}
		yield call(handleFetchUserFamilyListInformation);
	} catch (e: any) {
		yield* put(verifyOtpEmailError(e.message));
	}
}

function* handleSaveFamilyVerificationStatus({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				seqId: payload?.seqId || '',
				value: payload?.mobileNo,
				flag: 'MOBILE',
				// nominee: payload?.mblNominee,
				familyFlag: payload?.familyFlag,
				authorizedPerson: payload?.authorizedPerson
			},
		};
		const { data }: any = yield* call(SaveFamilyVerification, requestPayload);
		yield* put(saveFamilyVerificationDetailsSuccess(data));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield call(handleFetchUserFamilyListInformation);
	} catch (e: any) {
		yield* put(saveFamilyVerificationDetailsError(e.message));
	}
}

function* handleSaveFamilyEmailVerificationStatus({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);

		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				seqId: payload?.seqId || '',
				value: payload?.email,
				flag: 'EMAIL',
				authorizedPerson: payload?.authorizedPerson,
				familyFlag: payload?.familyFlag,
			},
		};
		const { data }: any = yield* call(SaveFamilyVerification, requestPayload);
		yield* put(saveFamilyEmailVerificationDetailsSuccess(data));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield call(handleFetchUserFamilyListInformation);
	} catch (e: any) {
		yield* put(saveFamilyEmailVerificationDetailsError(e.message));
	}
}

function* handleProfileUpdateAr({ payload: { navigation, ...payload } }: any) {
	try {
		const { data }: any = yield* call(ProfileUpdateArDetails, payload);
		yield* put(updateArSuccess(data));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		navigation.goBack();
		yield call(handleFetchUserProfileInformation);
	} catch (e: any) {
		yield* put(updateArError(e.message));
	}
}
// function* handleFamilyUpload(payload: any) {
// 	try {
// 		const user: any = yield* select(selectIsUserLoggedIn);
// 		const requestPayload = {
// 			Customer: {
// 				eiaNo: user.eiaNo,
// 			},
// 			...payload,
// 		};
// 		const data: any = yield* call(handleImageUpload, requestPayload);
// 		yield* put(familyUploadSuccess(data));
// 	} catch (e: any) {
// 		yield* put(familyUploadError(e.message));
// 	}
// }
function* handlePincodeInformation({
	payload: { handlePinCodeMaster, ...payload },
}: any) {
	try {
		const { data }: any = yield* call(pinCodeDetails, payload);
		if (handlePinCodeMaster) {
			handlePinCodeMaster(data);
		}
		yield* put(pinCodeSuccess(data));
	} catch (e: any) {
		yield* put(pinCodeError(e.message));
	}
}
function* handleAddAddress({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);

		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				...payload,
			},
		};
		const { data }: any = yield* call(addAddressDetails, requestPayload);
		yield* put(addAddressSuccess(data));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield call(handleFetchUserFamilyListInformation);
	} catch (e: any) {
		yield* put(addAddressError(e.message));
		yield call(handleFetchUserFamilyListInformation);
	}
}

function* handleFetchSelfDeclarationDetails({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchSelfDeclarationDetails, requestPayload);
		yield* put(SelfDeclarationDetailsSuccess(data?.selfDeclaration || {}));
	} catch (e: any) {
		yield* put(SelfDeclarationDetailsError(e.message));
	}
}

function* watchProfile() {
	yield* takeEvery(fetchUserProfile.type, handleFetchUserProfileInformation);
	yield* takeEvery(
		fetchUserFamilyListAction.type,
		handleFetchUserFamilyListInformation
	);
	yield* takeEvery(addFamilyMemberRequestAction.type, handleAddFamilyMember);
	yield* takeEvery(
		fetchFamilyRelationShipList.type,
		handleFetchFamilyRelationShip
	);

	yield* takeEvery(
		verifyGenerateOtpForMobileRequest.type,
		handleOtpGenerateForMobile
	);
	yield* takeEvery(
		verifyGenerateOtpForEmailRequest.type,
		handleOtpGenerateForEmail
	);
	yield* takeEvery(verifyOtpMobileNoRequest.type, handleOtpVerifyForMobile);
	yield* takeEvery(verifyOtpEmailRequest.type, handleOtpVerifyForEmail);
	yield* takeEvery(
		saveFamilyVerificationDetails.type,
		handleSaveFamilyVerificationStatus
	);
	yield* takeEvery(
		saveFamilyEmailVerificationDetails.type,
		handleSaveFamilyEmailVerificationStatus
	);
	yield* takeEvery(updateArRequest.type, handleProfileUpdateAr);
	// yield* takeEvery(familyUploadRequest.type, handleFamilyUpload);
	yield* takeEvery(pinCodeRequest.type, handlePincodeInformation);
	yield* takeEvery(addAddressRequest.type, handleAddAddress);
	yield* takeEvery(SelfDeclarationDetailsRequest.type, handleFetchSelfDeclarationDetails);
}

export {
	watchProfile,
	handleFetchUserProfileInformation,
	handleFetchUserFamilyListInformation,
};
