/* eslint-disable no-throw-literal */
import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
	fetchBanks,
	fetchPoliciesForBanks,
	getBankDetailsByIfscService,
	updateForBank,
	uploadCanceledChequeService,
} from './Bank.service';
import {
	errorLoadingPoliciesForBanks,
	loadPoliciesForBanks,
	fetchPoliciesForBanks as fetchPoliciesForBanksAction,
	fetchBanks as fetchBanksAction,
	loadBanks,
	errorLoadingBanks,
	updateBankChangesRequest,
	updateBankChangesSuccess,
	updateBankChangesError,
	selectBankChangesList,
	getBankDetailsByIfscRequest,
	getBankDetailsByIfscSuccess,
	getBankDetailsByIfscError,
	uploadCanceledChequeRequest,
	uploadCanceledChequeSuccess,
	uploadCanceledChequeError,
} from './Bank.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { setAlertInfo } from '../Common/Common.slice';
import { fetchUserProfile } from '../Profile/Profile.slice';
import { getAnalytics, logEvent } from '@firebase/analytics';

const dest = localStorage.getItem('language');

function* handleFetchPoliciesForBanks() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchPoliciesForBanks, requestPayload);
		const policies = data?.BankBasedPolicies;

		const unassignedBanks = data?.UnassignedBanks || [];
		const unverifiedBanks = data?.UnverifiedBanks || [];
		yield* put(
			loadPoliciesForBanks({
				policies,
				unassignedBanks,
				unverifiedBanks,
			})
		);
	} catch (e: any) {
		yield* put(errorLoadingPoliciesForBanks(e.message));
	}
}

function* handleFetchBanks({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				...payload,
			},
		};
		const { data }: any = yield* call(fetchBanks, requestPayload);
		yield* put(loadBanks(data?.bankList || []));
	} catch (e: any) {
		yield* put(errorLoadingBanks(e.message));
	}
}

function* finalDataSubmitBank({ payload: { handleDone, t } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const bankChanges: any[] = yield* select(selectBankChangesList);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			Actions: bankChanges,
		};
		const { data }: any = yield* call(updateForBank, requestPayload);
		yield* put(updateBankChangesSuccess(data || []));
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield* put(fetchUserProfile());
		if (handleDone) {
			handleDone();
		}
	} catch (e: any) {
		yield* put(updateBankChangesError(e.message));
	}
}

function* getBankDetailsByIfsc({
	payload: { handleSuccess, t, ...payload },
}: any) {
	try {
		const { data }: any = yield* call(getBankDetailsByIfscService, payload);

		if (data.status !== 'SUCCESS') {
			throw t('FAILED_TO_SEARCH_BANK_BY_IFSC');
		}
		const bankData = data?.response?.[0]?.DATA?.bank_details
			? data?.response?.[0]?.DATA?.bank_details
			: false;
		if (data.status !== 'SUCCESS') {
			throw t('IFSC_CODE_NOT_FOUND');
		}

		if (data.response[0].RESPONSE_TYPE === 'E') {
			yield* put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('NO_BANK_DETAILS_IFSC'),
				})
			);
		} else if (handleSuccess) {
			handleSuccess(data);
		}
		yield* put(getBankDetailsByIfscSuccess(bankData));
	} catch (e: any) {
		yield* put(getBankDetailsByIfscError(e.message));
	}
}

function* uploadCanceledCheque({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload: any = {
			eiaNo: user.eiaNo,
			payeeAccNo: payload?.payeeAccNo,
			payeeAccName: payload?.payeeAccName,
			payeeIfscCode: payload?.payeeIfscCode,
			chequeImage: payload?.chequeImage,
		};
		const { data }: any = yield* call(uploadCanceledChequeService, {
			Customer: requestPayload,
		});

		// const data = {
		//   txnId: "9a1adbbe-29ae-71e8-0204-028914700307",
		//   timestamp: "2023-05-24T18:15:21.707475100",
		//   displayMessage: "Bank Account Verified Successfully",
		//   uploadChequeStatus: "CHEQUE_VERIFIED / CHEQUE_MANUAL_VERIFICATION",
		//   OCR: {
		//     status: "SUCCESS",
		//     refNo: "20230524181504421733",
		//     address: "V-22B, VIRAVATI PALACE BHOJA MARKET,SECTOR-27 201301",
		//     ifsc: "PUNB0070710",
		//     accountNumber: "1113155000015020",
		//     bankName: "SKYLARK",
		//     chequeNumber: "31",
		//     accountType: "Savings",
		//     micrCode: "",
		//     contact: "911202534352",
		//     branch: "NOIDA",
		//     name: "GAURAV AGGARWAL,SUNAYANA AGARWAL",
		//     pincode: "",
		//     country: ["IN", "IND", "INDIA"],
		//     city: ["NOIDA"],
		//     district: ["GAUTAM BUDDHA NAGAR"],
		//     state: [["UTTAR PRADESH", "UP"]],
		//     dob: "",
		//     gender: "",
		//     guardianName: "",
		//     issueDate: "",
		//     expiryDate: "",
		//   },
		//   PennyDrop: {
		//     paymt_status: "S",
		//     epsClientCode: "8650",
		//     uniqueRefNo: "8650_00004269",
		//     beneName: "GAURAV AGGARWAL,SUNAYANA AGARWAL",
		//     beneAcctNo: "GAURAV AGARWAL",
		//     beneIFSC: "PUNB0070770",
		//     pymtAmount: "1.00",
		//     paymt_date: "24-May-2023",
		//     paymt_err_code: "IM000",
		//     paymt_err_desc: "SUCCESS",
		//     upi_ref_no: "314418332161",
		//     addl_tran_ref: "Addl. Tran. Ref.",
		//     Name_Match: "",
		//     Enable_Match: "",
		//     Request_Name_Match_Perc: "0",
		//     Response_Name_Match_Perc: "0",
		//   },
		//   NameMatch: {
		//     status: "SUCCESS",
		//     refNo: "20230524181521054186",
		//     similarity: "0.9230769230769231",
		//     similarityPercentage: 92.31,
		//   },
		//   errorCode: "0",
		//   errorDescription: "",
		// };

		// const data = {
		// 	txnId: 'd4c52947-0716-4192-947d-d352d0a6237d',
		// 	timestamp: '2023-08-11T15:24:40.526875633',
		// 	displayMessage:
		// 		'Request is moved to backend for verification. Request will be checked before 24 hours',
		// 	uploadStatus: 'CHEQUE_MANUAL_VERIFICATION',
		// 	errorCode: '0',
		// 	errorDescription: '',
		// };

		yield* put(uploadCanceledChequeSuccess(data));
		if (handleSuccess) {
			yield call(handleSuccess, data);
		}
		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
	

		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'uploadCanceled_Cheque_button_click', {
				button_name: 'uploadCanceled_Cheque_button',
				Select_Languages: dest,
				Status: 'Success',
				Devices_type: 'Web',
			});
		}
	} catch (e: any) {
		yield* put(uploadCanceledChequeError(e.message));
		yield* put(
			setAlertInfo({
				open: true,
				type: 'FAILED',
				description: e.response.data.errorDescription,
			})
		);
		if (process.env.REACT_APP_MODE === 'PROD') {
			const analytics = getAnalytics();
			logEvent(analytics, 'uploadCanceled_Cheque_button_click', {
				button_name: 'uploadCanceled_Cheque_button',
				Select_Languages: dest,
				Status: 'Failure',
				Devices_type: 'Web',
			});
		}
	}
}

function* watchBanks() {
	yield* takeEvery(
		fetchPoliciesForBanksAction.type,
		handleFetchPoliciesForBanks
	);
	yield* takeEvery(fetchBanksAction.type, handleFetchBanks);
	yield* takeEvery(updateBankChangesRequest.type, finalDataSubmitBank);
	yield* takeEvery(getBankDetailsByIfscRequest.type, getBankDetailsByIfsc);
	yield* takeEvery(uploadCanceledChequeRequest.type, uploadCanceledCheque);
}

// eslint-disable-next-line import/prefer-default-export
export { watchBanks };


