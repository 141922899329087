import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import CustomButton from '../../../../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../../../../common/constants/Routes';
import { AddressPolicyLock, EmailPolicyLock, MobilePolicyLock } from '../../../../../../common/utils';

interface CommunicationProps {
	communicationDetails?: any;
	isArchive?: any;
}
const CommunicationDetails: React.FC<CommunicationProps> = ({
	communicationDetails,
	isArchive,
}) => {
	const { t } = useTranslation();
	return (
		<Grid container className="py-10">
			<Grid item xs={12} className=" mb-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('MOBILE')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{communicationDetails && communicationDetails?.mobile?.length !== 0
						? `+91 ${communicationDetails?.mobile}`
						: communicationDetails?.mobile?.length === 0
							? '-'
							: ''}
				</Typography>
				{!isArchive &&
					!MobilePolicyLock(communicationDetails) &&
					communicationDetails?.mobile?.length !== 0 ? (
					<Link to={APP_ROUTES.UPDATE_MOBILE}>
						<CustomButton
							text={t('CHANGE_NUMBER')}
							variant="text"
							color="primary"
							showIcon={false}
							fontSize={12}
						/>
					</Link>
				) : (
					!MobilePolicyLock(communicationDetails) &&
					communicationDetails?.mobile?.length === 0 && (
						<Link to={APP_ROUTES.UPDATE_MOBILE}>
							<CustomButton
								text={t('TAP_TO_ADD')}
								variant="text"
								color="primary"
								showIcon={false}
								fontSize={12}
							/>
						</Link>
					)
				)}
			</Grid>
			<Grid item xs={12} className="py-10 mb-10">
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('EMAIL')}
				</Typography>
				<Typography color={theme.palette.common.black} fontSize={18}>
					{communicationDetails &&
						communicationDetails?.email?.length === 0
						? '-'
						: communicationDetails?.email}
				</Typography>
				{!isArchive &&
					!EmailPolicyLock(communicationDetails) &&
					communicationDetails?.email?.length !== 0 ? (
					<Link to={APP_ROUTES.EMAIL}>
						<CustomButton
							text={t('CHANGE_EMAIL')}
							variant="text"
							color="primary"
							showIcon={false}
							fontSize={12}
						/>
					</Link>
				) : (
					!EmailPolicyLock(communicationDetails) &&
					communicationDetails?.email?.length === 0 && (
						<Link to={APP_ROUTES.EMAIL}>
							<CustomButton
								text={t('TAP_TO_ADD')}
								variant="text"
								color="primary"
								showIcon={false}
								fontSize={12}
							/>
						</Link>
					)
				)}
			</Grid>
			{/* {communicationDetails?.alternateCode !== 'L' && ( */}
			<Grid item xs={12}>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('PERMANENT_ADDRESS')}
				</Typography>

				{communicationDetails?.address1 !== '' ||
					communicationDetails?.address2 !== '' ||
					communicationDetails?.address3 !== '' ||
					communicationDetails?.city !== '' ||
					communicationDetails?.district !== '' ||
					communicationDetails?.state !== '' ||
					communicationDetails?.country !== '' ||
					communicationDetails?.pinCode !== '' ? (
					<>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.address1}
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.address2}
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.address3}
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.city}
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.district}
						</Typography>

						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.state}
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.country}
						</Typography>
						<Typography color={theme.palette.common.black} fontSize={18}>
							{communicationDetails?.pinCode}
						</Typography>
					</>
				) : (
					'-'
				)}
			</Grid>
			{/* )} */}
			<Grid item xs={12} className='mt-20'>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('COMMUNICATION_ADDRESS')}
				</Typography>

				<>
					{communicationDetails?.commAddress1 !== '' ||
						communicationDetails?.commAddress2 !== '' ||
						communicationDetails?.commAddress3 !== '' ||
						communicationDetails?.commCity !== '' ||
						communicationDetails?.commDistrict !== '' ||
						communicationDetails?.commState !== '' ||
						communicationDetails?.commCountry !== '' ||
						communicationDetails?.commPinCode !== '' ? (
						<>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commAddress1}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commAddress2}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commAddress3}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commCity}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commDistrict}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commState}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commCountry}
							</Typography>
							<Typography color={theme.palette.common.black} fontSize={18}>
								{communicationDetails?.commPinCode}
							</Typography>
						</>
					) : (
						!isArchive &&
						communicationDetails?.commAddress1 === '' &&
						communicationDetails?.commAddress2 === '' &&
						communicationDetails?.commAddress3 === '' &&
						communicationDetails?.commCity === '' &&
						communicationDetails?.commDistrict === '' &&
						communicationDetails?.commState === '' &&
						communicationDetails?.commCountry === '' &&
						communicationDetails?.commPinCode === '' &&
						'-'
					)}
				</>
			</Grid>

			<Grid item container xs={11}>
				<Grid item xs={12}>
					{!isArchive &&
						!AddressPolicyLock(communicationDetails) &&
						(communicationDetails?.commAddress1 !== '' ||
							communicationDetails?.commAddress2 !== '' ||
							communicationDetails?.commAddress3 !== '' ||
							communicationDetails?.commCity !== '' ||
							communicationDetails?.commDistrict !== '' ||
							communicationDetails?.commState !== '' ||
							communicationDetails?.commCountry !== '' ||
							communicationDetails?.commPinCode !== '') ? (
						<Link to={APP_ROUTES.MANAGE_ADDRESS}>
							<CustomButton
								text={t('CHANGE_ADDRESS')}
								variant="text"
								color="primary"
								showIcon={false}
								fontSize={12}
							/>
						</Link>
					) : (
						!AddressPolicyLock(communicationDetails) &&
						communicationDetails?.commAddress1 === '' &&
						communicationDetails?.commAddress2 === '' &&
						communicationDetails?.commAddress3 === '' &&
						communicationDetails?.commCity === '' &&
						communicationDetails?.commDistrict === '' &&
						communicationDetails?.commState === '' &&
						communicationDetails?.commCountry === '' &&
						communicationDetails?.commPinCode === '' && (
							<Link to={APP_ROUTES.MANAGE_ADDRESS}>
								<CustomButton
									text={t('TAP_TO_ADD')}
									variant="text"
									color="primary"
									showIcon={false}
									fontSize={12}
								/>
							</Link>
						)
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CommunicationDetails;
