/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable default-param-last */
/* eslint-disable global-require */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { groupBy, pipe, prop, reject } from 'ramda';
import axios from 'axios';
import { DATE_FORMAT_ENUMS, POLICY_LOCK_FLAG } from './Enums';

export const setOAuthTokenData = (data: any) => {
	const cookies = new Cookies();
	const expires_in = data.expires_in * 1000;
	cookies.set('access_token', data.access_token);
	cookies.set('refresh_token', data.refresh_token);
	cookies.set('token_expires_at', dayjs(new Date()).valueOf() + expires_in);
};

export const dummyDelay = (delay: number | undefined = 1000) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, delay);
	});

export enum RequestTypes {
	MOBILE = 'MOBILE',
	EMAIL = 'EMAIL',
}

export enum LOCAL_VARIABLES {
	SESSION_ID = 'sessionId',
	MPIN_NEVER_ASK = 'hasMPINNeverAsk',
	MPIN_FLAG = 'hasMPINFlag',
	AUTH_USER_INFO = 'authUserInfo',
	FINGER_PRINT = 'FINGER_PRINT_ENABLED',
}

export interface AuthUser {
	eiaNo: string;
	panNo: string;
	mobileNo: string;
	email: string;
}

export const isArray = (obj: any) => obj instanceof Array;

export const isDev = () => process.env.REACT_APP_MODE === 'dev';

export const numericOnly = (text: string): string =>
	text.replace(/[^0-9]/g, '');
export const alphabetsOnly = (text: string): string =>
	text.replace(/[^a-zA-Z\s]/g, '');

// export const getDeviceInfo = () =>
//   new Promise(async (resolve, reject) => {
//     const deviceId = "NIL";
//     const deviceName = await DeviceInfo.getDeviceName();
//     const deviceOs = await DeviceInfo.getBaseOs();
//     const ipAddress = await DeviceInfo.getIpAddress();
//     const osVersion = DeviceInfo.getSystemVersion();
//     resolve({
//       appType: "MOBILE",
//       osVersion: osVersion,
//       deviceId: deviceId,
//       deviceName: deviceName,
//       deviceOS: deviceOs,
//       appVersion: "2.0",
//       clientIp: ipAddress,
//       origin: "",
//     });
//   });
const base64ToBinary = (base64: any) => {
	const binaryString = atob(base64);
	const bytes = new Uint8Array(binaryString.length);

	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes;
};
const createBlob = (data: any) => {
	const blob = new Blob([data], { type: 'application/pdf' });
	return blob;
};

export const getFormatDate = (date: string, dateFormat?: any) => {
	if (date?.length === 0 || date === undefined) {
		return '-';
	}
	return dayjs(date, dateFormat).format(DATE_FORMAT_ENUMS.LABEL) ===
		'Invalid Date'
		? date || '-'
		: dayjs(date, dateFormat).format(DATE_FORMAT_ENUMS.LABEL);

};

export const saveBase64AsFile = (
	base64Data?: any,
	fileName?: any,
	mimeType: string = 'base64',
	extension: string = 'pdf'
) =>
	new Promise(async (res, rej) => {
		try {
			const saveFile = require('save-file');
			const folderPath = fileName;
			const base64Data1 = base64Data;

			const binaryData = base64ToBinary(base64Data1);
			const blob = createBlob(binaryData);

			const fileContent = blob;
			saveFile(`${folderPath}/.pdf`, fileContent).then(() => { });
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('File save failed --->', e);
			rej(e);
		}
	});

export const verifyNullForObject = (object: any) => {
	for (const key in object) {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			if (object[key] === 'null' || object[key] === undefined) {
				object[key] = '';
			} else if (object[key] === 'string' && object[key].trim() === '') {
				object[key] = '';
			}
		}
	}
	return object;
};

export const verifyNullForArray = (data: any[]) =>
	data?.map((item) => verifyNullForObject(item));

export const convertLowerCaseToUpperCase = (data: any) =>
	String(data || '').toUpperCase();

export const getRandomNumber = (length: any) =>
	Math.floor(10 ** (length - 1) + Math.random() * 9 * 10 ** (length - 1));

export const convertAmount = (val: any) => {
	const parsedValue =
		!val || val === '0' || val === 'null' || val === '' ? '0' : Number(val);
	const formattedValue = parsedValue.toLocaleString('en-IN');
	if (formattedValue.endsWith('.00')) {
		return formattedValue.slice(0, -3);
	}
	return formattedValue;
};

export const trimWhiteSpaceForObject = (object: any) => {
	for (const key in object) {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			if (object[key] === 'null' || object[key] === undefined) {
				object[key] = '';
			} else if (object[key] === 'string' && object[key].trim() === '') {
				object[key] = '';
			}
		}
	}
	return object;
};

export const generateFullAddress = (address: any) =>
	address
		? `${address?.addressLine1 || address?.address1}, ${address?.addressLine2 || address?.address2}, ${address?.city}, ${address?.addressLine3 || address?.address3}, ${address?.state}-${address?.pincode}`
		: false;

export const getURLParams = (parameterName: string, url: string) => {
	const name = parameterName.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return null;
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const generateTransactionIDForRazorPay = (
	suffix?: any,
	insurerCode?: any
) =>
	`FY${dayjs(new Date()).format('YY')}${String(insurerCode).padStart(
		3,
		'0'
	)}${dayjs(new Date()).format('DDMMYYYYHHmmssSSS')}${suffix}`;

export const removeDuplicatedPoliciesForAssign = (
	policies: any[],
	newPolicies: any[]
) => {
	const newPoliciesArray = newPolicies
		? newPolicies?.map(({ policyNo }: any) => policyNo)
		: [];
	const duplicateRemovedCurrentList = reject(
		({ policyNo }) => !!newPoliciesArray.includes(policyNo),
		policies
	);
	return duplicateRemovedCurrentList || [];
};

export const generatePermanentAddressFromEkyc = (data: any) => {
	if (data) {
		const address = String(data?.ADDRESS).split(',');
		const newData: any = {
			address1: String(address?.[0]).trim(),
			address2: String(address?.[1]).trim(),
			address3: data?.CITY,
			city: data?.CITY,
			state: data?.STATE,
			country: data?.COUNTRY,
			pincode: data?.PINCODE,
			investHorizon: '',
			addressVerified: 'Y',
		};
		newData.fullAddress = generateFullAddress(newData);
		return newData;
	}
	return {};
};

export const convertToString = (val: any) => val || '';

export const convertUnAssignedBanksToPolicyType = (banks: any[]) =>
	banks
		? banks.map((bank) => ({
			bankAcNo: bank?.accountNo,
			bankBranch: bank?.bankBranch,
			bankIfscCode: bank?.ifscCode,
			bankName: bank?.bankName,
			accountHolderName: bank?.accountHolderName,
			bankLogo: bank?.bankLogo,
			chequeImage: bank?.chequeImage,
			bankVerified: bank?.bankVerified,
		}))
		: [];

export const swapToTop = (
	list: any[],
	targetValues: any[],
	propName: string
) => {
	list = list || [];
	list.forEach((item, i) => {
		if (targetValues.includes(item[propName])) {
			list.splice(i, 1);
			list.unshift(item);
		}
	});

	return list;
};

export const verifyFullAddressString = (address: any) =>
	String(address || '')
		.replace(',', '')
		.replace('-', '')
		.trim();

export const convertToBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export const stripBase64Prefix = (base64: string) => base64.split(',')[1];
export const appendBase64PrefixForImage = (base64: string) =>
	`data:image/png/jpg/jpeg;base64,${base64}`;

export const createEkycPopup = (
	url: string | false = false,
	handleFeedBack?: any
) => {
	if (url) {
		const params: any[] = [
			'popup=true',
			'menubar=1',
			'resizable=1',
			`width=${window.screen.width}`,
			`height=${window.screen.height}`,
			'left=0',
			'top=0',
			'fullscreen=yes',
		];
		const popup: any = window.open(url, 'popup', params.join(','));

		const checkPopup = setInterval(() => {
			//  if (popup.window.location.href
			//     .includes(CLIENT_URL)) {popup.close()}
			if (!popup || !popup.closed) return;
			handleFeedBack(popup);
			clearInterval(checkPopup);
		}, 1000);
	}
};

export const getIpAddress = async () =>
	new Promise(async (resolve) => {
		try {
			const config: any = {
				method: 'get',
				maxBodyLength: Infinity,
				url: 'https://geolocation-db.com/json/',
				headers: {},
			};

			const { data } = await axios.request(config);

			resolve(data.IPv4);
		} catch (error) {
			resolve('0.0.0.0');
		}
	});
const splitDateString = (dateString: any) => dateString.split('-');

const processDate = ([day, month, year]: any) => new Date(year, month - 1, day);

const dayjsFormatDate = (date: any) => dayjs(date).format('DD/MM/YYYY');

export const convertDateToServerFormat = pipe(
	splitDateString,
	processDate,
	dayjsFormatDate
);

export const parseMobileNo = (mobileNo: any) => {
	const numberPattern = /\d+/g;
	const matches: any[] | null = String(mobileNo).match(numberPattern);
	return matches ? matches.join('').substring(0, 10) : '';
};

export const parsePinCode = (mobileNo: any) => {
	const numberPattern = /\d+/g;
	const matches: any[] | null = String(mobileNo).match(numberPattern);
	return matches ? matches.join('').substring(0, 6) : '';
};

export const ageCalculator = (targetDate: any) => {
	if (!targetDate) return false;
	const now = dayjs();
	return now.diff(dayjs(targetDate), 'years');
};
export const countryCode = (code: any) => {
	const numberPattern = /\d+/g;
	const matches: any[] | null = String(code).match(numberPattern);
	return matches ? matches.join('').substring(0, 3) : '';
};
export const maskEmail = (email: string) => {
	if (!email) {
		return '';
	}
	const atIndex = email.lastIndexOf('@');

	// Check if there are enough characters before '@' to apply masking
	if (atIndex > 4) {
		const visiblePart = email.substring(0, 1);
		const domainPart = email.substring(atIndex - 4, atIndex);
		const repeatCount = Math.max(atIndex - 5, 0);
		const maskedEmail = `${visiblePart}${'*'.repeat(
			repeatCount
		)}${domainPart}${email.substring(atIndex)}`;
		return maskedEmail;
	}

	// If there are fewer than 4 characters before '@', mask only the first letter
	if (atIndex > 0) {
		const visiblePart = email.substring(0, 1);
		const domainPart = email.substring(atIndex);
		return `${visiblePart}*${domainPart}`;
	}

	// If the email is too short, return it as it is
	return email;
};
export const maskMobileNumber = (mobileNo: any) => {
	if (!mobileNo) {
		return '';
	}

	const maskedNumber =
		mobileNo.slice(0, -4).replace(/\d/g, '*') + mobileNo.slice(-4);
	return maskedNumber;
};

export const maskAadhar = (aadhar: any) => {
	if (!aadhar) {
		return '';
	}

	const maskedAadhar =
		aadhar.slice(0, -4).replace(/\d/g, '*') + aadhar.slice(-4);
	return maskedAadhar;
};

export const groupPoliciesByALCode = (policiesList: any) => {
	return groupBy<any>(prop<string>('alternateCode'))(
		policiesList
	);
}

export const PolicyLock = (policyDetails: any) => policyDetails?.insIntegFlag?.toUpperCase() !== 'Y' || POLICY_LOCK_FLAG.includes(policyDetails?.daFlag);

export const AddressPolicyLock = (policyDetails: any) => PolicyLock(policyDetails) || policyDetails?.addressEndoFlag?.toUpperCase() !== 'Y' || policyDetails?.endoAddressUpdatedStatus?.toUpperCase() === 'N';

export const BankPolicyLock = (policyDetails: any) => PolicyLock(policyDetails) || policyDetails?.bankEndoFlag?.toUpperCase() !== 'Y' || policyDetails?.endoBankUpdatedStatus?.toUpperCase() === 'N';

export const MobilePolicyLock = (policyDetails: any) => PolicyLock(policyDetails) || policyDetails?.mobileEndoFlag?.toUpperCase() !== 'Y' || policyDetails?.endoMobileUpdatedStatus?.toUpperCase() === 'N';

export const EmailPolicyLock = (policyDetails: any) => PolicyLock(policyDetails) || policyDetails?.emailEndoFlag?.toUpperCase() !== 'Y' || policyDetails?.endoEmailUpdatedStatus?.toUpperCase() === 'N';

export const NomineePolicyLock = (policyDetails: any) => PolicyLock(policyDetails) || policyDetails?.nomineeEndoFlag?.toUpperCase() !== 'Y' || policyDetails?.Nominees?.[0]?.endoNomineeUpdatedStatus?.toUpperCase() === 'N' || policyDetails?.endoNomineeUpdatedStatus?.toUpperCase() === 'N';