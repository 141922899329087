import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import './Mobile.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { groupBy, prop } from 'ramda';
import MobileNumberHeader from './MobileNumberHeader';
import MobileHeader from './MobileHeader';
import {
	changeMobileNumber,
	fetchPoliciesForMobileNumber,
	generateOtpForMobileRequest,
	isGenerateOtpForMobileLoading,
	isUpdateMobileNumbersLoading,
	removeMobileNumber,
	changePrimaryNumber,
	selectIsMobileNoVerifyLoading,
	selectIsPolicyMobileNumbersLoading,
	selectMobileNoChangesList,
	selectPoliciesForMobileNumbers,
	setMobileNoChangesList,
	updateMobileNoRequest,
	verifyMobileNoRequest,
	assignMobileNumber,
} from './MobileNumber.slice';
import { setAlertInfo } from '../Common/Common.slice';
import config from '../../../common/config';
import {
	fetchOtpRequest,
	selectIsOtpRequestLoading,
	selectIsUserLoggedIn,
} from '../../Auth/auth.slice';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { SELECT_POLICY_TYPES_FOR_MODULES } from '../../../common/Enums';
import MobileEntryModal from '../Common/MobileEntryModal';
import OtpEntryModal from '../Common/OtpEntryModal';
import DoneModal from '../Common/DoneModal';
import ChangeNumberModal from './Modals/ChangeNumberModal';
import RemoveNumberModal from './Modals/RemoveNumberModal';
import Spinner from '../../../common/ui/Spinner';
import ConfirmationModal from '../../../common/ui/Modal/ConfirmationModal';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const MobileNumberScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const Location = useLocation();

	const { t } = useTranslation();

	const mobile = Location.state?.mobileNumber;
	const [showAddModal, setShowAddModal] = useState(false);
	const [showDoneModal, setShowDoneModal] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [showRemoveNumberModal, setShowRemoveNumberModal] = useState(false);
	const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
	const [selectedPolicyDetails, setSelectedPolicyDetails] = useState<any>({});
	const [isPrimaryChange, setIsPrimaryChange] = useState<boolean>(false);
	const [selectedMobileNo, setSelectedMobileNo] = useState(false);
	const [newMobileNoData, setNewMobileNoData] = useState<any>({});
	const [verifyMobileDuration, setVerifyMobileDuration] = useState(0);
	const [isInstantVerifyRunning, setIsInstantVerifyRunning] = useState(false);
	const [removeUnAssignedMobileNo, setRemoveUnAssignedMobileNo] =
		useState<any>(false);

	const [isPreSelectedPolicyType, setIsPreSelectedPolicyType] =
		useState<any>(false);

	const isUpdateMobileLoading = useSelector(isUpdateMobileNumbersLoading);
	const isMobileGenerateOtpLoading = useSelector(isGenerateOtpForMobileLoading);
	const isMobileVerifyLoading = useSelector(selectIsMobileNoVerifyLoading);
	const mobileChangesList = useSelector(selectMobileNoChangesList);
	const toggleAddNumberModal = (
		isPoliciesPreSelected: boolean,
		isChange: boolean,
		primary: any
	) => {
		if (isPoliciesPreSelected === true) {
			setIsPreSelectedPolicyType(
				primary ? 'CHANGEPRIMARY' : isChange ? 'CHANGE' : 'REMOVE'
			);
		} else {
			setIsPreSelectedPolicyType(false);
		}
		setShowAddModal(!showAddModal);
		setShowRemoveNumberModal(false);
		setShowChangeNumberModal(false);
	};

	const toggleDoneModal = () => {
		if (mobileChangesList?.length > 0) {
			setShowDoneModal(!showDoneModal);
		} else {
			navigate(-1);
		}
		return true;
	};

	useEffect(() => {
		if (Location.state === 'Profile') {
			setShowAddModal(true);
		} else {
			setShowAddModal(false);
		}
	}, [Location.state]);

	const toggleRemoveNumberModal = (mobile: string) => {
		setSelectedMobileNo(mobile as any);
		setShowRemoveNumberModal(!showRemoveNumberModal);
	};
	const togglePrimaryChangeNumberModal = (data: any) => {
		if (data) {
			setSelectedPolicyDetails(data);
			setShowChangeNumberModal(!showChangeNumberModal);
			setIsPrimaryChange(true);
		}
	};

	const toggleChangeNumberModal = (data: any) => {
		setIsPrimaryChange(false);
		setSelectedPolicyDetails(data);
		setShowChangeNumberModal(!showChangeNumberModal);
	};

	const closeAllModals = () => {
		setShowAddModal(false);
		setShowDoneModal(false);
		setShowOtpModal(false);
		setShowRemoveNumberModal(false);
		setShowChangeNumberModal(false);
	};

	const isPoliciesForMobileNumbersLoading = useSelector(
		selectIsPolicyMobileNumbersLoading
	);

	const policiesForMobileNumber = useSelector(selectPoliciesForMobileNumbers);
	useEffect(() => {
		if (mobileChangesList?.length === 0)
			dispatch(fetchPoliciesForMobileNumber());
	}, [dispatch, mobileChangesList]);
	useEffect(() => {
		if (mobile) {
			togglePrimaryChangeNumberModal({ mobileNo: mobile });
			history.replaceState(null, Location?.pathname);
		}
	}, [mobile]);
	const handleMobileNoChangesSubmit = () => {
		const handleSuccess: any = () => {
			setShowDoneModal(false);
			navigate(-1);
		};
		dispatch(updateMobileNoRequest({ handleSuccess, t } as any));
	};

	const anyIntegPolicies = useMemo(() =>
		policiesForMobileNumber?.mobileBasedPolicies?.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForMobileNumber]);

	const handleOtpVerify = (otpCode: any) => {
		const { handleSuccess, ...newMobileNoDataLocal }: any = newMobileNoData;
		const data: any = { otpCode, mobileNo: newMobileNoDataLocal?.mobileNo };
		data.handleSuccess = () => {
			setShowAddModal(false);

			if (isPreSelectedPolicyType === 'CHANGE') {
				setShowOtpModal(false);
				setIsPreSelectedPolicyType(false);
				dispatch(
					changeMobileNumber({
						selectedPolicy: selectedPolicyDetails,
						targetMobileNo: newMobileNoDataLocal?.mobileNo,
					})
				);
			} else if (isPreSelectedPolicyType === 'REMOVE') {
				setShowOtpModal(false);
				dispatch(
					removeMobileNumber({
						newValue: newMobileNoDataLocal?.mobileNo,
						oldValue: selectedMobileNo,
					})
				);
			} else if (isPreSelectedPolicyType === 'CHANGEPRIMARY') {
				setShowOtpModal(false);
				dispatch(
					changePrimaryNumber({
						selectedNumber: selectedPolicyDetails?.mobileNo,
						targetedNumber: newMobileNoDataLocal?.mobileNo,
						isNew: true,
					})
				);
			} else {
				setShowAddModal(false);
				if (isInstantVerifyRunning !== true) {
					if (anyIntegPolicies) {
						navigate(APP_ROUTES.ASSIGN_POLICY, {
							state: {
								newMobileNoData: newMobileNoDataLocal,
								MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.MOBILE,
							},
						});
					}
					else {
						const payload = {
							selectedPolicies: [],
							newMobileNo: newMobileNoDataLocal?.mobileNo,
							isOld: false,
							primaryFlag: newMobileNoDataLocal?.primaryFlag ?? 'Z',
							purpose: '',
						};
						dispatch(assignMobileNumber(payload));
					}

					setIsInstantVerifyRunning(false);
				}
				setShowOtpModal(false);
			}
		};
		dispatch(verifyMobileNoRequest(data));
	};

	const handleNewMobileSubmit = (data: any) => {
		const groupByMobileNo: any = groupBy<any>(
			prop<any>('mobileNo'),
			policiesForMobileNumber?.mobileBasedPolicies
		);
		const isMobileNoDuplicate =
			data?.mobileNo in groupByMobileNo &&
			groupByMobileNo[data?.mobileNo].length > 0;

		const isMobileNoAssigned = policiesForMobileNumber?.unAssignedMobiles?.some(
			(item: any) => item.mobileNo === data?.mobileNo
		);
		if (isMobileNoDuplicate || isMobileNoAssigned) {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('MOBILE_NUMBER_IS_ALREADY_EXIST'),
				})
			);
			setShowAddModal(false);
		} else if (policiesForMobileNumber?.unAssignedMobiles?.length <= 5) {
			setNewMobileNoData(data);
			data.handleSuccess = () => {
				setShowAddModal(false);
				setShowOtpModal(true);
				setVerifyMobileDuration(config.otpDuration);
			};
			dispatch(generateOtpForMobileRequest(data));
		} else {
			setShowAddModal(false);
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: 'Only 5 unassigned  mobile numbers are allowed.',
				})
			);
		}
	};

	const handleInstantVerify = (mobileNo: string) => {
		setIsInstantVerifyRunning(true);
		closeAllModals();
		setNewMobileNoData({ mobileNo, mobileCountryCode: '+91' });
		setShowOtpModal(true);
		dispatch(
			generateOtpForMobileRequest({ mobileNo, mobileCountryCode: '+91' } as any)
		);
		setVerifyMobileDuration(config.otpDuration);
	};

	const handleCancel = () => {
		navigate(-1);
		dispatch(setMobileNoChangesList([]));
	};

	const loggedUser = useSelector(selectIsUserLoggedIn);
	const isResendOtpLoading = useSelector(selectIsOtpRequestLoading);

	const handleResendOtp = () => {
		const handleSuccess = () => {
			setVerifyMobileDuration(config.otpDuration);
		};
		const data: any = {
			eiaNo: loggedUser?.eiaNo,
			value: newMobileNoData?.mobileNo,
			otpPurpose: 'ADD_MOBILE',
			flag: 'MOBILE',
		};
		dispatch(
			fetchOtpRequest({
				Customer: data,
				handleSuccess,
			} as any)
		);
	};

	const handleRemoveForUnAssignedMobiles = () => {
		if (removeUnAssignedMobileNo.primaryFlag === 'Y') {
			setShowChangeNumberModal(true);
			setRemoveUnAssignedMobileNo(false);
			setSelectedPolicyDetails({
				type: 'primaryMobileChange',
				mobileNo: removeUnAssignedMobileNo.mobileNo,
			});
		} else {
			const handleDone = () => {
				setRemoveUnAssignedMobileNo(false);
			};
			const payload = {
				oldValue: removeUnAssignedMobileNo.mobileNo,
				isUnAssignedMobile: true,
				handleDone,
			};
			dispatch(removeMobileNumber(payload));
		}
	};

	const handleAssignPolicy = (data: any) => {
		navigate(APP_ROUTES.ASSIGN_POLICY, {
			state: {
				newMobileNoData: data,
				MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.MOBILE,
				isOld: true,
			},
		});
	};

	const handleInstantVerifyFromChangeModal = (mobileNumber: string) => {
		isPrimaryChange ? setIsPreSelectedPolicyType('CHANGEPRIMARY') : setIsPreSelectedPolicyType('CHANGE');
		handleInstantVerify(mobileNumber);
	}


	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Manage Mobile Number Screen',
		});
	}, []);


	return (
		<Grid item className="ml-50 mr-50 mb-50 mobile-style">
			<Grid item className="fixed-policy-header">
				<MobileHeader
					toggleAddNumberModal={toggleAddNumberModal}
					toggleDoneModal={toggleDoneModal}
				/>
			</Grid>
			<div
				style={{
					marginTop: 85,
				}}
			/>
			<Grid item>
				{isPoliciesForMobileNumbersLoading ? (
					<Grid container justifyContent="center">
						<Grid item>
							<Spinner />
						</Grid>
					</Grid>
				) : (
					<MobileNumberHeader
						toggleChangeNumberModal={toggleChangeNumberModal}
						toggleRemoveNumberModal={toggleRemoveNumberModal}
						togglePrimaryChangeNumberModal={togglePrimaryChangeNumberModal}
						handleAssignPolicy={handleAssignPolicy}
						handleRemoveForUnAssignedMobiles={(
							mobileNo: string,
							primaryFlag: string = 'Z'
						) => setRemoveUnAssignedMobileNo({ mobileNo, primaryFlag } as any)}
						handleInstantVerify={handleInstantVerify}
					/>
				)}
			</Grid>
			<MobileEntryModal
				open={showAddModal}
				setOpen={setShowAddModal}
				onSubmit={handleNewMobileSubmit}
				handleCancel={closeAllModals}
				textLabel="Mobile"
				loading={isMobileGenerateOtpLoading}
			/>
			<OtpEntryModal
				open={showOtpModal}
				setOpen={setShowOtpModal}
				onSubmit={handleOtpVerify}
				value={newMobileNoData?.mobileNo}
				isEmail={false}
				duration={verifyMobileDuration}
				onFinishDuration={() => setVerifyMobileDuration(0)}
				loading={isMobileVerifyLoading}
				resendLoading={isResendOtpLoading}
				handleResendOtp={handleResendOtp}
			/>
			<DoneModal
				open={showDoneModal}
				setOpen={setShowDoneModal}
				onSubmit={handleMobileNoChangesSubmit}
				loading={isUpdateMobileLoading}
				onCancel={handleCancel}
				contentType="MOBILE"
			/>
			<ChangeNumberModal
				open={showChangeNumberModal}
				setOpen={setShowChangeNumberModal}
				selectedPolicyDetails={selectedPolicyDetails}
				toggleAddNumberModal={toggleAddNumberModal}
				showAddButton={
					!selectedPolicyDetails.type ||
					selectedPolicyDetails.type !== 'primaryMobileChange'
				}
				isPrimaryChange={isPrimaryChange}
				handleInstantVerify={handleInstantVerifyFromChangeModal}
			/>
			<RemoveNumberModal
				open={showRemoveNumberModal}
				setOpen={setShowRemoveNumberModal}
				selectedMobileNo={selectedMobileNo}
				toggleAddNumberModal={toggleAddNumberModal}
				handleInstantVerify={handleInstantVerifyFromChangeModal}
			/>
			<ConfirmationModal
				show={
					!!removeUnAssignedMobileNo?.mobileNo &&
					!!removeUnAssignedMobileNo?.primaryFlag
				}
				setShow={setRemoveUnAssignedMobileNo}
				description={`${t(
					'ARE_YOU_SURE_TO_REMOVE'
				)} ${removeUnAssignedMobileNo?.mobileNo} 
				${`${t('FROM_UNASSIGNED_MOBILES')}?`}`}
				confirmText="Remove"
				cancelText="Cancel"
				onConfirm={handleRemoveForUnAssignedMobiles}
				onCancel={() => setRemoveUnAssignedMobileNo(false)}
			/>
			<ApiModal />
		</Grid>
	);
};

export default MobileNumberScreen;
