import React, { useMemo, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VerifiedText from '../VerifiedText';
import UnverifiedText from '../UnverifiedText';
import CustomButton from '../../../../common/ui/CustomButton';
import OverflowTip from '../../../../common/ui/OverflowTip';

import {
	emailVerifyOtpLoading,
	mobileVerifyOtpLoading,
	verifyGenerateOtpForEmailRequest,
	verifyGenerateOtpForMobileRequest,
	verifyOtpEmailRequest,
	verifyOtpMobileNoRequest,
} from '../../Profile/Profile.slice';
import { useDispatch, useSelector } from 'react-redux';
import OtpEntryModal from '../../Common/OtpEntryModal';
import config from '../../../../common/config';

import {
	changeMobileNumber,
	selectPoliciesForMobileNumbers,
	verifyMobileNoRequest,
} from '../MobileNumber.slice';
import { changeEmail, selectPoliciesForEmails, verifyEmailRequest } from '../../Email/Emails.slice';

const MobilePolicyHeader = ({
	index,
	mobileNo,
	verified,
	primary,
	onRemove,
	showAssignPolicyButton = false,
	handleAssignPolicy,
	showAssignPolicyRegularList = false,
	isEmail = false,
	onChangePrimary,
	policy,
}: any) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isVerifyMobileLoading = useSelector(mobileVerifyOtpLoading);
	const isVerifyEmailLoading = useSelector(emailVerifyOtpLoading);
	const [mobile, setMobile] = useState<any>({});
	const [mobileData, setMobileData] = useState<any>({});
	const [verifyMobileDuration, setVerifyMobileDuration] = useState(0);
	const [showOtpModal, setShowOtpModal] = React.useState(false);
	const [emailshowOtpModal, setEmailShowOtpModal] = React.useState(false);
	const [emailId, setEmailId] = useState<any>({});
	const [verifyEmailDuration, setVerifyEmailDuration] = useState(0);
	const [emailData, setEmailData] = useState<any>({});
	const policiesForMobileNumber = useSelector(selectPoliciesForMobileNumbers)?.mobileBasedPolicies;
	const policiesForEmail = useSelector(selectPoliciesForEmails)?.emailBasedPolicies;

	const verifyMobileNo = (data: any) => {
		if (!isEmail) {
			setMobileData(data);

			if (data) {
				const datas: any = {
					mobileNo: data,
				};

				const mobileNo = data;
				dispatch(verifyGenerateOtpForMobileRequest(datas));
				setMobile(mobileNo);

				setShowOtpModal(true);
				setVerifyMobileDuration(config.otpDuration);
			}
		} else {
			setEmailData(data);

			if (data) {
				const datas: any = {
					email: data,
				};
				const emailId = data;
				dispatch(verifyGenerateOtpForEmailRequest(datas));
				setEmailId(emailId);
				setMobile('');
				setEmailShowOtpModal(true);
				setVerifyEmailDuration(config.otpDuration);
			}
		}
	};
	const handleMobileOtpVerify = (otpCode: any) => {
		const data: any = {
			otpCode,
			mobileNo: mobile,
		};
		data.handleSuccess = () => {
			setShowOtpModal(false);
			dispatch(
				changeMobileNumber({
					selectedPolicy: policy,
					targetMobileNo: mobile,
				})
			);
		};
		dispatch(verifyMobileNoRequest(data));
	};
	const handleEmailOtpVerify = (otpCode: any) => {
		const data: any = {
			otpCode,
			email: emailId,
		};
		data.handleSuccess = () => {
			setEmailShowOtpModal(false);
			dispatch(
				changeEmail({
					selectedPolicy: policy,
					targetEmail: emailId,
				})
			);
		};

		dispatch(verifyEmailRequest(data));
	};
	const handleEmailResendotp = () => {
		setVerifyEmailDuration(config.otpDuration);
		const datas: any = {
			email: emailId,
		};
		dispatch(verifyGenerateOtpForEmailRequest(datas));
	};

	const handleResendOtp = () => {
		const datas: any = {
			mobileNo: mobile,
		};
		setVerifyMobileDuration(config.otpDuration);
		dispatch(verifyGenerateOtpForMobileRequest(datas));
	};
	const closeAllModals = () => {
		setShowOtpModal(false);
		setEmailShowOtpModal(false);
		setShowOtpModal(false);
	};
	const filterPolicy = useMemo(() =>
		isEmail ? policiesForEmail.filter((item: any) => item.email !== mobileNo) : policiesForMobileNumber.filter((item: any) => item.mobileNo !== mobileNo)
		, [policiesForMobileNumber, policiesForEmail]);



	const atleastOneIntegPoliciesHave = useMemo(() =>
		filterPolicy.some((item: any) => item.insIntegFlag === 'Y')
		, [filterPolicy]);

	const anyIntegPolicies = useMemo(() =>
		isEmail ? policiesForEmail.some((item: any) => item.insIntegFlag === 'Y') : policiesForMobileNumber.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForMobileNumber, policiesForEmail]);



	return (
		<Grid item className="">
			<Grid
				container
				justifyContent="space-between"
				wrap="nowrap"
				className=" mobile-container"
				alignItems="center"
			>

				<Grid
					item
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid flexWrap="nowrap" container spacing={0.5} className="ml-n20">
						<Grid item>
							<Typography color="black" fontSize={20} fontWeight={600}>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item className="mt-3" flex={1}>
							<Grid container alignItems="center">
								<Grid item className="mr-5">
									<Typography
										color="primary"
										fontSize={18}
										fontWeight={600}
										style={{
											maxWidth: '130px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
										noWrap
									>
										<OverflowTip>{mobileNo}</OverflowTip>
									</Typography>
								</Grid>
								<Grid item>
									{verified ? (
										<VerifiedText />
									) : (
										<UnverifiedText />
									)}
								</Grid>
								<Grid item>
									{String(primary).toUpperCase() === 'Y' ? (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											fontWeight={400}
										>
											[{t('PRIMARY')}{' '}
											{isEmail ? t('EMAIL_ID') : t('MOBILE_NUMBER')}]
										</Typography>
									) : (
										''
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{String(primary).toUpperCase() !== 'Y' && (
						<Grid item>
							<CustomButton
								text={
									String(primary).toUpperCase() === 'Y'
										? `${t('CHANGE_PRIMARY')}`
										: `${t('REMOVE')}`
								}
								variant="text"
								showIcon={false}
								color="primary"
								onClick={
									String(primary).toUpperCase() === 'Y'
										? onChangePrimary
										: onRemove
								}
								className="f-14 fw-400 "
							/>
						</Grid>
					)}
				</Grid>
				{/* <Grid item>
					<CustomButton
						text={
							String(primary).toUpperCase() === 'Y'
								? `${t('CHANGE_PRIMARY')}`
								: `${t('REMOVE')}`
						}
						variant="text"
						showIcon={false}
						color="primary"
						onClick={
							String(primary).toUpperCase() === 'Y' ? onChangePrimary : onRemove
						}
						className="f-14 fw-400 mt-5"
					/>
				</Grid> */}
			</Grid>
			<Grid container justifyContent="space-between" className="mb-10">
				{showAssignPolicyButton ? (
					<>
						<Grid
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							mt={1}
						>
							{verified ? (
								<Grid item>
									<CustomButton
										text={`${t('ASSIGN_POLICIES')}`}
										variant="text"
										showIcon={false}
										color="primary"
										onClick={handleAssignPolicy}
										className="f-14 fw-400 "
										disabled={!anyIntegPolicies}
									/>
								</Grid>
							) : (
								<Grid item>
									<CustomButton
										text={`${t('VERIFY_NOW')}`}
										variant="text"
										showIcon={false}
										color="primary"
										onClick={() => verifyMobileNo(mobileNo)}
										className="f-14 fw-400 "
									/>
								</Grid>
							)}

							{String(primary).toUpperCase() === 'Y' && (
								<Grid item ml={3}>
									<CustomButton
										text={
											String(primary).toUpperCase() === 'Y'
												? `${t('CHANGE_PRIMARY')}`
												: `${t('REMOVE')}`
										}
										variant="text"
										showIcon={false}
										color="primary"
										onClick={
											String(primary).toUpperCase() === 'Y'
												? onChangePrimary
												: onRemove
										}
										className="f-14 fw-400 "
									/>
								</Grid>
							)}
						</Grid>
					</>
				) : (
					<></>
				)}

				<Grid item container display="flex" alignItems="center">
					{showAssignPolicyRegularList ? (
						<>
							<Grid
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								mt={1}
							>
								{verified ? (
									<Grid item>
										<CustomButton
											text={`${t('ASSIGN_POLICIES')}`}
											variant="text"
											showIcon={false}
											color="primary"
											onClick={handleAssignPolicy}
											className="f-14 fw-400 "
											disabled={!atleastOneIntegPoliciesHave}
										/>
									</Grid>
								) : (
									<Grid item>
										<CustomButton
											text={`${t('VERIFY_NOW')}`}
											variant="text"
											showIcon={false}
											color="primary"
											onClick={() => verifyMobileNo(mobileNo)}
											className="f-14 fw-400 "
										/>
									</Grid>
								)}
								{String(primary).toUpperCase() === 'Y' && (
									<Grid item ml={3}>
										<CustomButton
											text={
												String(primary).toUpperCase() === 'Y'
													? `${t('CHANGE_PRIMARY')}`
													: `${t('REMOVE')}`
											}
											variant="text"
											showIcon={false}
											color="primary"
											onClick={
												String(primary).toUpperCase() === 'Y'
													? onChangePrimary
													: onRemove
											}
											className="f-14 fw-400 "
										/>
									</Grid>
								)}
							</Grid>
						</>
					) : (
						<></>
					)}
				</Grid>
				<Grid item container display="flex" alignItems="center">
					<Grid item width={290}  >
						{verified && !atleastOneIntegPoliciesHave && !anyIntegPolicies && isEmail && policiesForEmail?.length !== 0 &&
							<Typography className='f-12' color={theme.palette.grey[400]}>This service is not currently available for your insurer’s policy(s).</Typography>
						}
						{(verified && !atleastOneIntegPoliciesHave && !anyIntegPolicies && !isEmail) && policiesForMobileNumber?.length !== 0 &&
							<Typography className='f-12' color={theme.palette.grey[400]}>This service is not currently available for your insurer’s policy(s).</Typography>
						}
					</Grid>
				</Grid>
				<OtpEntryModal
					open={showOtpModal}
					setOpen={setShowOtpModal}
					loading={isVerifyMobileLoading}
					isEmail={false}
					value={mobile}
					duration={verifyMobileDuration}
					onFinishDuration={() => setVerifyMobileDuration(0)}
					onSubmit={handleMobileOtpVerify}
					handleCancel={closeAllModals}
					handleResendOtp={handleResendOtp}
				/>
				<OtpEntryModal
					open={emailshowOtpModal}
					setOpen={setEmailShowOtpModal}
					loading={isVerifyEmailLoading}
					isEmail
					value={emailId}
					onSubmit={handleEmailOtpVerify}
					handleCancel={closeAllModals}
					onFinishDuration={() => setVerifyEmailDuration(0)}
					duration={verifyEmailDuration}
					handleResendOtp={handleEmailResendotp}
				/>
			</Grid>
		</Grid >
	);
};

export default MobilePolicyHeader;
