/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import SvgConverter from '../../../../common/SvgConverter';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import './UpdateMobile.scss';
import { ReactComponent as OrangeRuppee } from '../../../../common/icons/profile/orange_ruppee_icon.svg';

import Separator from '../../../../common/ui/Separator';
import { convertAmount } from '../../../../common/utils';
import {
	MODULE_TYPE_LABEL,
	MODULE_TYPE_VALUE_FOR_ASSIGN_SCREEN,
} from '../../../../common/Enums';
import Currency from '../Currency';

interface UserProps {
	policyDetails?: any;
	handleCheck?: any;
	isChecked?: any;
	type?: any;
}
const AssignPolicyCard: React.FC<UserProps> = ({
	policyDetails,
	handleCheck,
	isChecked,
	type = 'address',
}) => {
	const { t } = useTranslation();
	return (
		<Grid item className=" mb-15 ml-10 assign-policy">
			<Grid container justifyContent="space-between" className="assign-policy">
				<Grid item xs={8}>
					<Grid container>
						<Grid item xs={12}>
							<FormCheckBox
								label={
									<Typography
										className="f-16 fw-600"
										color={theme.palette.common.black}
									>
										{policyDetails?.planName}
									</Typography>
								}
								checked={isChecked}
								onChange={handleCheck}
								size={16}
								checkBoxPadding={1}
							/>
						</Grid>
						<Grid item>
							<Typography
								className="f-14 ml-14"
								color={theme.palette.common.black}
							>
								{t('LIFE_ASSURED')}
							</Typography>
							<Typography
								className="f-16 ml-14 fw-600"
								color={theme.palette.common.black}
							>
								{policyDetails?.customerName}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<img
						src={policyDetails?.companyLogo}
						className="mt-8"
						alt="Company Logo"
						width={60}
						height={60}
						style={{ objectFit: 'contain', border: '1px solid #d7d7d7' }}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent="space-between"
				className="assign-policy ml-14"
			>
				<Grid item sm={6}>
					<Grid item>
						<Typography className="f-14" color={theme.palette.common.black}>
							{t('SUM_ASSURED')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid item container alignItems="flex-end" spacing={0.5}>
							<Currency amount={policyDetails?.sumAssured} policyDetails={policyDetails} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={6}>
					<Grid item>
						<Typography className="f-14" color={theme.palette.common.black}>
							{t('PREMIUM')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid item container alignItems="flex-end" spacing={0.5}>
							<Currency amount={policyDetails?.premiumAmount} policyDetails={policyDetails} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item className="mt-10 mb-5 ml-10">
				<Separator color={theme.palette.grey[400]} className="W-50" />
			</Grid>
			<Grid item className="ml-12">
				<Typography className="f-14" color={theme.palette.common.black}>
					{t('CURRENT')} {t(`${MODULE_TYPE_LABEL[type]}`)}
				</Typography>
				{(policyDetails[MODULE_TYPE_VALUE_FOR_ASSIGN_SCREEN[type]] !== 'null' && policyDetails[MODULE_TYPE_VALUE_FOR_ASSIGN_SCREEN[type]] !== "") ? <Typography className="f-14 fw-600" color={theme.palette.common.black}>
					{policyDetails[MODULE_TYPE_VALUE_FOR_ASSIGN_SCREEN[type]]}
				</Typography> :
					<Typography className="f-14 fw-600" color={theme.palette.error.main}>
						{'Unassigned'}
					</Typography>}
			</Grid>
		</Grid>
	);
};
export default AssignPolicyCard;
