import { axiosInstance } from '../../../common/axiosInstance';
import { ProfileRoutes } from '../../../common/routes';

const axios = axiosInstance;
const fetchUserProfileData = (data: any) =>
	axios.request({ ...ProfileRoutes.userProfile, data });

const fetchUserFamilyList = (data: any) =>
	axios.request({ ...ProfileRoutes.familyList, data });

const addFamilyMemberRequest = (data: any) =>
	axios.request({ ...ProfileRoutes.addFamilyMember, data });

const fetchFamilyRelationShipRequest = () =>
	axios.request({ ...ProfileRoutes.familyRelationShip });

const otpGenerateForMobileEmail = (data: any) =>
	axios.request({ ...ProfileRoutes.generateOtpForMobileEmail, data });

const otpVerifyForMobileEmail = (data: any) =>
	axios.request({ ...ProfileRoutes.verifyOtpForMobileEmail, data });

const SaveFamilyVerification = (data: any) =>
	axios.request({ ...ProfileRoutes.saveFamily, data });

const ProfileUpdateArDetails = (data: any) =>
	axios.request({ ...ProfileRoutes.updateAr, data });
const pinCodeDetails = (data: any) =>
	axios.request({ ...ProfileRoutes.pincode, data });
const addAddressDetails = (data: any) =>
	axios.request({ ...ProfileRoutes.addAddress, data });
const fetchSelfDeclarationDetails = (data: any) =>
	axios.request({ ...ProfileRoutes.selfDeclarationDetails, data });

export {
	fetchUserProfileData,
	fetchUserFamilyList,
	fetchFamilyRelationShipRequest,
	addFamilyMemberRequest,
	otpGenerateForMobileEmail,
	otpVerifyForMobileEmail,
	SaveFamilyVerification,
	ProfileUpdateArDetails,
	pinCodeDetails,
	addAddressDetails,
	fetchSelfDeclarationDetails
};
