import { createSlice } from '@reduxjs/toolkit';
import {
	AddPolicy,
	Insurer,
	PolicyCard,
	ViewPolicy,
} from './types/Policy.model';
import { LoadingState } from '../../../common';
import { RootState } from '../../../common/store';

interface PolicyState {
	active: PolicyCard[];
	archive: PolicyCard[];
	isLoading: LoadingState;
	selectedPolicy?: PolicyCard;
	policyDetails?: ViewPolicy;
	error: any;

	documentDownloads: {
		status: LoadingState;
		data: any;
		error: any;
	};
	premiumRecieptsDownloads: {
		status: LoadingState;
		data: any;
		error: any;
	};
	insurer: {
		status: LoadingState;
		data: Insurer[];
		error?: any;
	};
	addPolicy: {
		status: LoadingState;
		data: AddPolicy[];
		error?: any;
	};
	viewPolicy: {
		status: LoadingState;
		data: any;
		error?: any;
	};
	GetInsurerPolicies: {
		status: LoadingState;
		data: any;
		error?: any;
	};
}

const initialState: PolicyState = {
	isLoading: 'idle',
	active: [],
	archive: [],
	selectedPolicy: undefined,
	error: null,
	documentDownloads: {
		status: 'idle',
		data: {},
		error: null,
	},
	premiumRecieptsDownloads: {
		status: 'idle',
		data: {},
		error: null,
	},
	insurer: {
		status: 'idle',
		data: [],
		error: null,
	},
	addPolicy: {
		status: 'idle',
		data: [],
		error: null,
	},
	viewPolicy: {
		status: 'idle',
		data: {},
		error: null,
	},
	GetInsurerPolicies: {
		status: 'idle',
		data: {},
		error: null,
	},
};

const policySlice = createSlice({
	name: 'policy',
	initialState,
	reducers: {
		fetchActivePolicy: (state) => ({
			...state,
			isLoading: 'loading',
			error: null,
		}),
		loadActivePolicy: (state, action) => ({
			...state,
			active: action.payload.Policies,
			isLoading: 'done',
			error: null,
		}),
		errorLoadingActivePolicy: (state, action) => ({
			...state,
			error: action.payload,
			isLoading: 'done',
		}),
		fetchArchivePolicy: (state) => ({
			...state,
			isLoading: 'loading',
			error: null,
		}),
		loadArchivePolicy: (state, action) => ({
			...state,
			archive: action.payload.Policies,
			isLoading: 'done',
			error: null,
		}),
		errorLoadingArchivePolicy: (state, action) => ({
			...state,
			isLoading: 'done',
			error: action.payload,
		}),

		fetchPolicyDocumentDownload: (state) => {
			state.documentDownloads.status = 'loading';
			state.documentDownloads.data = {};
			state.documentDownloads.error = undefined;
		},
		loadPolicyDocumentDownload: (state, action) => {
			state.documentDownloads.status = 'done';
			state.documentDownloads.data = action.payload;
			state.documentDownloads.error = undefined;
		},
		errorLoadingPolicyDocumentDownload: (state, action) => {
			state.documentDownloads.status = 'error';
			state.documentDownloads.data = {};
			state.documentDownloads.error = action.payload;
		},

		fetchpremiumRecieptsDownloads: (state) => {
			state.premiumRecieptsDownloads.status = 'loading';
			state.premiumRecieptsDownloads.data = {};
			state.premiumRecieptsDownloads.error = undefined;
		},
		loadPremiumRecieptsDownloads: (state, action) => {
			state.premiumRecieptsDownloads.status = 'done';
			state.premiumRecieptsDownloads.data = action.payload;
			state.premiumRecieptsDownloads.error = undefined;
		},
		errorLoadingPremiumRecieptsDownloads: (state, action) => {
			state.premiumRecieptsDownloads.status = 'error';
			state.premiumRecieptsDownloads.data = {};
			state.premiumRecieptsDownloads.error = action.payload;
		},

		fetchInsurers: (state) => {
			state.insurer.status = 'loading';
			state.insurer.data = [];
			state.insurer.error = null;
		},
		loadInsurers: (state, action) => {
			state.insurer.status = 'done';
			state.insurer.data = action.payload;
			state.insurer.error = null;
		},
		errorLoadInsurer: (state, action) => {
			state.insurer.status = 'done';
			state.insurer.error = action.payload;
			state.insurer.data = [];
		},
		addNewPolicy: (state) => {
			state.addPolicy.status = 'loading';
			state.addPolicy.data = [];
			state.addPolicy.error = null;
		},
		addNewPolicySuccess: (state, action) => {
			state.addPolicy.status = 'done';
			state.addPolicy.data = action.payload;
			state.addPolicy.error = null;
		},
		addNewPolicyError: (state, action) => {
			state.addPolicy.status = 'done';
			state.addPolicy.error = action.payload;
			state.addPolicy.data = [];
		},
		fetchViewPolicyDetails: (state) => {
			state.viewPolicy.status = 'loading';
			state.viewPolicy.data = {};
			state.viewPolicy.error = undefined;
		},
		loadViewPolicyDetails: (state, action) => {
			state.viewPolicy.status = 'done';
			state.viewPolicy.data = action.payload;
			state.viewPolicy.error = undefined;
		},
		errorViewPolicyDetails: (state, action) => {
			state.viewPolicy.status = 'error';
			state.viewPolicy.data = {};
			state.viewPolicy.error = action.payload;
		},
		fetchGetInsurerPolicies: (state) => {
			state.GetInsurerPolicies.status = 'loading';
			state.GetInsurerPolicies.data = {};
			state.GetInsurerPolicies.error = undefined;
		},
		loadGetInsurerPolicies: (state, action) => {
			state.GetInsurerPolicies.status = 'done';
			state.GetInsurerPolicies.data = action.payload;
			state.GetInsurerPolicies.error = undefined;
		},
		errorGetInsurerPolicies: (state, action) => {
			state.GetInsurerPolicies.status = 'error';
			state.GetInsurerPolicies.data = {};
			state.GetInsurerPolicies.error = action.payload;
		},
	},
});

export const {
	fetchActivePolicy,
	loadActivePolicy,
	errorLoadingActivePolicy,
	fetchArchivePolicy,
	loadArchivePolicy,
	errorLoadingArchivePolicy,
	fetchPolicyDocumentDownload,
	loadPolicyDocumentDownload,
	errorLoadingPolicyDocumentDownload,
	loadInsurers,
	errorLoadInsurer,
	fetchInsurers,
	addNewPolicy,
	addNewPolicySuccess,
	addNewPolicyError,
	fetchViewPolicyDetails,
	loadViewPolicyDetails,
	errorViewPolicyDetails,
	fetchpremiumRecieptsDownloads,
	loadPremiumRecieptsDownloads,
	errorLoadingPremiumRecieptsDownloads,
	loadGetInsurerPolicies,
	fetchGetInsurerPolicies,
	errorGetInsurerPolicies
} = policySlice.actions;

export default policySlice.reducer;

export const selectIsPolicyLoading = (state: RootState) =>
	state.policy.isLoading === 'loading';

export const selectActivePolicies = (state: RootState) => state.policy.active;
export const selectArchivePolicies = (state: RootState) => state.policy.archive;
export const selectPolicyError = (state: RootState) => state.policy.error;

export const selectIsInsurerLoading = (state: RootState) =>
	state.policy.insurer.status === 'loading';

export const selectInsurers = (state: RootState) => state.policy.insurer.data;

export const selectInsurerOptions = (state: RootState) =>
	state.policy?.insurer?.data
		? state.policy?.insurer?.data?.map(
			({ Code, Description, Category, alternateCode, insurerLogo }) => ({
				label: `${Description} - ${Category}`,
				value: Code,
				insurerLogo,
				alternateCode,
			})
		)
		: [];
export const selectInsurerErrors = (state: RootState) =>
	state.policy.insurer.error;

export const addPolicyList = (state: any) => state.policy.addPolicy?.data;

export const addPolicyLoading = (state: RootState) =>
	state.policy.addPolicy.status === 'loading';

export const viewPolicyList = (state: RootState) =>
	state.policy.viewPolicy.data;

export const viewPolicyLoading = (state: RootState) =>
	state.policy.viewPolicy.status === 'loading';

export const addPolicySuccess = (state: RootState) =>
	state.policy.addPolicy.status === 'done';
