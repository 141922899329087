import { reject } from 'ramda';
import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
	fetchAddressList,
	fetchChangePermanentAddress,
	fetchPoliciesForAddress,
	finalSubmitAddress,
} from './Address.service';
import {
	errorLoadingPoliciesForAddress,
	loadPoliciesForAddress,
	fetchPoliciesForAddress as fetchPoliciesForAddressAction,
	fetchAddress as fetchAddressAction,
	loadAddress,
	errorLoadingAddress,
	selectAddressChangesList,
	updateAddressChangesRequest,
	updateAddressChangesSuccess,
	updateAddressChangesError,
	getAddressEkycUrlRequest,
	getAddressEkycUrlSuccess,
	getAddressEkycUrlError,
	saveAddressEkycRequest,
	saveAddressEkycSuccess,
	saveAddressEkycError,
	changePermanentAddressRequest,
	changePermanentAddressSuccess,
	changePermanentAddressError,
	resetPermanentAddress,
} from './Address.slice';

import {
	generatePermanentAddressFromEkyc,
	verifyNullForArray,
	verifyNullForObject,
} from '../../../common/utils';
import { setAlertInfo } from '../Common/Common.slice';
import { EKYC_PROCESS_FLOW_TYPE, KYC_TYPES } from '../../../common/Enums';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import { initiateEkycRequest, saveEkycRequest } from '../../Auth/auth.service';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { fetchUserProfile } from '../Profile/Profile.slice';

function* handleFetchPoliciesForAddress() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
		};
		const { data }: any = yield* call(fetchPoliciesForAddress, requestPayload);
		// const checkFullAddress = (data: any) => {
		// 	let validArray = verifyNullForArray(data)
		// 	validArray.forEach((element: any) => {
		// 		if (element.fullAddress == '') {
		// 			element.fullAddress = element.pincode;
		// 		}
		// 	});
		// 	return validArray;
		// }
		// const checkEmptyAddress = (data: any) => {
		// 	let validArray = verifyNullForArray(data)
		// 	let result = validArray.filter((d) => d.pincode == '');
		// 	return result;
		// }
		yield* put(
			loadPoliciesForAddress({
				permanentAddress: data?.PermanentAddresses
					? verifyNullForObject(data?.PermanentAddresses)
					: {},
				addressBasedPolicies: data?.AddressBasedPolicies,
				unassignedAddresses: data?.UnassignedAddresses
					? verifyNullForArray(data?.UnassignedAddresses)
					: [],
				manualAddressVerificationStatus: data?.manualAddressVerificationStatus,
				EkycAddress: data?.EkycAddress
			})
		);
	} catch (e: any) {
		yield* put(errorLoadingPoliciesForAddress(e.message));
	}
}

function* handleFetchAddress({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				address1: payload?.address1,
				address2: payload?.address2,
				address3: payload?.address3,
				city: payload?.city,
				state: payload?.state,
				country: payload?.country,
				pincode: payload?.pincode,
			},
		};
		const { data }: any = yield* call(fetchAddressList, requestPayload);
		yield* put(loadAddress(data?.listAddress || []));
	} catch (e: any) {
		yield* put(errorLoadingAddress(e.message));
	}
}

function* finalDataSubmitAddress({ payload: { handleDone, t } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const addressChanges: any[] = yield* select(selectAddressChangesList);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			Actions: addressChanges,
		};
		const { data }: any = yield* call(finalSubmitAddress, requestPayload);
		yield* put(updateAddressChangesSuccess(data || []));
		yield put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: data?.displayMessage,
			})
		);
		yield* put(fetchUserProfile());
		if (handleDone) {
			handleDone();
		}
	} catch (e: any) {
		yield* put(updateAddressChangesError(e.message));
	}
}

function* initiateEkycWatcher({ payload: { handleSuccess } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const { data }: any = yield* call(initiateEkycRequest, {
			Customer: {
				eiaNo: user.eiaNo,
				flag: KYC_TYPES.ADDRESS_CHANGE,
				// return_url: `${window.location.origin}${APP_ROUTES.ADDRESS_CHANGE_RETURN_URL}?ekyId=977a05fc-22a5-48dc-b25f-3814d6d54fae`,
				return_url: `${window.location.origin}${APP_ROUTES.ADDRESS_CHANGE_RETURN_URL}`,
				goback_url: `${window.location.origin}${APP_ROUTES.MANAGE_ADDRESS}`,
			},
		});
		yield* put(getAddressEkycUrlSuccess(data));
		if (handleSuccess) {
			handleSuccess(data);
		}
	} catch (e: any) {
		yield* put(getAddressEkycUrlError(e.message));
	}
}

function* saveEkycWatcher({ payload: { ekycId, handleDone, customerName } }: any) {
	try {
		// const kycData: any = yield* select(selectGetAddressEkycUrl);
		const user: any = yield* select(selectIsUserLoggedIn);
		if (ekycId) {
			const { data }: any = yield* call(saveEkycRequest, {
				kycId: ekycId,
				eiaNo: user.eiaNo,
				flag: KYC_TYPES.ADDRESS_CHANGE,
				gender: '',
				pan: '',
				customerName: customerName,
				return_url: `${window.location.origin}${APP_ROUTES.ADDRESS_CHANGE_RETURN_URL}`,
				goback_url: `${window.location.origin}${APP_ROUTES.MANAGE_ADDRESS}`,
			});

			if (handleDone) {
				handleDone()
			}

			yield* put(saveAddressEkycSuccess(data));
			yield* put(resetPermanentAddress());

			yield put(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: data.displayMessage,
				})
			);

			// const data = {
			// 	txnId: '1b1bb356-412c-425c-b84f-ebd8078c66bd',
			// 	timestamp: '2023-08-10T15:03:43.522397253',
			// 	errorCode: '0',
			// 	displayMessage: 'Address Updation queued to Manual Process.',
			// 	errorDescription: '',
			// 	kycId: 'e7ead5de-7a79-4a5a-ab2b-cc7580076a1a',
			// 	ID: 'e7ead5de-7a79-4a5a-ab2b-cc7580076a1a',
			// 	flag: 'MANUAL',
			// };

			// if (
			// 	data.flag === EKYC_PROCESS_FLOW_TYPE.AUTO ||
			// 	data.flag === EKYC_PROCESS_FLOW_TYPE.MANUAL
			// ) {
			// 	const permanentAddress = generatePermanentAddressFromEkyc(data);
			// 	yield* put(
			// 		saveAddressEkycSuccess({
			// 			permanentAddress,
			// 			flag: data.flag,
			// 			message: data.displayMessage,
			// 		})
			// 	);
			// 	if (data.flag === EKYC_PROCESS_FLOW_TYPE.AUTO) {
			// 		yield put(
			// 			setAlertInfo({
			// 				open: true,
			// 				type: 'SUCCESS',
			// 				description: data.displayMessage,
			// 			})
			// 		);
			// 	}

			// 	navigate(APP_ROUTES.MANAGE_ADDRESS, { state: { ekycId } });
			// } else {
			// 	// eslint-disable-next-line no-throw-literal
			// 	throw { isCustom: true, message: data?.errorDescription };
			// }
		}
	} catch (e: any) {
		yield* put(saveAddressEkycError(e.message));
		if (e.isCustom) {
			yield put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: e.message,
				})
			);
		}
	}
}

function* changePermanentAddressWatcher({ payload: { ekycId, navigate, customerName } }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		if (ekycId) {
			const { data }: any = yield* call(fetchChangePermanentAddress, {
				kycId: ekycId,
				eiaNo: user.eiaNo,
				flag: KYC_TYPES.ADDRESS_CHANGE,
				customerName: customerName
			});
			if (
				data?.EkycAddress?.pincode
			) {
				yield* put(
					changePermanentAddressSuccess(data)
				);

				navigate(APP_ROUTES.MANAGE_ADDRESS, { state: { ekycId } });
			} else {
				// eslint-disable-next-line no-throw-literal
				throw { isCustom: true, message: data?.displayMessage, data };
			}
		}
	} catch (e: any) {
		console.log(e)
		yield* put(changePermanentAddressError(e.message));
		if (e.isCustom) {
			yield put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: e.message,
					errorCode: e?.data?.kycReinitiateFlag === "Y" ? "2222" : ""
				} as any)
			);
		}

		navigate(APP_ROUTES.MANAGE_ADDRESS, { state: { ekycId } });
	}
}

function* watchAddress() {
	yield* takeEvery(
		fetchPoliciesForAddressAction.type,
		handleFetchPoliciesForAddress
	);
	yield* takeEvery(fetchAddressAction.type, handleFetchAddress);
	yield* takeEvery(updateAddressChangesRequest.type, finalDataSubmitAddress);
	yield* takeEvery(getAddressEkycUrlRequest.type, initiateEkycWatcher);
	yield* takeEvery(saveAddressEkycRequest.type, saveEkycWatcher);
	yield* takeEvery(changePermanentAddressRequest.type, changePermanentAddressWatcher);
}

// eslint-disable-next-line import/prefer-default-export
export { watchAddress };
