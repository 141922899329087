import React, { useState, useMemo, useEffect } from 'react';
import { Grid } from '@mui/material';
import './Mobile.scss';
import { filter, findIndex, groupBy, prop, propEq, reject } from 'ramda';
import { useSelector } from 'react-redux';
import { selectPoliciesForMobileNumbers } from './MobileNumber.slice';
import { PolicyAlternateCodes } from '../../../common/types/PolicyTypes';
import MobilePolicyHeader from './common/MobilePolicyHeader';
import PolicyCard from '../Common/PolicyCard';
import UnassignedMobileNumber from './UnassignedMobileNumber';
import MobileUnassignedPolicies from './MobileUnassignedPolicies';
import { MobilePolicyLock } from '../../../common/utils';

const MobileNumberHeader = ({
	toggleChangeNumberModal,
	toggleRemoveNumberModal,
	handleAssignPolicy,
	handleRemoveForUnAssignedMobiles,
	handleInstantVerify,
	togglePrimaryChangeNumberModal,
}: any) => {
	const [count, setCount] = useState(0);
	const groupPoliciesByNumber = groupBy<any>(prop<any>('mobileNo'));
	const policiesForMobileNumber = useSelector(selectPoliciesForMobileNumbers);
	const { mobileNumbersListPolicies, unAssignedMobileNumbers } = useMemo(
		() => ({
			mobileNumbersListPolicies: policiesForMobileNumber?.mobileBasedPolicies,
			unAssignedMobileNumbers: policiesForMobileNumber?.unAssignedMobiles,
		}),
		[policiesForMobileNumber]
	);
	const policies: any = useMemo(
		() => groupPoliciesByNumber(mobileNumbersListPolicies || []),
		[mobileNumbersListPolicies, groupPoliciesByNumber]
	);

	const { assignedPoliciesObj, unAssignedPolicies } = useMemo(() => {
		if (policies) {
			const assignedPoliciesObjLocal = { ...policies };
			const unAssignedPoliciesLocal = assignedPoliciesObjLocal.null
				? assignedPoliciesObjLocal.null
				: [];
			delete assignedPoliciesObjLocal.null;
			return {
				assignedPoliciesObj: assignedPoliciesObjLocal,
				unAssignedPolicies: unAssignedPoliciesLocal,
			};
		}
		return { assignedPoliciesObj: {}, unAssignedPolicies: [] };
	}, [policies]);

	const lifePolicies = filter(
		propEq(PolicyAlternateCodes.LIFE, 'alternateCode')
	);
	const healthPolicies: any = filter(
		propEq(PolicyAlternateCodes.HEALTH, 'alternateCode')
	);
	const assetPolicies: any = filter(
		propEq(PolicyAlternateCodes.ASSET, 'alternateCode')
	);

	useEffect(() => {
		setCount(count + 1);
	}, []);

	const unAssignedMobileNumbersLocal = useMemo(() => {
		if (unAssignedMobileNumbers && mobileNumbersListPolicies) {
			return reject(
				({ mobileNo }: any) =>
					findIndex(propEq(mobileNo, 'mobileNo'))(mobileNumbersListPolicies) >=
					0,
				unAssignedMobileNumbers
			);
		}
		return [];
	}, [unAssignedMobileNumbers, mobileNumbersListPolicies]);

	return (
		<>
			<Grid container spacing={5} className="mob-list">
				{Object.keys(assignedPoliciesObj).map((mobileNo, index) => (
					<Grid item key={mobileNo} xs={12} sm={6} lg={6} md={6} xl={3}>
						<MobilePolicyHeader
							index={index}
							mobileNo={mobileNo}
							verified={assignedPoliciesObj[mobileNo].some((item: any) => item.mobileNoVerified === 'Y')}
							primary={assignedPoliciesObj[mobileNo][0].primaryFlag}
							onRemove={() =>
								toggleRemoveNumberModal(
									mobileNo,
									assignedPoliciesObj[mobileNo][0].primaryFlag
								)
							}
							handleAssignPolicy={() =>
								handleAssignPolicy({
									mobileNo,
									mobileNoVerified:
										assignedPoliciesObj[mobileNo][0].mobileNoVerified,
									primaryFlag: assignedPoliciesObj?.[mobileNo]?.[0].primaryFlag,
								})
							}
							onChangePrimary={() => {
								togglePrimaryChangeNumberModal({ mobileNo });
							}}
							showAssignPolicyButton={false}
							onVerify={() => handleInstantVerify(mobileNo)}
							showAssignPolicyRegularList
							isEmail={false}
							policy={assignedPoliciesObj[mobileNo][0]}
						/>
						<Grid
							item
							className="mobile-Policy-screen policy-list-container p-20"
						>
							{lifePolicies(assignedPoliciesObj[mobileNo]).map(
								(policy: any) => (
									<PolicyCard
										policyDetails={policy}
										key={policy?.policyNo}
										changeButtonText="CHANGE_NUMBER"
										onChangeClick={() => toggleChangeNumberModal(policy)}
										disabled={MobilePolicyLock(policy)}
									/>
								)
							)}

							{healthPolicies(assignedPoliciesObj[mobileNo]).map(
								(policy: any) => (
									<PolicyCard
										policyDetails={policy}
										key={policy?.policyNo}
										changeButtonText="CHANGE_NUMBER"
										onChangeClick={() => toggleChangeNumberModal(policy)}
										disabled={MobilePolicyLock(policy)}
									/>
								)
							)}
							{assetPolicies(assignedPoliciesObj[mobileNo]).map(
								(policy: any) => (
									<PolicyCard
										policyDetails={policy}
										key={policy?.policyNo}
										changeButtonText="CHANGE_NUMBER"
										onChangeClick={() => toggleChangeNumberModal(policy)}
										disabled={MobilePolicyLock(policy)}
									/>
								)
							)}
						</Grid>
					</Grid>
				))}
			</Grid>
			{unAssignedMobileNumbersLocal.length > 0 ? (
				<UnassignedMobileNumber
					mobileNoList={unAssignedMobileNumbersLocal}
					handleAssignPolicy={handleAssignPolicy}
					onRemove={handleRemoveForUnAssignedMobiles}
					togglePrimaryChangeNumberModal={togglePrimaryChangeNumberModal}
				/>
			) : (
				<></>
			)}

			{unAssignedPolicies.length > 0 ? (
				<MobileUnassignedPolicies
					policies={unAssignedPolicies}
					toggleChangeNumberModal={toggleChangeNumberModal}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default MobileNumberHeader;
