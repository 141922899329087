import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Separator from "../../../common/ui/Separator";
import theme from "../../../common/theme";
import { LineChart } from "../../../common/ui/Charts/LineChart";
import { selectSelfDeclarationDetails } from "./Profile.slice";
import { useSelector } from "react-redux";
import { POLICY_TYPE_LABEL } from "../../../common/Enums";
import { useMemo } from "react";

const DeclarationRecord = () => {
    const { t } = useTranslation();

    const getYear = (date: any) => date !== "NA" ? new Date(date).getFullYear() : "-"

    const policy = useSelector(selectSelfDeclarationDetails)?.polices || [];
    const occupation = useSelector(selectSelfDeclarationDetails)?.occupation || [];
    const education = useSelector(selectSelfDeclarationDetails)?.education || [];
    const maritalStatus = useSelector(selectSelfDeclarationDetails)?.maritalStatus || [];
    const annualIncome = useSelector(selectSelfDeclarationDetails)?.annualIncome || [];

    const minYear = getYear(useSelector(selectSelfDeclarationDetails)?.minDate ? useSelector(selectSelfDeclarationDetails)?.minDate : 'null');
    const maxYear = getYear(useSelector(selectSelfDeclarationDetails)?.maxDate ? useSelector(selectSelfDeclarationDetails)?.maxDate : 'null');


    const years = useMemo(() => {
        const temp = []
        if (annualIncome.length) {
            const result = [...annualIncome].sort((a, b) => a.year.localeCompare(b.year));
            // console.log(result);

            for (let i = 0; i < result.length; i++) {
                let y
                if (result[i].year !== 'NA') {
                    if (i === 0) {
                        const d = new Date(result[i].year);
                        y = d.getFullYear();
                    } else {
                        const d = new Date(result[i].year);
                        y = d.getFullYear();
                        // y = dy.toString().substr(-2);
                    }
                    temp.push(y)
                    // console.log(temp);
                }
            }
        }
        return temp
    }, [annualIncome])

    const income = useMemo(() => {
        let temp = []
        if (annualIncome.length && years.length !== 0) {
            const result = [...annualIncome].sort((a, b) => a.year.localeCompare(b.year));
            // console.log(result)
            for (let i = 0; i < result.length; i++) {
                const currentAmount = result?.find((item: any) => getYear(item?.year) == years[i])
                temp.push(currentAmount ? (currentAmount?.amount / 100000) : "")
            }
        }
        // console.log(temp)
        return temp
    }, [years])


    return <Grid container >
        <Grid item container alignItems={'initial'}>
            <Typography className="f-18 fw-600" color="primary">
                {t('Declaration Record')}
            </Typography>

            {!Number.isNaN(minYear) && <> <Grid item className="separator">
                <Separator
                    orientation="vertical"
                    color={theme.palette.grey[600]}
                    borderWidth={1.5}
                    className="h-45 pr-10"
                />

            </Grid>
                <Typography className="f-18 fw-600 ml-10" color="primary">
                    {minYear === maxYear ? minYear : `${minYear}  - ${maxYear}`}
                </Typography>
            </>
            }
        </Grid>

        {policy?.length || occupation.length || education.length || maritalStatus.length || annualIncome.length ? <>

            <Typography className="f-14 fw-600 pb-10 mt-15" color="primary">
                {t('Smoking')}
            </Typography>

            {policy.length ? <>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Year')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} style={{ wordWrap: 'break-word' }}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Policy #')}
                        </Typography>

                    </Grid>
                    <Grid item xs={2}>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Y/N')}
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container>
                    {policy?.map((item: any) => (
                        <>
                            <Grid item xs={3}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {getYear(item?.year)}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} style={{ wordWrap: 'break-word', paddingRight: "10px" }}>
                                <Typography className="f-14 fw-400 py-5" color="primary" >
                                    {item?.policyNo}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{ wordWrap: 'break-word' }}>
                                <Typography className="f-14 fw-400 py-5"  >
                                    ({POLICY_TYPE_LABEL[item?.alternateCode]})
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {item?.Smoking}
                                </Typography>
                            </Grid>
                        </>

                    ))}

                </Grid>
            </> :
                <Grid container> <Typography className="f-14 fw-400 py-5" >
                    {"No Smoking Declarations found"}
                </Typography> </Grid>}
            <Typography className="f-14 fw-600 pb-10 mt-15" color="primary">
                {t('Tobacco ')}
            </Typography>

            {policy.length ? <>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Year')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} style={{ wordWrap: 'break-word' }}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Policy #')}
                        </Typography>

                    </Grid>
                    <Grid item xs={2}>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Y/N')}
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container>
                    {policy?.map((item: any) => (
                        <>
                            <Grid item xs={3}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {getYear(item?.year)}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} style={{ wordWrap: 'break-word', paddingRight: "10px" }}>
                                <Typography className="f-14 fw-400 py-5" color="primary" >
                                    {item?.policyNo}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{ wordWrap: 'break-word' }}>
                                <Typography className="f-14 fw-400 py-5"  >
                                    ({POLICY_TYPE_LABEL[item?.alternateCode]})
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {item?.Tobacco}
                                </Typography>
                            </Grid>
                        </>

                    ))}

                </Grid>
            </> :
                <Grid container> <Typography className="f-14 fw-400 py-5" >
                    {"Tobacco Consumption Declaration not found"}
                </Typography> </Grid>}


            <Typography className="f-14 fw-600 pb-10 mt-15" color="primary">
                {t('Alcohol')}
            </Typography>

            {policy.length ? <>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Year')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} style={{ wordWrap: 'break-word' }}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Policy #')}
                        </Typography>

                    </Grid>
                    <Grid item xs={2}>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Y/N')}
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container>
                    {policy?.map((item: any) => (
                        <>
                            <Grid item xs={3}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {getYear(item?.year)}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} style={{ wordWrap: 'break-word', paddingRight: "10px" }}>
                                <Typography className="f-14 fw-400 py-5" color="primary" >
                                    {item?.policyNo}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{ wordWrap: 'break-word' }}>
                                <Typography className="f-14 fw-400 py-5"  >
                                    ({POLICY_TYPE_LABEL[item?.alternateCode]})
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {item?.Alcohol}
                                </Typography>
                            </Grid>
                        </>

                    ))}

                </Grid>
            </> :
                <Grid container> <Typography className="f-14 fw-400 py-5" >
                    {"Alcohol consumption declaration not found"}
                </Typography> </Grid>}


            <Typography className="f-14 fw-600 pb-10 mt-40" color="primary">
                {t('Occupation Declaratons')}
            </Typography>


            {occupation.length ? occupation.map((item: any) => (
                <Grid container>
                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 py-5" >
                            {getYear(item?.year)}
                        </Typography>


                    </Grid>
                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 py-5" >
                            {item?.status}
                        </Typography>

                    </Grid>
                </Grid>
            )) : <Grid container> <Typography className="f-14 fw-400 py-5" >
                {"Occupation not found"}
            </Typography> </Grid>}
            <Typography className="f-14 fw-600 pb-10 mt-40" color="primary">
                {t('Education Declarations')}
            </Typography>

            {education?.length ? <> {education.map((item: any) => (
                <Grid container>
                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 py-5" >
                            {getYear(item?.year)}
                        </Typography>


                    </Grid>
                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 py-5" >
                            {item?.degree}
                        </Typography>

                    </Grid>
                </Grid>
            ))}</> : <Grid container> <Typography className="f-14 fw-400 py-5" >
                {"Education not found"}
            </Typography> </Grid>}


            <Typography className="f-14 fw-600 pb-10 mt-40" color="primary">
                {t('Marital Status Declarations')}
            </Typography>

            {maritalStatus.length ? maritalStatus.map((item: any) => (
                <Grid container>
                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 py-5" >
                            {getYear(item?.year)}
                        </Typography>


                    </Grid>
                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 py-5" >
                            {item?.status}
                        </Typography>

                    </Grid>
                </Grid>
            )) : <Grid container><Typography className="f-14 fw-400 py-5" >
                {"Marital Status not found"}
            </Typography> </Grid>}

            <Typography className="f-14 fw-600 mb-10 mt-40" color="primary">
                {t('Annual Income Declarations')}
            </Typography>


            <Grid container height={annualIncome?.length ? 300 : 40}>
                {annualIncome?.length && years?.length ? <LineChart labels={years} dataSet={income} /> :
                    <Grid container> <Typography className="f-14 fw-400 py-5" >
                        {"Annual Income not found"}
                    </Typography> </Grid>}
            </Grid>


            <Typography className={"f-14 fw-600 mb-10 mt-40"} color="primary">
                {t('Hospitalization Declarations')}
            </Typography>

            {policy.length ? <>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Year')}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} style={{ wordWrap: 'break-word' }}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Policy #')}
                        </Typography>

                    </Grid>
                    <Grid item xs={2}>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography className="f-14 fw-400 pb-10" color="primary">
                            {t('Y/N')}
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container>
                    {policy?.map((item: any) => (
                        <>
                            <Grid item xs={3}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {getYear(item?.year)}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} style={{ wordWrap: 'break-word', paddingRight: "10px" }}>
                                <Typography className="f-14 fw-400 py-5" color="primary" >
                                    {item?.policyNo}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{ wordWrap: 'break-word' }}>
                                <Typography className="f-14 fw-400 py-5"  >
                                    ({POLICY_TYPE_LABEL[item?.alternateCode]})
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography className="f-14 fw-400 py-5" >
                                    {item?.Hospitalization}
                                </Typography>
                            </Grid>
                        </>

                    ))}

                </Grid>

            </> :
                <Grid container>
                    <Typography className="f-14 fw-400 py-5" >
                        {"Hospitalization history not found"}
                    </Typography> </Grid>
            }

        </> :

            <Grid container> <Typography className="f-14 fw-400 py-5" >
                {"No Declarations found"}
            </Typography> </Grid>}

    </Grid>
}


export default DeclarationRecord;

