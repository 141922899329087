import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { ReactComponent as AddMobileIcon } from '../../../../common/icons/update-mobile/Add_Mobile.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as Verify } from '../../../../common/icons/update-mobile/verify_orange.svg';
import {
	changeEmail,
	changePrimaryEmail,
	getEmailList,
	selectPoliciesForEmails,
} from '../Emails.slice';
import { setAlertInfo } from '../../Common/Common.slice';

const ChangeEmailModal = ({
	open,
	setOpen,
	selectedPolicyDetails,
	toggleAddNumberModal,
	showAddButton = true,
	isPrimaryChange = false,
}: any) => {
	const theme = useTheme();
	const [selectedOption, setSelectedOption] = React.useState<any>(false);
	const { emailBasedPolicies, unAssignedEmails } = useSelector(
		selectPoliciesForEmails
	);
	const dispatch = useDispatch();

	const { t } = useTranslation();

	useEffect(() => {
		if (!open) setSelectedOption(false);
	}, [open]);

	const emails: any[] = useMemo(
		() =>
			getEmailList(
				selectedPolicyDetails,
				emailBasedPolicies,
				unAssignedEmails
			).map((val: any) => ({
				value: val,
				label: val,
			})),
		[emailBasedPolicies, unAssignedEmails, selectedPolicyDetails?.email]
	);
	const handleSubmit = () => {
		if (selectedOption) {
			if (!isPrimaryChange) {
				dispatch(
					changeEmail({
						selectedPolicy: selectedPolicyDetails,
						targetEmail: selectedOption,
					})
				);
			} else {
				dispatch(
					changePrimaryEmail({
						selectedEmail: selectedPolicyDetails?.email,
						targetedEmail: selectedOption,
					})
				);
			}
		} else {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('PLEASE_SELECT_EMAIL'),
				})
			);
		}
		setOpen(false);
	};

	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid item>
				<Grid item>
					{emails.length !== 0 && (
						<>
							<Typography
								className="f-16 fw-600"
								color={theme.palette.primary.main}
							>
								{isPrimaryChange
									? t('SELECT_THE_EMAILID_AS_PRIMARY')
									: t('SELECT_EMAIL_TO_ASSIGN')}
							</Typography>

							<Grid container>
								<Grid item xs={12} className="my-25">
									<CustomRadioGroup
										name="RadioGroup"
										options={emails}
										value={selectedOption}
										onChange={handleOptionChange}
										size="small"
									/>
								</Grid>
								{showAddButton ? (
									<Grid item xs={12} className="py-2">
										<Typography
											className="f-16 fw-600"
											color={theme.palette.primary.main}
										>
											{t('OR')}
										</Typography>
									</Grid>
								) : (
									<></>
								)}
							</Grid>
						</>
					)}
					{showAddButton ? (
						<Grid item className="py-10">
							<CustomButton
								text={t('ADD_A_EMAIL')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={() =>
									toggleAddNumberModal(true, true, isPrimaryChange)
								}
								fontSize={14}
								startIcon={AddMobileIcon}
							/>
						</Grid>
					) : (
						<></>
					)}

					<Grid item className="py-5">
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</Grid>
					<Grid container textAlign="center">
						<Grid item xs={12} className="py-10 ">
							<CustomButton
								text={isPrimaryChange ? t('PROCEED'): t('CHANGE_EMAIL')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={handleSubmit}
								fontSize={16}
								fontWeight={600}
								startIcon={Verify}
								disabled={!emails.length}
							/>
						</Grid>
						<Grid item xs={12} className="py-10">
							<CustomButton
								text={t('CANCEL')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => setOpen(false)}
								fontSize={16}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default ChangeEmailModal;
