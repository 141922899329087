import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as checked } from '../../../../common/icons/profile/checked.svg';
import { ReactComponent as ExeDangerIcon } from '../../../../common/icons/Red-alert-bank.svg';
import AddressSection from '../AddressSection';
import SvgConverter from '../../../../common/SvgConverter';
import CustomButton from '../../../../common/ui/CustomButton';
import { selectmanualAddressVerificationStatus } from '../Address.slice';
import { useSelector } from 'react-redux';

const PermanentAddress = ({
	addressDetails,
	onChange,
	changeLoading = false,
	message = false,
}: any) => {
	// addressDetails = { addressProofDoc: addressDetails?.addressProofDoc }
	const theme = useTheme();
	const { t } = useTranslation();
	const manualAddressVerificationStatus = useSelector(selectmanualAddressVerificationStatus);
	return (
		<Grid container>
			<Grid item className="address">
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography
							color={theme.palette.primary.main}
							fontSize={16}
							fontWeight={600}
						>
							{t('PERMANENT_ADDRESS')}
						</Typography>
						{addressDetails?.city ? (
							<Typography
								// component={'span'}
								color={theme.palette.grey[400]}
								fontSize={12}
								className="mt-n4 my-6"
							>
								{t('ASSIGN_TO_ALL_POLICIES')}
							</Typography>
						) : (
							<></>
						)}
					</Grid>
					<Grid item>
						<CustomButton
							text={t('CHANGE')}
							variant="text"
							color="primary"
							fontSize={14}
							showIcon={false}
							fontWeight={400}
							onClick={onChange}
							loading={changeLoading}
							disabled={manualAddressVerificationStatus === "Y"}
						/>
					</Grid>
				</Grid>
				{message && (
					<Typography
						color={theme.palette.warning.light}
						fontWeight={600}
						fontSize={12}
					>
						{message}
					</Typography>
				)}

				{addressDetails?.city ? (
					<Grid item className="manage-permanent-address p-20 mb-10">
						<AddressSection data={addressDetails} />
						{String(addressDetails.addressVerified).toUpperCase() === 'Y' ? (
							<>
								<Grid
									item
									container
									spacing={0.5}
									flexWrap="nowrap"
									alignItems="center"
								>
									<Grid item>
										<SvgConverter
											Icon={checked}
											width="11px"
											height="8px"
											className="mb-4"
										/>
									</Grid>
									<Grid item>
										<Typography
											className="f-12"
											color={theme.palette.success.main}
										>
											[{t('VERIFIED')}]
										</Typography>
									</Grid>
								</Grid>
								<Grid item>
									<Typography
										className="f-12"
										color={theme.palette.common.black}
									>
										{/* {`[${t('PAN_REGISTERED')}]`}
										<br />
										{`[${t('AADHAR_REGISTERED')}]`} */}
										[{t('VERIFIED_BY')} {addressDetails?.addressProofDoc}]
									</Typography>
								</Grid>
							</>
						) : (
							<>
								<Grid
									item
									container
									spacing={0.5}
									flexWrap="nowrap"
									alignItems="center"
								>
									{/* <Grid item>
										<SvgConverter Icon={ExeDangerIcon} width={10} height={15} />
									</Grid> */}
									<Grid item>
										<Typography
											className="f-12 fw-600"
											color={theme.palette.secondary.main}
										>
											{t('Under Process')}
										</Typography>
									</Grid>
								</Grid>
								{/* <Grid item>
									<Typography
										className="f-12"
										color={theme.palette.common.black}
									>
										[{t('VERIFIED_BY')} <div style={{ display: 'inline-block', textTransform: 'capitalize' }}>{addressDetails?.addressProofDoc}</div>]
									</Typography>
								</Grid> */}
							</>
						)}

						{/* <Typography color={theme.palette.common.black} fontSize={12}>
            [{`[${t('PAN_REGISTERED')}]`}]
          </Typography>
          <Typography color={theme.palette.common.black} fontSize={12}>
            [{`[${t('AADHAR_REGISTERED')}]`}]
          </Typography> */}
					</Grid>
				) : (
					<></>
				)}
			</Grid>
		</Grid>
	);
};

export default PermanentAddress;
