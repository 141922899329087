/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import Separator from '../../../../../../common/ui/Separator';

interface NomineesProps {
	nomineeDetails?: any;
}
const Nominees: React.FC<NomineesProps> = ({ nomineeDetails }) => {
	const { t } = useTranslation();
	return (
		<Grid container>
			<Grid
				item
				xs={11}
				display="flex"
				justifyContent="space-between"
				className="py-15"
			>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('NOMINEES')}
				</Typography>
				<Typography
					color={theme.palette.primary.main}
					fontSize={14}
					fontWeight={600}
				>
					{t('SHARE')}%
				</Typography>
			</Grid>
			{nomineeDetails && nomineeDetails?.length > 0
				? nomineeDetails.map((mapdata: any, index: any) => (
					<Grid item container xs={12} key={index} className="mb-20">
						<Grid item xs={0.8}>
							<Typography
								color={theme.palette.common.black}
								fontSize={16}
								fontWeight={600}
							>
								{index + 1}.
							</Typography>
						</Grid>
						<Grid item container xs={10} className="blue-box ">
							<Grid item container xs={12} className="ml-10 mr-10 py-10">
								<Grid
									item
									xs={12}
									display="flex"
									justifyContent="space-between"
								>
									<Typography
										color={theme.palette.common.black}
										fontSize={16}
										fontWeight={600}
									>
										{mapdata?.nomineeName?.length === 0
											? '-'
											: mapdata?.nomineeName}
									</Typography>
									<Typography
										color={theme.palette.common.black}
										fontSize={16}
										fontWeight={600}
									>
										{mapdata?.sharePerentage?.length === 0
											? '-'
											: mapdata?.sharePerentage}
										%
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12} className="ml-10  ">
								<>
									{mapdata && mapdata?.gender === '1' ? (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											fontWeight={600}
										>
											{t('MALE')}
										</Typography>
									) : mapdata && mapdata?.gender === '2' ? (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											fontWeight={600}
										>
											{t('FEMALE')}
										</Typography>
									) : mapdata && mapdata?.gender === '3' ? (
										<Typography
											color={theme.palette.common.black}
											fontSize={14}
											fontWeight={600}
										>
											{t('OTHERS')}
										</Typography>
									) : (
										'-'
									)}

									{/* <Typography color={theme.palette.common.black} fontSize={14}>
										{mapdata?.gender?.length === 0 ? '' : `${mapdata?.gender},`}
									</Typography> */}
								</>
								<Typography color={theme.palette.common.black} fontSize={14}>
									{(() => {
										switch (true) {
											case mapdata?.age?.length === 0:
												return '-';
											case mapdata?.age === '0':
												return '';
											default:
												return `${mapdata?.age} yrs`;
										}
									})()}
								</Typography>
								<Typography color={theme.palette.common.black} fontSize={14}>
									{mapdata?.relationToInsured?.length === 0
										? '-'
										: mapdata?.relationToInsured}
								</Typography>
								<Typography color={theme.palette.primary.main} fontSize={14}>
									{t('PAN')}
								</Typography>
								<Typography color={theme.palette.common.black} fontSize={14}>
									{mapdata?.pan?.length === 0 ? '-' : mapdata?.pan}
								</Typography>
								<Typography color={theme.palette.primary.main} fontSize={14}>
									{t('UID')}
								</Typography>
								<Typography color={theme.palette.common.black} fontSize={14}>
									{mapdata?.uid?.length === 0 ? '-' : mapdata?.uid}
								</Typography>
							</Grid>

							{mapdata && mapdata?.Appointee?.length ? <>
								<Grid item xs={12} className="py-5">
									<Separator color={theme.palette.grey[400]} />
								</Grid>
								<Grid item xs={12} container className="ml-10 py-15 ">
									<Grid item xs={12}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
										>
											{t('APPOINTEE')}
										</Typography>
									</Grid>

									{mapdata && mapdata?.Appointee?.length > 0
										? mapdata?.Appointee?.map(
											(mapAppointeedata: any, index: any) => (
												<Grid
													item
													xs={12}
													container
													key={index}
													className="mb-5"
												>
													<Grid item xs={12}>
														<Typography
															color={theme.palette.common.black}
															fontSize={16}
															fontWeight={600}
															className="py-5"
														>
															{/* [{t('APPOINTEE NAME')}] */}
															{mapAppointeedata?.appointeeName?.length === 0
																? '-'
																: mapAppointeedata?.appointeeName}
														</Typography>
													</Grid>

													{mapAppointeedata?.gender?.length !== 0 && (
														<Grid item xs={12}>
															<Typography
																color={theme.palette.common.black}
																fontSize={14}
															>
																{mapAppointeedata?.gender?.length === 0
																	? '-'
																	: mapAppointeedata?.gender}
															</Typography>
														</Grid>
													)}
													{mapAppointeedata?.age?.length !== 0 && (
														<Grid item xs={12}>
															<Typography
																color={theme.palette.common.black}
																fontSize={14}
															>
																{mapAppointeedata?.age?.length === 0
																	? '-'
																	: mapAppointeedata?.age}
																{` ${t('yrs')}`}
															</Typography>
														</Grid>
													)}

													<Typography
														color={theme.palette.common.black}
														fontSize={14}
														className="text-wrap"
													>
														{mapAppointeedata?.relation?.length === 0
															? '-'
															: mapAppointeedata?.relation}
													</Typography>

													<Grid item xs={12}>
														<Typography
															color={theme.palette.primary.main}
															fontSize={14}
															className="mt-10"
														>
															{t('PAN')}
														</Typography>
														<Typography
															color={theme.palette.common.black}
															fontSize={14}
														>
															{mapAppointeedata?.panNo?.length === 0
																? '-'
																: mapAppointeedata?.panNo}
														</Typography>

														<Typography
															color={theme.palette.primary.main}
															fontSize={14}
														>
															{t('UID')}
														</Typography>
														<Typography
															color={theme.palette.common.black}
															fontSize={14}
														>
															{mapAppointeedata?.uid?.length === 0
																? '-'
																: mapAppointeedata?.uid}
														</Typography>
													</Grid>
												</Grid>
											)
										)
										: <></>}
								</Grid>

							</> : <></>}
						</Grid>
					</Grid>
				))
				: '-'}
		</Grid>
	);
};
export default Nominees;
