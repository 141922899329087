import React, { useState, useMemo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { find, groupBy, prop, propEq } from 'ramda';
import theme from '../../../../common/theme';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { ReactComponent as LifeIcon } from '../../../../common/icons/group-certified-small.svg';
import { ReactComponent as HealthIcon } from '../../../../common/icons/small-health-icon.svg';
import { ReactComponent as AssetsIcon } from '../../../../common/icons/small-assets-icon.svg';
import { ReactComponent as Verify } from '../../../../common/icons/update-mobile/verify_orange.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import CustomButton from '../../../../common/ui/CustomButton';
import {
	fetchActivePolicy,
	// fetchActivePolicy,
	selectActivePolicies,
	selectIsPolicyLoading,
} from '../../PolicyScreen/Policies.slice';
import {
	assignBank,
	assignPoliciesForUnassignedBank,
	selectTotalPolices,
	selectUploadCanceledChequePayloadDetails,
	skipBank,
} from '../../BankModule/Bank.slice';
import { PolicyAlternateCodes } from '../../../../common/types/PolicyTypes';
import { SELECT_POLICY_TYPES_FOR_MODULES } from '../../../../common/Enums';
import { assignEmail, selectPoliciesForEmails } from '../../Email/Emails.slice';
import Spinner from '../../../../common/ui/Spinner';
import {
	assignAddress,
	selectPoliciesForAddress,
} from '../../AddressModule/Address.slice';
import {
	assignMobileNumber,
	selectPoliciesForMobileNumbers,
} from '../../MobileNumber/MobileNumber.slice';
import './UpdateMobile.scss';
import Separator from '../../../../common/ui/Separator';
import AssignPolicyCard from './AssignPolicyCard';
import FormCheckBox from '../../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';

const AssignPolicyScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { t } = useTranslation();

	const newMobileNo = useMemo(
		() => location?.state?.newMobileNoData?.mobileNo,
		[location?.state]
	);
	const newAddress = useMemo(
		() => location?.state?.newAddress,
		[location?.state]
	);
	const bankOCRDetails = useMemo(
		() => location?.state?.bankOCRDetails,
		[location?.state]
	);

	const bankDetails = useMemo(
		() => location?.state?.bankDetails,
		[location?.state]
	);

	const newEmailData = useMemo(
		() => location?.state?.newEmailData?.email,
		[location?.state]
	);

	const policiesForMobileNumber =
		useSelector(selectPoliciesForMobileNumbers)?.mobileBasedPolicies || [];
	const policiesForEmail =
		useSelector(selectPoliciesForEmails)?.emailBasedPolicies || [];
	const policiesForBank = useSelector(selectTotalPolices);
	const policiesForAddress = useSelector(selectPoliciesForAddress)?.addressBasedPolicies || [];
	const activePolicies = useSelector(selectActivePolicies);
	const isPolicyLoading = useSelector(selectIsPolicyLoading);
	const uploadChequePayload = useSelector(
		selectUploadCanceledChequePayloadDetails
	);
	const [selectedPolicies, setSelectedPolicies] = useState<any[]>([]);

	const [selectAll, setSelectAll] = useState(false);

	const [assertSelect, setassertSelect] = useState(false);
	const [healthSelect, sethealthSelect] = useState(false);
	const [lifeSelect, setlifeSelect] = useState(false);

	const groupPoliciesByNumber = groupBy<any>(prop<any>('alternateCode'));

	const policies: any = useMemo(() => {
		let verifiedPolicies: any[] = [];
		if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.MOBILE
		) {
			verifiedPolicies =
				policiesForMobileNumber?.length > 0
					? policiesForMobileNumber?.filter(
						({ insIntegFlag, mobileNo }: any) =>
							String(insIntegFlag).toUpperCase() === 'Y' &&
							newMobileNo !== mobileNo
					)
					: [];
		} else if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.EMAIL
		) {
			verifiedPolicies =
				policiesForEmail?.length > 0
					? policiesForEmail?.filter(
						({ insIntegFlag, email }: any) =>
							String(insIntegFlag).toUpperCase() === 'Y' &&
							newEmailData !== email
					)
					: [];
		} else if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.BANK
		) {
			verifiedPolicies =
				policiesForBank?.length > 0
					? policiesForBank?.filter(
						({ insIntegFlag, bankAcNo }: any) =>
							String(insIntegFlag).toUpperCase() === 'Y' &&
							(bankOCRDetails
								? bankOCRDetails?.accountNumber !== bankAcNo
								: bankDetails?.bankAcNo !== bankAcNo)
					)
					: [];
		} else if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS
		) {
			verifiedPolicies =
				policiesForAddress?.length > 0
					? policiesForAddress?.filter(
						({ insIntegFlag, fullAddress }: any) =>
							String(insIntegFlag).toUpperCase() === 'Y' && newAddress.fullAddress !== fullAddress
					)
					: [];
		}
		// const verifiedPolicies =
		// 	activePolicies?.length > 0
		// 		? activePolicies?.filter(
		// 			({ verifiedFlag }: any) =>
		// 				String(verifiedFlag).toUpperCase() === 'Y'
		// 		)
		// 		: [];
		return groupPoliciesByNumber(verifiedPolicies);
	}, [location, groupPoliciesByNumber]);

	// useEffect(() => {
	// 	const payload = {
	// 		moduleType: location?.state?.MODULE_TYPE,
	// 	};
	// 	dispatch(fetchActivePolicy(payload as any));
	// }, []);

	useEffect(() => {
		if (
			!policiesForMobileNumber?.length &&
			!policiesForEmail?.length &&
			!policiesForAddress?.length &&
			!policiesForBank?.length
		)
			assignPolicy(true);
		// navigate(-1);
	}, []);

	const handleCheckBox = (data: any) => (e: any) => {
		const checked: boolean = Boolean(e?.target?.checked);
		if (checked) {
			const newArray: any[] = [...selectedPolicies, data];
			setSelectedPolicies(newArray);
		} else {
			const newArray: any[] = selectedPolicies.filter(
				(selectedPolicy: { policyNo: any }) =>
					selectedPolicy.policyNo !== data.policyNo
			);
			setSelectedPolicies(newArray);
		}
	};

	const handleSelctAllChange = () => {
		// if (selectAll) {
		// 	setSelectedPolicies([]);
		// } else {
		setSelectedPolicies(
			(policies[PolicyAlternateCodes.LIFE] || []).concat(
				(policies[PolicyAlternateCodes.ASSET] || []).concat(
					policies[PolicyAlternateCodes.HEALTH] || []
				)
			)
		);
		// }
		setSelectAll(!selectAll);
	};

	const selectAssert = () => {
		if (assertSelect) {
			// setSelectedPolicies([]);
		} else {
			if (policies[PolicyAlternateCodes.ASSET]) {
				setSelectedPolicies([
					...selectedPolicies,
					...policies[PolicyAlternateCodes.ASSET],
				]);
			}
		}
		setassertSelect(!assertSelect);
	};

	const selectHealth = () => {
		if (healthSelect) {
			// setSelectedPolicies([]);
		} else {
			if (policies[PolicyAlternateCodes.HEALTH]) {
				setSelectedPolicies([
					...selectedPolicies,
					...policies[PolicyAlternateCodes.HEALTH],
				]);
			}
		}
		sethealthSelect(!healthSelect);
	};
	const selectLife = () => {
		if (lifeSelect) {
			// setSelectedPolicies([]);
		} else {
			if (policies[PolicyAlternateCodes.LIFE]) {
				setSelectedPolicies([
					...selectedPolicies,
					...policies[PolicyAlternateCodes.LIFE],
				]);
			}
		}
		setlifeSelect(!lifeSelect);
	};

	const assignPolicy = (isSkip: any) => {
		const policies = isSkip ? [] : selectedPolicies;
		if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.MOBILE
		) {
			const handleDone = () => navigate(-1);
			const payload = {
				selectedPolicies: policies,
				newMobileNo,
				handleDone,
				isOld: location?.state?.isOld,
				primaryFlag: location?.state?.newMobileNoData?.primaryFlag ?? 'Z',
				purpose: 'ASSIGN_POLICIES',
			};
			dispatch(assignMobileNumber(payload));
		} else if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.EMAIL
		) {
			const handleDone = () => navigate(-1);
			const payload = {
				selectedPolicies: policies,
				newEmail: location?.state?.newEmailData?.email,
				handleDone,
				isOld: location?.state?.isOld,
				primaryFlag: location?.state?.newEmailData?.primaryFlag ?? 'Z',
				purpose: 'ASSIGN_POLICIES',
			};
			dispatch(assignEmail(payload));
		} else if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS
		) {
			const handleDone = () => navigate(-1);
			let payload = {};
			if (location?.state?.isOld && isSkip) {
				payload = {
					selectedPolicies: policies,
					newAddress: newAddress,
					handleDone,
				};
				navigate(-1);
			} else {
				payload = {
					selectedPolicies: policies,
					newAddress,
					handleDone,
					isOld: location?.state?.isOld,
					purpose: 'ASSIGN_POLICIES',
				};
				dispatch(assignAddress(payload));
			}
			// payload = { selectedPolicies: policies, newAddress, handleDone };
			// dispatch(assignAddress(payload));
		} else if (
			location?.state?.MODULE_TYPE === SELECT_POLICY_TYPES_FOR_MODULES.BANK
		) {
			const handleDone = () => navigate(-1);
			if (location?.state?.isUnassignedBank) {
				if (policies?.length === 0) {
					handleDone();
				} else {
					const payload = {
						selectedPolicies: policies,
						unAssignedBankDetails: location?.state?.bankDetails,
						purpose: 'ASSIGN_POLICIES',
						handleDone,
					};
					dispatch(assignPoliciesForUnassignedBank(payload));
				}
			} else if (policies?.length === 0 && bankOCRDetails) {
				const payload: any = {
					isVerified: true,
					uploadChequePayload,
					bankOCRDetails,
					handleDone,
					skipApiChanges: false,
				};
				dispatch(skipBank(payload));
			} else {
				const payload = {
					selectedPolicies: policies,
					newOCRDetails: bankOCRDetails,
					newBank: location?.state?.bankDetails,
					handleDone,
					purpose: 'ASSIGN_POLICIES',
				};
				dispatch(assignBank(payload));
			}
		}
	};

	return (
		<div className="mx-50">
			<div className="fixed-policy-header">
				<Grid item xs={12}>
					<Typography
						className="f-16 fw-600 mb-8"
						color={theme.palette.primary.main}
					>
						{t('SELECT_POLICIES_TO_ASSIGN')}
					</Typography>
				</Grid>
				<Grid item xs={12} justifyContent="center" className="mb-10">
					<Grid container spacing={10}>
						<Grid item xs={1}>
							<CustomButton
								text={t('SKIP')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => assignPolicy(true)}
								className="fw-400 f-14 sentence-straight"
							/>
						</Grid>
						<Grid item xs={1}>
							<CustomButton
								className="mr-25 fw-600 f-14 sentence-straight"
								text={t('ASSIGN')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Verify}
								type="submit"
								disabled={selectedPolicies?.length === 0}
								onClick={() => assignPolicy(false)}
							/>
						</Grid>
						<Grid item flex={1} textAlign={'right'}>
							{/* <FormCheckBox
								label={
									<Typography
										className="f-16 fw-600"
										// color={theme.palette.common.black}
										color={theme.palette.primary.main}
									>
										{t('Select All')}
									</Typography>
								}
								checked={selectAll}
								onChange={handleSelctAllChange}
								size={16}
								checkBoxPadding={3}
							/> */}
							<CustomButton
								text={t('SELECT_ALL')}
								variant="text"
								showIcon={false}
								className="f-16 fw-400 "
								onClick={() => handleSelctAllChange()}
							/>
							{/* <Typography
								className="f-16 fw-400"
								color={theme.palette.primary.main}
								onClick={() => handleSelctAllChange()}
							>
								{t('SELECT_ALL')}
							</Typography> */}
						</Grid>
					</Grid>
					<Grid item className="mt-5">
						<Separator color={theme.palette.primary.dark} borderWidth={1} />
					</Grid>
				</Grid>
			</div>
			<div style={{ marginTop: 80 }} />
			{isPolicyLoading ? (
				<Grid item xs={12} container justifyContent="center">
					<Grid item>
						<Spinner />
					</Grid>
				</Grid>
			) : (
				<>
					<Grid spacing={5} container justifyContent="space-between">
						<Grid item className="policies-select">
							<Grid
								container
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Grid item display="flex">
									<LabelWithIcon
										svgClassName="ml-5"
										justifyContent="flex-start"
										Icon={LifeIcon}
										label={t('LIFE')}
										labelProps={{
											color: theme.palette.primary.main,
											fontSize: 14,
										}}
										className="mb-10"
									/>
								</Grid>

								<Grid display="flex" item ml={10}>
									<CustomButton
										text={t('SELECT_ALL')}
										variant="text"
										showIcon={false}
										className="f-16 fw-400  mb-10"
										onClick={() => selectLife()}
									/>
								</Grid>
							</Grid>
							{policies[PolicyAlternateCodes.LIFE] &&
								policies[PolicyAlternateCodes.LIFE].map((data: any) => (
									<AssignPolicyCard
										type={location?.state?.MODULE_TYPE}
										policyDetails={data}
										handleCheck={handleCheckBox(data)}
										isChecked={Boolean(
											find(propEq(data?.policyNo, 'policyNo'))(
												selectedPolicies
											)
										)}
									/>
								))}

						</Grid>
						<Grid item>
							<Grid
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Grid item>
									<LabelWithIcon
										className="mb-10"
										svgClassName="ml-5"
										justifyContent="flex-start"
										Icon={HealthIcon}
										label={t('HEALTH')}
										labelProps={{
											color: theme.palette.primary.main,
											fontSize: 14,
										}}
									/>
								</Grid>

								<Grid item ml={10}>
									<CustomButton
										text={t('SELECT_ALL')}
										variant="text"
										showIcon={false}
										className="f-16 fw-400 mb-10"
										onClick={() => selectHealth()}
									/>
								</Grid>
							</Grid>

							{policies[PolicyAlternateCodes.HEALTH] &&
								policies[PolicyAlternateCodes.HEALTH].map((data: any) => (
									<AssignPolicyCard
										type={location?.state?.MODULE_TYPE}
										policyDetails={data}
										handleCheck={handleCheckBox(data)}
										isChecked={Boolean(
											find(propEq(data?.policyNo, 'policyNo'))(selectedPolicies)
										)}
									/>
								))}
						</Grid>
						<Grid item>
							<Grid
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Grid item>
									<LabelWithIcon
										className="mb-10"
										svgClassName="ml-5"
										justifyContent="flex-start"
										Icon={AssetsIcon}
										label={t('ASSETS_MOTOR')}
										labelProps={{
											color: theme.palette.primary.main,
											fontSize: 14,
										}}
									/>
								</Grid>

								<Grid item ml={10}>
									<CustomButton
										text={t('SELECT_ALL')}
										variant="text"
										showIcon={false}
										className="f-16 fw-400 mb-10"
										onClick={() => selectAssert()}
									/>
								</Grid>
							</Grid>

							{policies[PolicyAlternateCodes.ASSET] &&
								policies[PolicyAlternateCodes.ASSET].map((data: any) => (
									<AssignPolicyCard
										type={location?.state?.MODULE_TYPE}
										policyDetails={data}
										handleCheck={handleCheckBox(data)}
										isChecked={Boolean(
											find(propEq(data?.policyNo, 'policyNo'))(selectedPolicies)
										)}
									/>
								))}
						</Grid>
					</Grid>
				</>
			)}
		</div>
	);
};

export default AssignPolicyScreen;
