import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SvgConverter from '../../../../common/SvgConverter';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { ReactComponent as TickSuccessIcon } from '../../../../common/icons/dashboard/green_tick_icon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../common/icons/right_arrow-icon.svg';
import Separator from '../../../../common/ui/Separator';
import { ReactComponent as RupeeOrangeIcon } from '../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { convertAmount, getFormatDate } from '../../../../common/utils';
import CustomButton from '../../../../common/ui/CustomButton';
import Currency from '../../Common/Currency';

const PolicyCalendarCard = ({
	data,
	isLastItem,
}: {
	data?: any;
	isLastItem: boolean;
}) => {
	const theme = useTheme();
	const dateVal = data?.premiumDueDate;
	// if (data) {
	// 	data.name = 'FinanceMyPremium';
	// }
	let date = new Date(dateVal);
	const parts = dateVal.split('-');
	const day = parts[2];
	// const month = parts[1];
	date.setMonth(date.getMonth());
	const month = date.toLocaleString('en-EN', { month: 'short' });
	const { t } = useTranslation();

	const currentDate = new Date();
	return (
		<Grid
			item
			container
			borderBottom={
				isLastItem === false ? `1px solid ${theme.palette.grey[400]}` : 'none'
			}
		>
			<Grid item xs={2}>
				<Grid item xs={12} display="flex" justifyContent="end">
					{data?.paymentFlag === 'Y' ? (
						<Typography
							color={theme.palette.secondary.main}
							fontSize={28}
							fontWeight={300}
						>
							{day || '-'}
						</Typography>
					) : (
						<>
							<Grid item className="">
								<Typography
									color=" rgba(127, 127, 127, 0.99)"
									fontSize={28}
									fontWeight={300}
								>
									{day || '-'}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="end">
					{data.paymentFlag === 'Y' ? (
						<Typography
							component="span"
							color={theme.palette.primary.main}
							whiteSpace="nowrap"
							fontSize={14}
							fontWeight={600}
							className="mb-5"
						>
							{month || '-'}
						</Typography>
					) : (
						<Typography
							component="span"
							whiteSpace="nowrap"
							color=" rgba(127, 127, 127, 0.99)"
							fontSize={14}
							fontWeight={600}
							className="mb-5"
						>
							{month || '-'}
						</Typography>
					)}
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="end">
					<img
						src={data.insurerLogo}
						width={42}
						height={42}
						className="claendar-image"
						alt="insurer logo"
					/>
				</Grid>
			</Grid>
			<Grid item xs={0.5} className="py-5 mt-5">
				<Separator
					orientation="vertical"
					color={theme.palette.grey[400]}
					className="h-75"
				/>
			</Grid>
			<Grid item xs={9.5} className="mt-5 mb-5">
				{data.paymentFlag === 'Y' ? (
					<Grid item xs={12} className="px-15">
						<Typography
							color={theme.palette.common.black}
							fontSize={16}
							fontWeight={600}
							lineHeight="14px"
							className="py-5"
						>
							{data.productName}
						</Typography>
					</Grid>
				) : (
					<Grid item xs={12} className="px-15">
						<Typography
							color="rgba(127, 127, 127, 0.99)"
							fontSize={16}
							fontWeight={600}
							lineHeight="14px"
							className="py-5"
						>
							{data.productName}
						</Typography>
					</Grid>
				)}
				<Grid item xs={12} className="px-15">
					<Typography
						color={theme.palette.common.black}
						fontSize={14}
						lineHeight="14px"
						className="py-5"
					>
						{data?.premiumType} {t('PREMIUM')}
					</Typography>
					<Grid container alignItems={'center'}>
						<Currency amount={data.premiumAmount} policyDetails={data} amountClass="f-16 fw-600 ml-3" />
					</Grid>
					{/* <LabelWithIcon
						justifyContent="flex-start"
						Icon={RupeeOrangeIcon}
						label={convertAmount(data.premiumAmount)}
						labelProps={{
							color: theme.palette.common.black,
							fontSize: 16,
							fontWeight: 600,
						}}
					/> */}
				</Grid>
				<Grid item xs={12}>
					{data.paymentFlag === 'Y' ? (
						<>
							<Typography
								color={theme.palette.secondary.main}
								fontSize={14}
								className="px-15"
							>
								{t('DUE_ON')}{' '}
								{data?.premiumDueDate
									? getFormatDate(data?.premiumDueDate)
									: '-'}
							</Typography>
							<Grid item className="mb-10 mt-10 ml-15">
								<Separator color={theme.palette.grey[400]} />
							</Grid>
						</>
					) : (
						<>
							<Grid item className="">
								<Typography
									color={theme.palette.grey[400]}
									fontSize={14}
									className="px-15"
								>
									{currentDate > new Date(data?.premiumDueDate) ? t('WAS_DUE_ON') : t('DUE_ON')}
									{/* {t('DUE_ON')}{' '} */} {" "}
									{data?.premiumDueDate
										? getFormatDate(data?.premiumDueDate)
										: '-'}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid item xs={12}>
					{data.paymentStatus.toUpperCase() === 'Y' &&
						data.paymentDate != '' ? (
						<>
							<Grid container className="paid-date mt-10">
								<Grid item className="mt-3 ml-20">
									<LabelWithIcon
										justifyContent="flex-start"
										Icon={TickSuccessIcon}
										label={`${t('PAID_ON')} -`}
										// label= {data.paymentDate}
										labelProps={{
											color: theme.palette.success.main,
											fontSize: 13,
										}}
										className="mb-5"
									/>
								</Grid>
								<Typography className="paid-date mt-3 f-13">
									{/* {data.paymentDate || '-'} */}
									{getFormatDate(data?.paymentDate)}
								</Typography>
							</Grid>
						</>
					) : (
						<>
							<Grid container className="mt-5" justifyContent="space-between">
								<Grid item>
									{/* <Grid
                                        container
                                        alignItems="center"
                                        spacing={0.5}
                                        className="finance-my-premium p-5"
                                    >
                                        <Grid item>
                                            <SvgConverter
                                                Icon={OrangeRuppee}
                                                width={10}
                                                height={16}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                color={theme.palette.primary.main}
                                                fontSize={12}
                                                fontWeight={600}
                                            >
                                                FINANCE MY PREMIUM
                                            </Typography>
                                        </Grid>
                                    </Grid> */}
									{/* current dis feature is not implemented so it is commented */}
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>

			<Grid item className="mb-5">
				{data.paymentFlag.toUpperCase() === 'Y' ? (
					<Grid container alignItems="center" spacing={0.5}>
						{data.financeMyPremium.toUpperCase() === 'Y' ? (
							<Grid item>
								<Grid
									container
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									<Link to={APP_ROUTES.NEED_MONEY} state={data}>
										<Grid item display="flex" className="ml-15 custom-btn">
											<CustomButton
												text="FINANCE MY PREMIUM"
												variant="text"
												color="primary"
												showIcon={false}
												startIcon={RupeeOrangeIcon}
												fontSize={12}
												className="color-grey"
											/>
										</Grid>
									</Link>
									{/* <Grid item style={{ position: 'relative' }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '-138%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <SvgConverter
                                            className="rotate-180"
                                            Icon={ArrowRightIcon}
                                            width={15}
                                            height={15}
                                        />
                                    </div>
                                </Grid> */}
								</Grid>
							</Grid>
						) : (
							<Typography className="margin-left-cls"></Typography>
						)}
						<Grid container justifyContent="end">
							<Link
								to={
									APP_ROUTES.RENEWAL_PAYMENT_SCREEN
								}
								state={{ data }}
							>
								<Grid
									// className="f-14 fw-6"
									xs={12}
									container
									display="flex"
									alignItems="center"
									justifyContent="right"
								>
									<Grid item xs={8}>
										<Typography
											color={theme.palette.primary.main}
											fontSize={14}
											fontWeight={600}
											width={70}
											mt={1.2}
											
										// style={{ wordWrap: 'break-word' }}

										>
											{t('PAY_NOW')}
										</Typography>
									</Grid>
									<Grid item xs={2} className="mx-3 mt-8">
										<SvgConverter
											className="rotate-180 ml-8"
											Icon={ArrowRightIcon}
											
										/>
									</Grid>
								</Grid>
							</Link>
						</Grid>
					</Grid>
				) : (
					<Grid container className="mt-5" justifyContent="space-between">
						<Grid item></Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default PolicyCalendarCard;
