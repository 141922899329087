import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Separator from '../../../common/ui/Separator';
import './PersonalProfileScreen.css';
import UserProfileCard from '../../../common/ui/UserProfileCard';
import CustomButton from '../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as NotInformativeIcon } from '../../../common/icons/profile/i_icon.svg';
import ImagePickerModal from '../../../common/ui/Modal/ImagePickerModal';
import { fetchUserProfile, selectUserProfile } from './Profile.slice';
import { getFormatDate, verifyNullForObject } from '../../../common/utils';
import {
	uploadProfilePhotoRequest,
	uploadSignaturePhotoRequest,
	selectSignatureImageIsLoading,
} from '../ImageUpload/Upload.Slice';
import {
	selectIsUserLoggedIn,
	selectSendCommunicationLoading,
	sendCommunicationRequest,
} from '../../Auth/auth.slice';
import {
	SEND_COMMUNICATION_FLAG,
	SEND_COMMUNICATION_PURPOSE,
} from '../../../common/Enums';
import ProfilePic from '../../../common/icons/profile/profile-icon.svg';
import EmailEntryModal from '../Common/EmailEntryModal';
import { verifyEmailRequest } from '../Email/Emails.slice';

import PersonalDetailModal from '../Common/PersonalDetailModal';
import Spinner from '../../../common/ui/Spinner';

interface PersonalDetailsProps {
	dob?: any;
	gender?: string;
	fatherName?: any;
	pan?: string | number;
	aadhar?: string | number;
	voterId?: string | number;
	drivingLicense?: string | number;
	passport?: string | number;
	mobileNumber?: string | number;
	email?: string | number;
	mobileNumberCode?: string | number;
	policyMobileNo: any;
	policyEmail: any;
	Images: any;
}
const PersonalDetails = ({
	dob,
	gender,
	fatherName,
	pan,
	aadhar,
	voterId,
	drivingLicense,
	passport,
	mobileNumber,
	email,
	mobileNumberCode,
	policyMobileNo,
	policyEmail,
	Images,
}: PersonalDetailsProps) => {
	const [, setImageBase64] = React.useState<string | null>(null);
	const [, setProfileUri] = React.useState<string | null>(null);
	const [showAddModal, setShowAddModal] = useState(false);
	const [addType, setaddType] = useState('');
	const [, setSignImage64] = React.useState<string | null>(null);
	const [, setSignUri] = React.useState<string | null>(null);
	const [uploadType, setUploadType] = React.useState<string | null>(null);
	const type = 'Profile';
	const theme = useTheme();
	const relationShipType = 'father';
	// const [, setImage] = useState('');
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userProfile = useSelector(selectUserProfile);
	const isSignatureLoading = useSelector(selectSignatureImageIsLoading);
	const [open, setOpen] = useState(false);
	const informARLoading = useSelector(selectSendCommunicationLoading);
	const handleImageSignature = () => {
		setOpen(true);
		setUploadType('SIGNATURE');
	};

	const processImage = (data: any, file: any) => {
		try {
			const imgType = uploadType;
			const uri = data?.assets?.[0]?.uri;
			const imageBase64 = data;
			const fileName = file?.target?.files?.[0]?.name;
			const generateUniqueFileName = () => {
				const timestamp = Date.now();
				const randomId = Math.random().toString(36).substring(2, 8);
				return `image_${timestamp}_${randomId}.jpg`;
			};
			const fileNames = generateUniqueFileName();

			if (imageBase64 || fileName) {
				if (imgType === 'PROFILE') {
					dispatch(
						uploadProfilePhotoRequest({
							imgType,
							imageBase64,
							addRemoveFlag: 'ADD',
							fileName: fileName || fileNames,
						} as any)
					);
					setImageBase64(imageBase64 as string);
					setProfileUri(imageBase64 as string);
				} else if (imgType === 'SIGNATURE') {
					dispatch(
						uploadSignaturePhotoRequest({
							imgType,
							imageBase64,
							addRemoveFlag: 'ADD',
							fileName: fileName || fileNames,
						} as any)
					);
					setSignImage64(imageBase64 as string);
					setSignUri(uri as string);
				}
				setOpen(false);
			}
		} catch (e) {
			// Empty catch block
		}
	};
	const getGenderText = (gender: any) => {
		switch (gender) {
			case 'F':
				return 'Female';
			case 'M':
				return 'Male';
			default:
				return '-';
		}
	};

	const profileReadinessVal =
		userProfile?.Customer?.profileReadiness &&
		userProfile?.Customer?.profileReadiness.replace(/\s/g, '');

	const loggedUser = useSelector(selectIsUserLoggedIn);
	const informARSubmit = () => {
		dispatch(
			sendCommunicationRequest({
				Customer: {
					eiaNo: loggedUser?.eiaNo,
					purpose: SEND_COMMUNICATION_PURPOSE.AR_INTIMATION,
					flag: SEND_COMMUNICATION_FLAG.SUCCESS,
					policyNo: '',
					alternateCode: '',
					insurerCode: '',
				},
			})
		);
	};
	const handleNewAadharrSubmit = (data: any) => {
		const types = 'personalDetails';
		data.handleSuccess = () => {
			setShowAddModal(false);
			dispatch(fetchUserProfile());
		};
		dispatch(verifyEmailRequest({ data, types } as any));
	};

	const handleNewAahaarClick = () => {
		setShowAddModal(true);
		setaddType('aadharAdd');
	};
	const handleNewPassportClick = () => {
		setShowAddModal(true);
		setaddType('passportAdd');
	};
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Grid item className="mb-18">
					<UserProfileCard
						profileImg={
							userProfile?.Customer?.profileImg
								? `data:image;base64, ${userProfile?.Customer?.profileImg}`
								: ProfilePic
						}
						customerName={userProfile?.Customer?.customerName}
						eiaNum={userProfile?.Customer?.eiaNo}
						profileReadiness={
							userProfile?.Customer?.profileReadiness
								? profileReadinessVal
								: '-'
						}
						lastUpdateDate={userProfile?.Customer?.lastUpdateDt}
					/>
				</Grid>
				<Grid item className="w-100">
					<Separator color={theme.palette.primary.main} />
				</Grid>

				<Grid item>
					{userProfile?.Customer?.authorizedRepresentative &&
						userProfile?.Customer?.authorizedRepresentativeMobileNo ? (
						<Grid item>
							<Grid container spacing={1} justifyContent="space-between">
								<Grid item className="mt-6">
									<Grid container spacing={1}>
										<Grid item>
											<Typography className="f-14 fw-600" color="primary">
												{t('AUTHORIZED_REPRESENTATIVE')}
											</Typography>
										</Grid>
										{userProfile?.Customer?.authorizedRepresentative &&
											userProfile?.Customer
												?.authorizedRepresentativeIntimationStatus === 'Y' ? (
											<Grid item>
												<Typography
													className="f-14"
													color={theme.palette.common.black}
												>
													({t('INFORMED')})
												</Typography>
											</Grid>
										) : (
											<Grid item>
												<Typography
													className="f-14"
													color={theme.palette.common.black}
												>
													({t('NOT_INFORMED')})
												</Typography>
											</Grid>
										)}
										<Grid item className="mt-3">
											<SvgConverter
												Icon={NotInformativeIcon}
												width={6}
												height={13}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item>
									{userProfile?.Customer
										?.authorizedRepresentativeIntimationStatus !== 'Y' && (
											<CustomButton
												text={t('INFORM_AR')}
												variant="text"
												fontWeight={600}
												fontSize={16}
												onClick={informARSubmit}
												className="inform_ar_button"
												loading={informARLoading}
											/>
										)}

									<Link to={APP_ROUTES.PROFILE_AR_SCREEN}>
										<CustomButton
											text={t('CHANGE_AR')}
											variant="text"
											showIcon={false}
											fontWeight={600}
											fontSize={16}
										/>
									</Link>
								</Grid>
							</Grid>
							<Grid item>
								<Typography
									className="f-18 fw-600 mb-3"
									color={theme.palette.common.black}
								>
									{userProfile?.Customer?.authorizedRepresentative
										? userProfile?.Customer?.authorizedRepresentative
										: '-'}
								</Typography>
							</Grid>
							<Grid item className="mb-10">
								<Typography className="f-14" color={theme.palette.common.black}>
									{t('MOBILE')}#:&nbsp;
									{userProfile?.Customer?.authorizedRepresentativeMobileNo
										? userProfile?.Customer?.authorizedRepresentativeMobileNo
										: '-'}
								</Typography>
							</Grid>
						</Grid>
					) : (
						<Grid item>
							<Grid item>
								<Typography className="f-14 fw-600" color="primary">
									{t('AUTHORIZED_REPRESENTATIVE')}
								</Typography>
							</Grid>
							<Grid item className="mt-10 ml-n3">
								<Link to={APP_ROUTES.PROFILE_AR_SCREEN}>
									<CustomButton
										text={t('TAP_TO_ADD_AR')}
										variant="text"
										color="primary"
										showIcon={false}
									/>
								</Link>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid item className="w-100">
					<Separator color={theme.palette.primary.main} />
				</Grid>
				<Grid item container className="mt-20  mb-20">
					<Grid
						lg={3}
						xl={2}
						md={4}
						sm={6}
						xs={12}
						item
						className="mb-20 mt-10"
					>
						<Typography className="f-14 fw-600" color="primary">
							{t('FATHER_NAME')}
						</Typography>
						<Typography className="f-18" color={theme.palette.common.black}>
							{fatherName ? (
								verifyNullForObject(fatherName)
							) : (
								<Grid>
									<Link
										to={APP_ROUTES.ADD_FAMILY_SCREEN}
										state={relationShipType}
									>
										<CustomButton
											text={t('TAP_HERE_TO_ADD')}
											variant="text"
											showIcon={false}
											color="primary"
											className="f-12 fw-400"
										/>
									</Link>
								</Grid>
							)}
						</Typography>
					</Grid>
					<Grid
						item
						lg={3}
						xl={2}
						md={4}
						sm={6}
						xs={12}
						className="mb-20 mt-10"
					>
						<Typography className="f-14 fw-600" color="primary">
							{t('DATE_OF_BIRTH')}
						</Typography>
						<Typography className="f-18" color={theme.palette.common.black}>
							{getFormatDate(dob, 'DD-MM-YYYY')}
						</Typography>
					</Grid>
					<Grid
						item
						lg={3}
						xl={2}
						md={4}
						sm={6}
						xs={12}
						className="mb-20 mt-10"
					>
						<Typography className="f-14 fw-600" color="primary">
							{t('GENDER')}
						</Typography>
						<Typography className="f-18" color={theme.palette.common.black}>
							{getGenderText(gender)}
						</Typography>
					</Grid>
					{pan ? (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className="mb-20 mt-10"
						>
							<Typography className="f-14 fw-600" color="primary">
								{t('PAN')}
							</Typography>
							<Typography className="f-18" color={theme.palette.common.black}>
								{pan}
							</Typography>
						</Grid>
					) : (
						''
					)}
					{aadhar ? (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className="mb-20 mt-10"
						>
							<Typography className="f-14 fw-600" color="primary">
								{t('AADHAR')}
							</Typography>
							<Typography className="f-18" color={theme.palette.common.black}>
								{aadhar}
							</Typography>
						</Grid>
					) : (
						''
					)}
					{passport ? (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className="mb-20 mt-10"
						>
							<Typography className="f-14 fw-600" color="primary">
								{t('PASSPORT')}
							</Typography>
							<Typography className="f-18" color={theme.palette.common.black}>
								{passport}
							</Typography>
						</Grid>
					) : (
						''
					)}
					{drivingLicense ? (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className="mb-20 mt-10"
						>
							<Typography className="f-14 fw-600" color="primary">
								{t('DRIVING_LICENSE')}
							</Typography>
							<Typography className="f-18" color={theme.palette.common.black}>
								{drivingLicense}
							</Typography>
						</Grid>
					) : (
						''
					)}
					{voterId ? (
						<Grid
							item
							lg={3}
							xl={2}
							md={4}
							sm={6}
							xs={12}
							className="mb-20 mt-10"
						>
							<Typography className="f-14 fw-600" color="primary">
								{t('VOTER_ID')}
							</Typography>
							<Typography className="f-18" color={theme.palette.common.black}>
								{voterId}
							</Typography>
						</Grid>
					) : (
						''
					)}
					<Grid item className="w-100">
						<Separator color={theme.palette.primary.main} />
					</Grid>
				</Grid>
				<Grid item container className="mt-20 mb-10">
					<Grid item xs={12} className=" mb-14">
						<Typography className="f-14 fw-600" color="primary">
							{t('PRIMARY')} {t('MOBILE_NUMBER')}
						</Typography>
						{mobileNumber &&
							mobileNumber !== '' &&
							mobileNumber !== undefined &&
							mobileNumber !== null ? (
							<>
								{' '}
								<Grid
									item
									container
									className="mt-1 ml-2"
									lg={12}
									xl={2}
									md={4}
									sm={6}
									xs={12}
									display="flex"
									flexDirection="row"
								>
									<Grid item>
										<Typography
											className="f-18 mr-5"
											color={theme.palette.common.black}
										>
											{mobileNumberCode || '91'}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											className="f-18"
											color={theme.palette.common.black}
										>
											{mobileNumber}
										</Typography>
									</Grid>
								</Grid>
								<Link to={APP_ROUTES.UPDATE_MOBILE}>
									<CustomButton
										text={t('SEE_ASSIGN_POLICIES')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-12 fw-400"
									/>
								</Link>
								<Link to={APP_ROUTES.UPDATE_MOBILE} state={{ mobileNumber }}>
									<CustomButton
										text={t('CHANGE_PRIMARY')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-12 fw-400 ml-10"
									/>
								</Link>
							</>
						) : (
							<>
								<Grid display="flex" alignItems="center">
									<Grid>
										<Typography
											className="f-14 fw-600"
											color={theme.palette.common.black}
										>
											{t('NOT_AVAILABLE')}
										</Typography>
									</Grid>
									<Grid>
										<Link to={APP_ROUTES.UPDATE_MOBILE} state={type}>
											<CustomButton
												text={t('TAP_HERE_TO_ADD')}
												variant="text"
												showIcon={false}
												color="primary"
												className="f-12 fw-400"
											/>
										</Link>
									</Grid>
								</Grid>
							</>
						)}
					</Grid>

					{policyMobileNo &&
						policyMobileNo.map((item: any, index: any) => (
							<Grid item lg={3} xl={2} md={4} sm={6} xs={12} className="mb-14">
								<Typography className="f-14 fw-600" color="primary" key={index}>
									{t('MOBILE_NUMBER')} {index + 2}
								</Typography>
								<Grid container className="mt-2 ml-2">
									<Typography
										className="f-18 mr-3"
										color={theme.palette.common.black}
									>
										{item.mobileCountryCode}
									</Typography>
									<Typography
										className="f-18"
										color={theme.palette.common.black}
									>
										{item.mobileNo}
									</Typography>
								</Grid>

								<Link to={APP_ROUTES.UPDATE_MOBILE}>
									<CustomButton
										text={t('SEE_ASSIGN_POLICIES')}
										variant="text"
										showIcon={false}
										color="primary"
										className="f-12 fw-400"
									/>
								</Link>
							</Grid>
						))}
				</Grid>
				<Grid container>
					<>
						{' '}
						<Grid item className="mb-14 " xs={12}>
							<Typography className="f-14 fw-600" color="primary">
								{t('PRIMARY')}
								{t('EMAIL')}
							</Typography>
							<Typography
								className="f-18 personal-email"
								color={theme.palette.common.black}
							>
								{email &&
									email !== '' &&
									email !== undefined &&
									email !== null ? (
									email
								) : (
									<>
										<Grid display="flex" alignItems="center">
											<Grid>
												<Typography
													className="f-14 fw-600"
													color={theme.palette.common.black}
												>
													{t('NOT_AVAILABLE')}
												</Typography>
											</Grid>
											<Grid>
												<Link to={APP_ROUTES.EMAIL} state={type}>
													<CustomButton
														text={t('TAP_HERE_TO_ADD')}
														variant="text"
														showIcon={false}
														color="primary"
														className="f-12 fw-400"
													/>
												</Link>
											</Grid>
										</Grid>
									</>
								)}
							</Typography>
							{email && (
								<>
									<Link to={APP_ROUTES.EMAIL}>
										<CustomButton
											text={t('SEE_ASSIGN_POLICIES')}
											variant="text"
											showIcon={false}
											color="primary"
											className="f-12 fw-400"
										/>
									</Link>
									<Link to={APP_ROUTES.EMAIL} state={{ email }}>
										<CustomButton
											text={t('CHANGE_PRIMARY')}
											variant="text"
											showIcon={false}
											color="primary"
											className="f-12 fw-400 ml-10"
										/>
									</Link>
								</>
							)}
						</Grid>
					</>

					{policyEmail &&
						policyEmail.map((item: any, index: any) => (
							<Grid item className="mb-14 " lg={4} xl={2} md={4} sm={6} xs={12}>
								<Typography className="f-14 fw-600" color="primary">
									{t('EMAIL')} {index + 2}
								</Typography>
								{item?.email !== 'null' &&
									item.email !== '' &&
									item.email !== 'undefined' ? (
									<Typography
										className="f-18 personal-email"
										color={theme.palette.common.black}
										textOverflow="ellipsis"
									>
										{item.email}
									</Typography>
								) : (
									<>
										<Grid display="flex">
											<Typography
												className="f-18 personal-email"
												color={theme.palette.common.black}
											>
												{t('NOT_AVAILABLE')}
											</Typography>

											<Link to={APP_ROUTES.EMAIL} state={type}>
												<CustomButton
													text={t('TAP_HERE_TO_ADD')}
													variant="text"
													showIcon={false}
													color="primary"
													className="f-12 fw-400"
												/>
											</Link>
										</Grid>
									</>
								)}{' '}
								{item?.email !== 'null' &&
									item.email !== '' &&
									item.email !== 'undefined' && (
										<Link to={APP_ROUTES.EMAIL}>
											<CustomButton
												text={t('SEE_ASSIGN_POLICIES')}
												variant="text"
												showIcon={false}
												color="primary"
												className="f-12 fw-400"
											/>
										</Link>
									)}
							</Grid>
						))}

					<Grid item className="w-100 mt-20 mb-10">
						<Separator color={theme.palette.primary.main} />
					</Grid>
				</Grid>
				<Grid
					item
					className="mt-20 mb-20"
					xs={12}
					lg={12}
					xl={12}
					md={12}
					sm={12}
				>
					<Typography className="f-14 fw-600" color="primary">
						{t('SIGNATURE')}
					</Typography>

					{Images &&
						Images.map((item: any) => (
							<>
								{item.imgData && item.imgType === 'S' && (
									<img
										alt="signature"
										src={`data:image/png;base64,${item.imgData}`}
										width={100}
									/>
								)}
							</>
						))}
					{isSignatureLoading ? (
						<Grid item>
							<Spinner />
						</Grid>
					) : (
						<></>
					)}
					{!userProfile?.Images?.some((image) => image.imgType === 'S') && (
						<Typography className="f-16" color="primary">
							<CustomButton
								text={t('TAP_TO_ADD_SIGNATURE')}
								variant="text"
								fontSize={16}
								showIcon={false}
								fontWeight={300}
								onClick={handleImageSignature}
							/>
							<ImagePickerModal
								open={open}
								setOpen={setOpen}
								handleImage={processImage}
								enableCropping
							/>
						</Typography>
					)}
					{userProfile?.Images.map((item: any) => {
						if (item.imgData && item.imgType === 'S') {
							return (
								<Typography className="f-16" color="primary" key={item.id}>
									<CustomButton
										text={t('TAP_TO_CHANGE_SIGNATURE')}
										variant="text"
										fontSize={16}
										showIcon={false}
										fontWeight={400}
										onClick={handleImageSignature}
									/>
									<ImagePickerModal
										open={open}
										setOpen={setOpen}
										handleImage={processImage}
										enableCropping
									/>
								</Typography>
							);
						}
						if (!item.imgData && item.imgType === 'S') {
							return (
								<Typography className="f-16" color="primary" key={item.id}>
									<CustomButton
										text={t('TAP_TO_CHANGE_SIGNATURE')}
										variant="text"
										fontSize={16}
										showIcon={false}
										fontWeight={300}
										onClick={handleImageSignature}
									/>
									<ImagePickerModal
										open={open}
										setOpen={setOpen}
										handleImage={processImage}
										enableCropping
									/>
								</Typography>
							);
						}
						return null;
					})}
				</Grid>
				<Grid item className="w-100 ">
					<Separator color={theme.palette.primary.main} />
				</Grid>
			</Grid>
			<PersonalDetailModal
				open={showAddModal}
				setOpen={setShowAddModal}
				addType={addType}
				type="personalDetails"
				onSubmit={handleNewAadharrSubmit}
			/>
		</Grid>
	);
};

export default PersonalDetails;
