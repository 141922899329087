import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import data from '../../../../common/constants/FormConstants.json';
import config from '../../../../common/config';
import { pinCodeRequest } from '../../Profile/Profile.slice';
import { parsePinCode } from '../../../../common/utils';
import theme from '../../../../common/theme';

const AppointeeAddress = ({
	index,
	item,
	register,
	errors,
	submit,

	setValue,
	appointeeIsChecked
}: {
	index: number;
	item: any;
	register: any;
	errors: any;
	submit: any;

	setValue?: any;
	appointeeIsChecked: boolean
}) => {
	const stateRegex = /^[A-Za-z0-9_]+$/;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [pincode, setPincode] = useState('');
	const handlePinCodeMaster = (pinDetails: any) => {

		setValue(`nomineeData[${index}].Appointee[0].address3`, pinDetails?.districtName);
		setValue(`nomineeData[${index}].Appointee[0].state`, pinDetails?.stateName);
	};
	useEffect(() => {
		if (pincode.length === 6) {
			const payload = {
				Customer: {
					pincode,
				},
				handlePinCodeMaster,
			};
			dispatch(pinCodeRequest(payload as any));
		}

		if (pincode.length != 0) {
			if (pincode.length !== 6) {
				setValue(`nomineeData[${index}].Appointee[0].address3`, '');
				setValue(`nomineeData[${index}].Appointee[0].state`, '');
			}
		}

	}, [pincode]);

	return (
		<form onSubmit={submit}>
			<Grid item key={item.id}>
				<Grid item className="mb-5">
					<FormInput
						type="text"
						placeholder={`${t('HOUSE')} #, ${t('STREET')} #`}
						disabled={appointeeIsChecked}
						name="address1"
						inputProps={{
							className: 'px-10',
							...register(`nomineeData.${index}.Appointee[0].address1`
								, {
									// 	required: {
									// 		value: true,
									// 		message: t(data.STREET),
									// 	},
									pattern: {
										value: config.addressRegex,
										message: 'Invalid address format',
									},
								}
							),
						}}
						errors={errors}
					/>

					{/* {errors?.Appointee?.[0]?.address1?.message ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors?.address1?.message}
											</Typography>
										) : null} */}


				</Grid>

				<Grid item className="mb-5">
					<FormInput
						type="text"
						placeholder={t('LOCALITY,AREA')}
						disabled={appointeeIsChecked}
						name="address2"
						inputProps={{
							className: 'px-10',
							...register(`nomineeData.${index}.Appointee[0].address2`
								, {
									// 	required: {
									// 		value: true,
									// 		message: data.AREA,
									// 	},
									pattern: {
										value: config.addressRegex,
										message: 'Invalid address format',
									},
								}
							),
						}}
						errors={errors}
					/>
					{/* {errors?.Appointee?.[0]?.address2?.message ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors?.Appointee?.[0]?.address2?.message}
											</Typography>
										) : null} */}
				</Grid>
				<Grid container spacing={3} className="mb-5">
					<Grid item xs={6} sm={6}>
						<FormInput
							type="text"
							placeholder={t('CITY')}
							disabled={appointeeIsChecked}
							name="city"
							inputProps={{
								className: 'px-10',
								...register(`nomineeData.${index}.Appointee[0].city`

									, {
										// 	required: {
										// 		value: true,
										// 		message: t(data.CITY),
										// 	},
										validate: (value: any) => {
											if (value != '' && !config.alphabetsOnlyRegex.test(value)) {
												return t(data.INVALID_NAME);
											}
											return true;
										},
									}

								),
							}}
							errors={errors}
						/>
						{/* {errors?.Appointee?.[0]?.city?.message ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors?.Appointee?.[0]?.city?.message}
											</Typography>
										) : null} */}
					</Grid>
					<Grid item xs={6} sm={6}>
						<FormInput
							type="number"
							placeholder={t('PIN')}
							disabled={appointeeIsChecked}
							name="pincode"
							inputProps={{
								...(pincode ? { value: pincode } : {}),
								maxLength: 6,
								className: 'px-10',
								...register(`nomineeData.${index}.Appointee[0].pincode`

									, {
										onChange: (event: any) =>
											setPincode(parsePinCode(event.target.value)),
										// required: {
										// 	value: true,
										// 	message: t(data.PIN),
										// },
										validate: (value: any) => {
											if (value != '' && !config.pincode.test(value)) {
												return t(data.INVALID_PINCODE);
											}
											return true;
										},
									}

								),
							}}
							errors={errors}
						/>
						{/* 
						{errors?.Appointee?.[0]?.pincode?.message ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												{errors?.Appointee?.[0]?.pincode?.message}
											</Typography>
										) : null} */}
					</Grid>
				</Grid>
				<Grid container spacing={3} className="mb-5">
					<Grid item xs={6} sm={6}>
						<FormInput
							type="text"
							placeholder={t('DISTRICT')}
							name="address3"
							readOnly
							inputProps={{
								className: 'px-10 address-color',
								...register(`nomineeData.${index}.Appointee[0].address3`
									, {
										// 	required: {
										// 		value: true,
										// 		message: t(data.DISTRICT),
										// 	},
										// validate: (value: any) => {
										// 	if (value != '' && !config.alphabetsOnlyRegex.test(value)) {
										// 		return t(data.INVALID_NAME);
										// 	}
										// 	return true;
										// },
									}

								),
							}}
							errors={errors}
						/>

					</Grid>
					<Grid item xs={6} sm={6}>
						<FormInput
							type="text"
							placeholder={t('STATE')}
							name="state"
							readOnly
							inputProps={{
								onBlur: { handlePinCodeMaster },
								className: 'px-10 address-color',
								...register(
									`nomineeData.${index}.Appointee[0].state`

									// , {
									// 	required: {
									// 		value: true,
									// 		message: t(data.STATE),
									// 	},
									// 	// validate: (value: any) => {
									// 	// 	if (!stateRegex.test(value)) {
									// 	// 		return data.INVALID_NAME;
									// 	// 	}
									// 	// 	return true;
									// 	// },
									// }
								),
							}}
							errors={errors}
						/>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default AppointeeAddress;
