import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../common';
import {
	FamilyProfile,
	FamilyRelationShips,
	UserProfile,
} from './types/Profile.model';
import { RootState } from '../../../common/store';

interface ProfileSlice {
	isLoading: LoadingState;
	//userProfile: UserProfile | null;
	userProfile: { status: LoadingState; data: UserProfile | null; error: any };
	errors: any | null;
	familyList: {
		status: LoadingState;
		requestInfo: FamilyProfile[] | [];
		error: any;
	};
	familyRelationShip: FamilyRelationShips;
	addFamilyMember: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};

	generateOtpForMobile: { status: LoadingState; data: any; error: any };
	verifyMobileNo: { status: LoadingState; data: any; error: any };
	generateOtpForEmail: { status: LoadingState; data: any; error: any };
	verifyEmail: { status: LoadingState; data: any; error: any };
	familyVerification: { status: LoadingState; data: any; error?: any };
	familyEmailVerification: { status: LoadingState; data: any; error?: any };
	updateAR: { status: LoadingState; data: any; error: any };
	familyUpload: { status: LoadingState; familyInfo: any; error: any };
	pinMaster: { status: LoadingState; data: any; error: any };
	addAddress: { status: LoadingState; data: any; error: any };
	SelfDeclarationDetails: { status: LoadingState; data: any; error: any };
}

const initialState: ProfileSlice = {
	isLoading: 'idle',
	userProfile: {
		status: 'idle',
		data: null,
		error: '',
	},
	familyList: {
		status: 'idle',
		requestInfo: [],
		error: '',
	},
	familyRelationShip: {},
	errors: null,
	addFamilyMember: {
		status: 'idle',
		requestInfo: {},
		error: '',
	},

	generateOtpForMobile: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	verifyMobileNo: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	generateOtpForEmail: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	verifyEmail: {
		status: 'idle',
		data: undefined,
		error: undefined,
	},
	familyVerification: {
		status: 'idle',
		data: {},
		error: null,
	},
	familyEmailVerification: {
		status: 'idle',
		data: {},
		error: null,
	},
	updateAR: {
		status: 'idle',
		data: {},
		error: '',
	},
	familyUpload: {
		status: 'idle',
		familyInfo: null,
		error: null,
	},
	pinMaster: {
		status: 'idle',
		data: {},
		error: '',
	},
	addAddress: {
		status: 'idle',
		data: {},
		error: '',
	},
	SelfDeclarationDetails: {
		status: 'idle',
		data: {},
		error: '',
	},
};

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		fetchUserProfile: (state) => {
			state.userProfile.status = 'loading';
			state.userProfile.error = '';
			state.userProfile.data = null;
		},
		loadUserProfile: (state, action) => {
			state.userProfile.status = 'done';
			state.userProfile.error = '';
			state.userProfile.data = action.payload;
		},
		errorUserProfile: (state, action) => {
			state.userProfile.status = 'error';
			state.userProfile.error = action.payload;
			state.userProfile.data = null;
		},
		fetchUserFamilyListAction: (state) => {
			state.familyList.status = 'loading';
			state.familyList.requestInfo = [];
			state.familyList.error = null;
		},
		loadUserFamilyList: (state, action) => {
			state.familyList.status = 'done';
			state.familyList.requestInfo = action.payload;
			state.familyList.error = null;
		},
		errorUserFamilyList: (state, action) => {
			state.familyList.status = 'done';
			state.familyList.requestInfo = [];
			state.familyList.error = action.payload;
		},
		fetchFamilyRelationShipList: (state) => ({
			...state,
			isLoading: 'loading',
			familyRelationShip: {},
			errors: null,
		}),
		loadFamilyRelationShipList: (state, action) => ({
			...state,
			isLoading: 'done',
			familyRelationShip: action.payload,
			errors: null,
		}),
		errorFamilyRelationShipList: (state, action) => ({
			...state,
			isLoading: 'done',
			familyRelationShip: {},
			errors: action.payload,
		}),

		addFamilyMemberRequest: (state) => {
			state.addFamilyMember.status = 'loading';
			state.addFamilyMember.requestInfo = {};
			state.addFamilyMember.error = null;
		},
		addFamilyMemberSuccess: (state, action) => {
			state.addFamilyMember.status = 'done';
			state.addFamilyMember.requestInfo = action.payload;
			state.addFamilyMember.error = null;
		},
		addFamilyMemberError: (state, action) => {
			state.addFamilyMember.status = 'done';
			state.addFamilyMember.requestInfo = {};
			state.addFamilyMember.error = action.payload;
		},

		verifyGenerateOtpForMobileRequest: (state) => {
			state.generateOtpForMobile.status = 'loading';
			state.generateOtpForMobile.data = undefined;
			state.generateOtpForMobile.error = undefined;
		},
		verifyGenerateOtpForMobileSuccess: (state, { payload }) => {
			state.generateOtpForMobile.status = 'done';
			state.generateOtpForMobile.data = payload;
			state.generateOtpForMobile.error = undefined;
		},
		verifyGenerateOtpForMobileError: (state, { payload }) => {
			state.generateOtpForMobile.status = 'error';
			state.generateOtpForMobile.data = undefined;
			state.generateOtpForMobile.error = payload;
		},
		verifyOtpMobileNoRequest: (state) => {
			state.verifyMobileNo.status = 'loading';
			state.verifyMobileNo.data = undefined;
			state.verifyMobileNo.error = undefined;
		},
		verifyOtpMobileNoSuccess: (state, { payload }) => {
			state.verifyMobileNo.status = 'done';
			state.verifyMobileNo.data = payload;
			state.verifyMobileNo.error = undefined;
		},
		verifyOtpMobileNoError: (state, { payload }) => {
			state.verifyMobileNo.status = 'error';
			state.verifyMobileNo.data = undefined;
			state.verifyMobileNo.error = payload;
		},
		verifyGenerateOtpForEmailRequest: (state) => {
			state.generateOtpForEmail.status = 'loading';
			state.generateOtpForEmail.data = undefined;
			state.generateOtpForEmail.error = undefined;
		},
		verifyGenerateOtpForEmailSuccess: (state, { payload }) => {
			state.generateOtpForEmail.status = 'done';
			state.generateOtpForEmail.data = payload;
			state.generateOtpForEmail.error = undefined;
		},
		verifyGenerateOtpForEmailError: (state, { payload }) => {
			state.generateOtpForEmail.status = 'error';
			state.generateOtpForEmail.data = undefined;
			state.generateOtpForEmail.error = payload;
		},
		verifyOtpEmailRequest: (state) => {
			state.verifyEmail.status = 'loading';
			state.verifyEmail.data = undefined;
			state.verifyEmail.error = undefined;
		},
		verifyOtpEmailSuccess: (state, { payload }) => {
			state.verifyEmail.status = 'done';
			state.verifyEmail.data = payload;
			state.verifyEmail.error = undefined;
		},
		verifyOtpEmailError: (state, { payload }) => {
			state.verifyEmail.status = 'error';
			state.verifyEmail.data = undefined;
			state.verifyEmail.error = payload;
		},
		saveFamilyVerificationDetails: (state) => {
			state.familyVerification.status = 'loading';
			state.familyVerification.data = {};
			state.familyVerification.error = undefined;
		},
		saveFamilyVerificationDetailsSuccess: (state, action) => {
			state.familyVerification.status = 'done';
			state.familyVerification.data = action.payload;
			state.familyVerification.error = undefined;
		},
		saveFamilyVerificationDetailsError: (state, action) => {
			state.familyVerification.status = 'error';
			state.familyVerification.data = {};
			state.familyVerification.error = action.payload;
		},
		saveFamilyEmailVerificationDetails: (state) => {
			state.familyEmailVerification.status = 'loading';
			state.familyEmailVerification.data = {};
			state.familyEmailVerification.error = undefined;
		},
		saveFamilyEmailVerificationDetailsSuccess: (state, action) => {
			state.familyEmailVerification.status = 'done';
			state.familyEmailVerification.data = action.payload;
			state.familyEmailVerification.error = undefined;
		},
		saveFamilyEmailVerificationDetailsError: (state, action) => {
			state.familyEmailVerification.status = 'error';
			state.familyEmailVerification.data = {};
			state.familyEmailVerification.error = action.payload;
		},
		updateArRequest: (state) => {
			state.updateAR.status = 'loading';
			state.updateAR.data = {};
			state.updateAR.error = null;
		},
		updateArSuccess: (state, action) => {
			state.updateAR.status = 'done';
			state.updateAR.data = action.payload;
			state.updateAR.error = null;
		},
		updateArError: (state, action) => {
			state.updateAR.status = 'done';
			state.updateAR.data = {};
			state.updateAR.error = action.payload;
		},
		familyUploadRequest: (state) => {
			state.familyUpload.status = 'loading';
			state.familyUpload.familyInfo = {};
			state.familyUpload.error = null;
		},
		familyUploadSuccess: (state, action) => {
			state.familyUpload.status = 'done';
			state.familyUpload.familyInfo = action.payload;
			state.familyUpload.error = null;
		},
		familyUploadError: (state, action) => {
			state.familyUpload.status = 'error';
			state.familyUpload.familyInfo = {};
			state.familyUpload.error = action.payload;
		},
		pinCodeRequest: (state) => {
			state.pinMaster.status = 'loading';
			state.pinMaster.data = {};
			state.pinMaster.error = null;
		},
		pinCodeSuccess: (state, action) => {
			state.pinMaster.status = 'done';
			state.pinMaster.data = action.payload;
			state.pinMaster.error = null;
		},
		pinCodeError: (state, action) => {
			state.pinMaster.status = 'done';
			state.pinMaster.data = {};
			state.pinMaster.error = action.payload;
		},
		addAddressRequest: (state) => {
			state.addAddress.status = 'loading';
			state.addAddress.data = {};
			state.addAddress.error = null;
		},
		addAddressSuccess: (state, action) => {
			state.addAddress.status = 'done';
			state.addAddress.data = action.payload;
			state.addAddress.error = null;
		},
		addAddressError: (state, action) => {
			state.addAddress.status = 'done';
			state.addAddress.data = {};
			state.addAddress.error = action.payload;
		},
		SelfDeclarationDetailsRequest: (state) => {
			state.SelfDeclarationDetails.status = 'loading';
			state.SelfDeclarationDetails.data = {};
			state.SelfDeclarationDetails.error = null;
		},
		SelfDeclarationDetailsSuccess: (state, action) => {
			state.SelfDeclarationDetails.status = 'done';
			state.SelfDeclarationDetails.data = action.payload;
			state.SelfDeclarationDetails.error = null;
		},
		SelfDeclarationDetailsError: (state, action) => {
			state.SelfDeclarationDetails.status = 'done';
			state.SelfDeclarationDetails.data = {};
			state.SelfDeclarationDetails.error = action.payload;
		},
	},
});

export const selectIsLoading = (state: RootState) =>
	state.profile.isLoading === 'loading';

export const selectUserProfileLoading = (state: RootState) =>
	state.profile.userProfile.status === 'loading';

export const selectUserProfile = (state: RootState) =>
	state.profile.userProfile.data;

export const selectFamilyListIsLoading = (state: RootState) =>
	state.profile.familyList.status === 'loading';

export const selectUserFamilyList = (state: RootState) =>
	state.profile.familyList.requestInfo;

export const selectUserFamilyListError = (state: RootState) =>
	state.profile.errors;

export const AddFamilyMemberList = (state: RootState) =>
	state.profile.addFamilyMember;

export const selectIsAddFamilyMemberLoading = (state: RootState) =>
	state.profile.addFamilyMember.status === 'loading';

export const selectIsFamilyRelationShipLoading = (state: RootState) =>
	state.profile.isLoading === 'loading';

export const selectFamilyRelationShipList = (state: RootState) =>
	state.profile.familyRelationShip.Relations;

export const mobileGenerateOtpLoading = (state: RootState) =>
	state.profile.generateOtpForMobile.status === 'loading';

export const emailGenerateOtpLoading = (state: RootState) =>
	state.profile.generateOtpForEmail.status === 'loading';

export const mobileVerifyOtpLoading = (state: RootState) =>
	state.profile.verifyMobileNo.status === 'loading';

export const emailVerifyOtpLoading = (state: RootState) =>
	state.profile.verifyEmail.status === 'loading';

export const mobileNoOtpGenerate = (state: RootState) =>
	state.profile.generateOtpForMobile.data;

export const saveFamilyVerificationLoading = (state: RootState) =>
	state.profile.familyVerification.status === 'loading';

export const saveFamilyEmailVerificationLoading = (state: RootState) =>
	state.profile.familyEmailVerification.status === 'loading';

export const addArRequestLoading = (state: RootState) =>
	state.profile.updateAR.status === 'loading';

export const addArData = (state: RootState) => state.profile.updateAR.data;
export const familyUploadLoading = (state: RootState) =>
	state.profile.familyUpload.status === 'loading';
export const pinMasterRequestLoading = (state: RootState) =>
	state.profile.pinMaster.status === 'loading';
export const pinMasterData = (state: RootState) => state.profile.pinMaster.data;
export const addAddressRequestLoading = (state: RootState) =>
	state.profile.addAddress.status === 'loading';
// export const addAddressData = (state: RootState) => state.profile.pinMaster.data;

export const selectSelfDeclarationDetails = (state: RootState) =>
	state.profile.SelfDeclarationDetails.data;

export const {
	fetchUserProfile,
	loadUserProfile,
	errorUserProfile,
	fetchUserFamilyListAction,
	loadUserFamilyList,
	errorUserFamilyList,
	addFamilyMemberRequest,
	addFamilyMemberSuccess,
	addFamilyMemberError,
	fetchFamilyRelationShipList,
	loadFamilyRelationShipList,
	errorFamilyRelationShipList,
	verifyGenerateOtpForMobileRequest,
	verifyGenerateOtpForMobileSuccess,
	verifyGenerateOtpForMobileError,
	verifyOtpMobileNoRequest,
	verifyOtpMobileNoSuccess,
	verifyOtpMobileNoError,
	verifyGenerateOtpForEmailRequest,
	verifyGenerateOtpForEmailSuccess,
	verifyGenerateOtpForEmailError,
	verifyOtpEmailRequest,
	verifyOtpEmailSuccess,
	verifyOtpEmailError,
	saveFamilyVerificationDetails,
	saveFamilyVerificationDetailsSuccess,
	saveFamilyVerificationDetailsError,
	saveFamilyEmailVerificationDetails,
	saveFamilyEmailVerificationDetailsSuccess,
	saveFamilyEmailVerificationDetailsError,
	updateArRequest,
	updateArSuccess,
	updateArError,
	familyUploadRequest,
	familyUploadSuccess,
	familyUploadError,
	pinCodeRequest,
	pinCodeSuccess,
	pinCodeError,
	addAddressError,
	addAddressRequest,
	addAddressSuccess,
	SelfDeclarationDetailsRequest,
	SelfDeclarationDetailsSuccess,
	SelfDeclarationDetailsError
} = profileSlice.actions;

export default profileSlice.reducer;
