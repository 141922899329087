import { Grid, Typography } from "@mui/material"
import CustomButton from "../../../common/ui/CustomButton"
import Separator from "../../../common/ui/Separator"
import CommonModal from "../../../common/ui/Modal/CommonModal"
import theme from "../../../common/theme"
import CreditIcon from '../../../common/icons/Credit_Card.png';
import OtherIcon from '../../../common/icons/Other_Pay_Method.png';

export const PaymentModeModal = ({ open, setOpen, setPaymentType }: any) => {

    const paymentMode = [{
        title: "Pay using a Credit Card",
        id: "CreditCard"
    },
    {
        title: "Pay by other methods",
        id: "Others"
    }]

    return (
        <CommonModal open={open} onClose={() => setOpen(false)} modalClassName=" popup-word"
            boxProps={{ width: 280 }} >
            <Grid container>
                <Grid container className="p-15 " alignItems={'center'}
                // onClick={() => setPaymentType(paymentMode[0]?.id)}
                >
                    <img
                        src={CreditIcon}
                        alt="Card"
                        width={27}
                        height={27}
                        className="mr-10"
                    />
                    <Typography className="fw-600 f-14" color={theme.palette.grey[500]}>{paymentMode[0]?.title}</Typography>
                </Grid>
                <Separator color={theme.palette.grey[400]} borderWidth={0.6} />
                <Grid container className="p-10 pointer" alignItems={'center'} onClick={() => setPaymentType(paymentMode[1]?.id)} >
                    <img
                        src={OtherIcon}
                        alt="Other"
                        width={31}
                        height={31}
                        className="mr-10"
                    />
                    <Typography className="fw-600 f-14">{paymentMode[1]?.title}
                        <Typography className="fw-400 f-12" color={theme.palette.grey[400]} >{"(UPI, Netbanking, Debit Card, etc.)"}</Typography>
                    </Typography>

                </Grid>
            </Grid>
        </CommonModal>
    )
}